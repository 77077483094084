// 时间戳转换成日期格式
function getTime(params) {
    if(params){
        let date = new Date(params*1000);
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        let H = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let Mi = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let S = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        let tempDate = Y + M + D + H + Mi + S;
        return tempDate;
       }
}
export default getTime


 /**
 * Parse the time to string
 * 获取当前年月日
 */
export function nowYMD() {
    var dd = new Date();
    dd.setDate(dd.getDate() );//获取p_count天后的日期
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;//获取当前月份的日期
    if (m < 10) {
      m = '0' + m;
    }
    var d = dd.getDate();
    if (d < 10) {
      d = '0' + d;
    }
   return y + "-" + m + "-" + d;
}
 /**
 * Parse the time to string
 * 将秒转为时分秒
 */
  export function setHMM2(time){
    const millisecond = parseInt(time%1000) 
    let second = 0;
    let totalMinute = 0;
    let minute = 0;
    let hour = 0;
    let result = "";
    const totalSecond = parseInt(time/1000)   // 3671
    if(totalSecond>59){   // 总秒数大于59 需要计算总分钟 数
      second =  parseInt(totalSecond%60)    // 11
      totalMinute =  parseInt(totalSecond/60)  // 61
    }else{
      second = totalSecond
    }
    if(totalMinute>59){
      minute = parseInt(totalMinute%60)   // 1
      hour = parseInt(totalMinute/60)     // 1 
    }else{
      minute =  totalMinute
    }
    if(hour<10){
      result+="0"+hour;
    }else{
      result+=""+hour;
    }
    if(minute<10){
      result+=":0"+minute;
    }else{
      result+=":"+minute;
    }
    if(second<10){
      result+=":0"+second;
    }else{
      result+=":"+second;
    }
    return result;
}