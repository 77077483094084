<template>
  <!-- 新建/修改 -->
  <GlobalWindow
    title="绑定通道"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  > 
    <el-form :model="form" ref="form" label-width="80px">
      <el-form-item label="选择车场" required>
        <el-select v-model="selectpark" filterable placeholder="请选择" @change="selectParkFun">
          <el-option
            v-for="item in parkList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="添加通道" required>
        <el-select v-model="selectPass" placeholder="请选择" @change="selectPassFun">
          <el-option label="全部" value="all" v-if="allPassList.length"></el-option>
          <el-option
            v-for="item in allPassList"
            :key="item.id"
            :label="item.passname"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="success">绑定</el-button>
      </el-form-item> -->
    </el-form>
    <!-- 用户已绑定通道 -->
    <div style="display:flex;">
      <div style="margin-top:20px;width:120px;">已绑定通道:</div>
      <div class="pass-tag-box">
        <el-tag
            v-for="(tag,i) in passTagList"
            :key="tag.id"
            @close="delPass(tag,i,0)"
            closable
            :type="tag.type">
            {{tag.comidPassName}}
        </el-tag>
      </div>
    </div>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { getPassListByBindingUser,bindingUserToPass,unBindingUserToPass,managerComList,passInfo } from '@/api/system/user';
import { fetchList } from '@/api/parking/pass';
export default {
  name: 'OperaUserWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        
        
      },
      selectpark:null,
      passTagListDel:[],
      passTagListAdd:[],
      fetchAllList:[],
      passTagList:[],
      allPassList:[],
      selectPass:'',
      list:[],
      userId:null,
      isWorking:false,
      parkList:[],
      parkName:''
    }
  },
  methods: {
    /**
     * @title 窗口标题
     * @target 编辑的用户对象
     */
    open (item) {
      this.userId = item.id;
      let that = this;
      this.passTagList = []
      this.selectpark = ''
      this.selectPass = ''
      //查询用户管理的车场
      managerComList().then(res=>{
        this.parkList = res;
      })
      //   获取用户绑定通道
      getPassListByBindingUser(this.userId).then(res=>{
        res.map(item=>{
          item.isBind = true;
          item.comidPassName = item.comName + ': ' + item.passname
          this.passTagList.push(item)
        })
      })
      this.visible = true;
    },
    //筛选下拉框内容
    setPassList(){
      this.allPassList = this.list.filter(v=>{
        return this.passTagList.every(e => e.id != v.id)
      })
    },
    //选中车场后
    selectParkFun(park){
      let that = this;
      let data = {
        "capacity": 100,
        "model": {
          "comId": park,
          "passName": "",
          "passType": ""
        },
        "page": 1,
      }
      this.selectPass = ''
      passInfo(data).then(res=>{
        this.list = res.records;
        this.allPassList = this.list.filter(v=>{
          return this.passTagList.every(e => e.id != v.id)
        })
        this.allPassList.map(item=>{
          item.isBind = false;
        })
        //获取选中车场名
        this.parkList.map(parkitme=>{
          if(park == parkitme.id){
            this.parkName = parkitme.name;
          }
        })
      })
      // this.setPassList()
    },
    //选中通道后
    selectPassFun(pass){
      let that = this;
      this.$confirm('是否绑定此通道?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let passIds = []
        if (pass === 'all') {
          that.allPassList.map(item=>{
            passIds.push(item.id)
          })
        } else {
          passIds = [pass]
        }
        let dataAdd = {
          userId:this.userId,
          passIds: passIds
        }
        bindingUserToPass(dataAdd).then(res=>{
          that.selectPass = ''
          that.passTagList = []
          //   获取用户绑定通道
          getPassListByBindingUser(that.userId).then(res=>{
            res.map(item=>{
              item.isBind = true;
              item.comidPassName = item.comName + ': ' + item.passname
              that.passTagList.push(item)
            })
          })
          // that.allPassList.map(item=>{
          //   if(item.id == pass){
          //     item.comidPassName = this.parkName + ': ' + item.passname;
          //     that.passTagListAdd.push(item);
          //     that.passTagList.push(item);
          //   }
          // })
          // that.$forceUpdate()
          that.setPassList()
        })
      })
    },
    confirm () {
      this.isWorking = false;
      this.visible = false;
      this.passTagListDel = []
      this.passTagListAdd = []
      this.fetchAllList = []
      this.passTagList = []
      this.allPassList = []
      this.selectPass = ''
      this.list = []
      this.userId = null
      this.isWorking = false
    },
    //移除通道
    delPass(item,i,type){
      let that = this;
      this.$confirm('是否解绑此通道?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let dataDel = {
          userId:this.userId,
          passIds:[item.id]
        }
        unBindingUserToPass(dataDel).then(res=>{
          that.allPassList.push(item);
          that.passTagList.splice(i,1)
        })
      })
      this.setPassList()
    },
  },
}
</script>

<style lang="scss" scoped>
.global-window {
  /deep/ .el-date-editor {
    width: 100%;
  }
  // 表单头像处理
  /deep/ .form-item-avatar {
    .el-radio.is-bordered {
      height: auto;
      padding: 6px;
      margin: 0 10px 0 0;
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding: 0;
        width: 48px;
        display: block;
        img {
          width: 100%;
        }
      }
    }
  }
}
.pass-tag-box{
  padding:10px 0;
}
.pass-tag-box /deep/ .el-tag{
  margin:10px;
}
</style>
