<template>
  <div class="dashboard-editor-container">
    <!--<el-button  type="text"  @click="showReminder" icon="el-icon-question" >说明</el-button>-->
    <!--综合数据显示区域-->
    <panel-group :count-data="count" />

    <!--饼状图显示区域-->
    <el-row :gutter="32">
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <pie-chart-2 :chart-data="deviceType" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <!--<bar-chart />-->
          <pie-chart :chart-data="deviceStatus" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <!--<bar-chart />-->
          <pie-chart3 :chart-data="deviceBind" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PanelGroup from '@/components/dataCenter/admin/PanelGroup'
import LineChart from '@/components/dataCenter/admin/LineChart'
import RaddarChart from '@/components/dataCenter/admin/RaddarChart'
import PieChart from '@/components/dataCenter/admin/PieChart'
import PieChart2 from '@/components/dataCenter/admin/PieChart2'
import PieChart3 from '@/components/dataCenter/admin/PieChart3'
import BarChart from '@/components/dataCenter/admin/BarChart'
import { getCenterDataByAdmin } from '@/api/dataCenter/admin'

export default {
  name: 'DatacenterIST',
  components: {
    PanelGroup,
    LineChart,
    RaddarChart,
    PieChart,
    PieChart2,
    PieChart3,
    BarChart
  },
  data() {
    return {
      count: {
        deviceCount: 0,
        onlineCount: 0,
        offlineCount: 0,
        bindCount: 0
      },
      deviceType: [],
      deviceStatus: [],
      deviceBind: []
    }
  },
  created() {
    this.getList()
  },
  methods: {

    showReminder() {
      this.$alert('<strong><i style="color: #C03639">1.设备总数<i>，指注册到IOT平台的所有设备</i></i><br>' +
        '<i>2.设备是<i style="color: #C03639">手机APP</i>时存在<i style="color: #C03639">APP用户编号</i></i><br>' +
        '<i>3.</i><i style="color: #C03639">IOT平台编号</i><i>是IOT平台分配的编号(来源于IOT平台)</i><br>' +
        '<i>4.</i><i style="color: #C03639">设备编号</i><i>是此设备或者APP的唯一编号(来源于设备或者APP)</i><br>' +
        '<i>5.</i><i style="color: #C03639">设备P2P编号</i><i>是由运营平台生成，分配给对讲相机，用于P2P协议的编号</i></strong>', '说明', {
        dangerouslyUseHTMLString: true
      })
    },

    getList() {
      getCenterDataByAdmin().then(response => {
        var data = response
        this.count = {
          deviceCount: data.deviceAllNums,
          onlineCount: data.deviceOnlineNums,
          offlineCount: data.deviceOfflineNums,
          bindCount: data.deviceBindingNums
        }

        data.deviceTypeList.forEach(function(item) {
          item.value = item.count
          if (item.device_type == 1001) { item.name = '4G相机' }//eslint-disable-line
          if (item.device_type == 1002) { item.name = '非4G相机' }//eslint-disable-line
          if (item.device_type == 1003) { item.name = '识别相机' }//eslint-disable-line
          if (item.device_type == 1004) { item.name = '全景相机' }//eslint-disable-line
          if (item.device_type == 1005) { item.name = '对讲相机' }//eslint-disable-line
          if (item.device_type == 2001) { item.name = 'LCD屏' }//eslint-disable-line
          if (item.device_type == 3001) { item.name = '手机APP' }//eslint-disable-line
          if (item.device_type == 4001) { item.name = '停车终端机' }//eslint-disable-line
        })
        this.deviceType = data.deviceTypeList
        data.deviceStatusList.forEach(function(item) {
          item.value = item.count
          if (item.status == 0) { item.name = '在线' }//eslint-disable-line
          if (item.status == 1) { item.name = '离线' }//eslint-disable-line
          if (item.status == 2) { item.name = '未激活' }//eslint-disable-line
          if (item.status == 3) { item.name = '禁用' }//eslint-disable-line
        })
        this.deviceStatus = data.deviceStatusList
        this.deviceBind = [
          {
            'name': '已绑定',
            'value': data.deviceBindingNums
          },
          {
            'name': '未绑定',
            'value': data.deviceAllNums - data.deviceBindingNums
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}

@media (max-width:1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
