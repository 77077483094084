import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/com/deviceInfo', data)
}

// 车场下发广告
export function sendAdByCom (comId) {
  return request.get('/system/ad/sendAdByCom?comId=' + comId)
  // return request.get('/system/ad/sendAdByCom?comId=10031626')
}

// 查询子设备
export function querySubDevice (nodeId) {
  return request.get('/system/device/querySubDevice?nodeId=' + nodeId)
}

// 删除子设备
export function removeSubDevice (id) {
  return request.post('/system/device/removeSubDevice?id=' + id)
}

// 新增子设备
export function addSubDevice (data) {
  return request.post(`/system/device/addSubDevice`,data)
}

// 终端机开启设备frpc
export function openEnableFRpc (nodeId) {
  return request.get(`/system/device/enableFRpc?nodeId=${nodeId}`)
}


// 设备列表
export function deviceCategoryGetEnum () {
  return request.get(`/system/deviceCategory/getEnum`)
}