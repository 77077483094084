<template>
  <TableLayout class="menu-layout" :permissions="['system:com:comInfo']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="车场编号" prop="comId">
        <el-input v-model="searchForm.comId" v-trim placeholder="请输入车场编号" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="车场名称" prop="companyName">
        <el-input v-model="searchForm.companyName" v-trim placeholder="请输入车场名称" />
        <!-- <el-select v-model="searchForm.companyName" placeholder="请选择车场" filterable >
          <el-option :label="item.name" :value="item.name" v-for="(item,i) in parkingList" :key="i"></el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item label="车场类型">
        <el-select v-model="searchForm.sourceType" placeholder="请选择车场类型">
          <el-option label="网络相机车场" value="1"></el-option>
          <el-option label="第三方车场软件车场" value="2"></el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="resetNew">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        default-expand-all
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="id" label="车场ID"></el-table-column>
        <el-table-column prop="companyName" label="车场名称" min-width="200px"></el-table-column>
        <el-table-column prop="parkingTotal" label="车位总数" min-width="80px"></el-table-column>
        <el-table-column prop="parkingType" label="车场类型" min-width="100px">
          <template slot-scope="{row}">{{row.sourceType == '1' ? '网络相机车场' : '第三方软件车场'}}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="140px">
          <template slot-scope="{row}">{{$getTime(row.createTime)}}</template>
        </el-table-column>
        <el-table-column prop="mobile" label="停车场电话" min-width="100px"></el-table-column>
        <!-- <el-table-column label="操作" min-width="120" fixed="right" v-if="containPermissions(['system:orderAd:config']) && userInfo.roles.indexOf('admin') != -1">
          <template slot-scope="{row}">
            <el-button type="text" icon="el-icon-s-tools" @click="doadTypeSetShow(row)" v-permissions="['system:orderAd:config']">订单广告服务</el-button>
          </template>
        </el-table-column> -->
        <el-table-column label="操作" min-width="190" fixed="right">
          <template slot-scope="{row}">
            <el-button type="text" icon="el-icon-s-tools" @click="setAd(row)">订单广告服务</el-button>
            <el-button type="text" icon="el-icon-s-tools" v-show="isAdmin" @click="setAli(row)">支付宝广告设置</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>

    <el-dialog title="订单广告服务配置" :visible.sync="dialogVisible"  width="30%">
      <el-switch v-model="value1" active-text="开启" @change="changStatu"></el-switch>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置广告类型 -->
    <el-dialog title="设置广告类型" :visible.sync="adTypeSetShow" height="600px" width="400px">
      <div v-show="!isAdmin">
        <el-select v-model="adType" placeholder="请选择广告类型">
          <el-option v-for="type in adTypeList" :label="type.name" :value="type.type" :key="type.type" />
        </el-select>
      </div>
      <div v-show="isAdmin">
        <el-switch v-model="value1" active-text="开启" @change="changStatu" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="adTypeSetShow = false">取 消</el-button>
        <el-button type="primary" @click="defaultAdType">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 设置支付宝停车助手 -->
    <el-dialog title="支付宝广告设置" :visible.sync="aliTypeSetShow" height="600px" width="400px">
      <el-form ref="form" label-width="120px" v-show="isAdmin">

        <el-form-item label="支付宝停车助手">
          <el-switch v-model="isOpen" active-text="开启" />
        </el-form-item>
        <el-form-item label="跳转支付宝">
          <el-switch v-model="isJump" active-text="开启" />
        </el-form-item>
        <el-form-item label="支付宝广告">
          <el-switch v-model="isShow" active-text="开启" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="aliTypeSetShow = false">取 消</el-button>
        <el-button type="primary" @click="defaultAliType">确 定</el-button>
      </div>
    </el-dialog>
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import { parkingList,getAllOpenComId ,comOpen,comClose, aliAdOpenOrClose, aliadinfo} from '@/api/parking/parking'
import { getAdType, AdTypeList} from "@/api/ad/oderAdUpLoad";
import { updateById } from '@/api/tenant/tenant'
import { mapState } from 'vuex'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination},
  data () {
    return {
      aliTypeSetShow: false,
      isAdmin: false,
      adTypeSetShow: false,
      value1:false,
      dialogVisible:false,
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        comId: '',
        companyName: '',
        sourceType: '',
      },
      parkingList:[],
      OpenComId:[],
      checkPark:{},
      adTypeList: [],
      adType: '',
      adTenant: {},
      isOpen: true,
      isJump: true,
      isShow: true
    }
  },
  mounted(){
    this.getParkingListFuc();
    this.getAllOpenComIdFun();
    if (this.userInfo.roles.indexOf("admin") != -1) {
      this.isAdmin = true
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    doadTypeSetShow(row) {
      console.log(this.userInfo)
      this.adTenant = row
      this.AdTypeList()
      getAdType(row.id).then(res=>{
        this.adType = res === null ? 'IST' : res
      })
    },
    yyUpdateById() {
      let data = {
        adType: this.adType,
        code: this.adTenant.code,
        createTime: this.adTenant.createTime,
        managerUserId: this.userInfo.id,
        id: this.adTenant.id,
        name: this.adTenant.name,
        updateTime: this.adTenant.updateTime,
      }
      updateById(data).then(res=>{
        this.adTypeSetShow = false;
        this.$message({ message: '操作成功', type: 'success'});
        this.search()
      }).catch(e=>{
        this.adTypeSetShow = false;
        this.$message.error(e);
        
      })
    },
    // 设置默认广告
    defaultAdType() {
      // admin层
      if (this.isAdmin) {

      } else {
        this.yyUpdateById()
      }
      
    },
    // 获取所有广告类型
    AdTypeList() {
      this.adTypeList = []
       AdTypeList().then(res=>{
        this.adTypeList = res
        this.adTypeSetShow = true
      })
    },
    changStatu(statu){
      let data = {
        comIds:[this.checkPark.id],
        tenantId:this.userInfo.id
      }
      if(statu){
        comOpen(data).then(res=>{
          this.$tip.apiSuccess('设置成功')
          this.getAllOpenComIdFun()
        }).catch(e => {
          this.$tip.apiFailed(e)
        })
      }else{
        comClose(data).then(res=>{
          this.$tip.apiSuccess('设置成功')
          this.getAllOpenComIdFun()
        }).catch(e => {
          this.$tip.apiFailed(e)
        })
      }
    },
    setAd(row){
      this.checkPark = row;
      if(this.OpenComId.indexOf(row.id) != -1){
        this.value1 = true;
      }else{
        this.value1 = false;
      }
      this.dialogVisible = true

    },
    getAllOpenComIdFun(){
      getAllOpenComId().then(res=>{
        this.OpenComId = res;
      })
    },
    setAli(row){
      this.checkPark = row;
      aliadinfo({comId: row.id})
        .then(records => {
          this.isOpen = records.isOpen ? true : false
          this.isJump = records.isJump ? true : false
          this.isShow = records.isShow ? true : false
          this.aliTypeSetShow = true
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
        })
      

    },
    defaultAliType() {
      let obj = {
        comId: this.checkPark.id,
        enable: this.isOpen ? 1 : 0,
        isJump: this.isJump ? 1 : 0,
        isShow: this.isShow ? 1 : 0,
      }
      aliAdOpenOrClose(obj)
        .then(records => {
          this.$tip.apiSuccess('设置成功')
          this.aliTypeSetShow = false
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
        })
      
    },
    // 时间搜索范围变化
    handleSearchTimeChange (value) {
      this.searchForm.createTimeStart = null
      this.searchForm.createTimeEnd = null
      if (value != null) {
        this.searchForm.createTimeStart = value[0] / 1000
        this.searchForm.createTimeEnd = value[1] / 1000
      }
      this.search()
    },
    getParkingListFuc(){
      parkingList()
        .then(records => {
          this.parkingList = records
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
        })
    },
    //重置
    resetNew(){
      this.searchForm = {
        comId: '',
        companyName: '',
        sourceType: '',
      }
      this.search()
    },
  },
  created () {
    this.config({
      module: '菜单',
      api: '/parking/parking',
      sorts: [{
        property: 'create_time',
        direction: 'DESC'
      }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
