import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/com/passInfo', data)
}

//清除通话状态
export function clearCall () {
  return request.get('/system/clear/call')
}
