import request from '@/utils/request'

// 查询
export function querySdp (data) {
  return request.get('/system/monitorPage/querySdp?ip='+ data.ip + '&passId=' + data.passId + '&type=' + data.type,data)
}


//查询通道
export function getPassListByBindingUser (data) {
  return request.get('/system/comPass/getPassListByBindingUser')
}
//查询通道2
export function deviceInfo (data) {
  return request.post('/system/com/deviceInfo',data)
}

//关闭webRTC连接
export function closeConnect (data) {
  return request.post(`/system/monitorPage/closeConnect`,data,{'Content-Type':'application/json'})
}
//上传本地candidate
export function uploadAndGetCandidate (data) {
  return request.post(`/system/monitorPage/uploadCandidate?candidate=${data.candidate}&connectId=${data.connectId}&sn=${data.sn}`,data)
}
//获取设备candidate
export function getCandidate (data) {
  return request.post(`/system/monitorPage/getCandidate?connectId=${data.connectId}&sn=${data.sn}`,data)
}
//上传sdp
export function uploadSpd (data) {
  return request.post(`/system/monitorPage/uploadSpd?connectId=${data.connectId}&ip=${data.ip}&sdp=${data.sdp}&sn=${data.sn}&type=${data.type}`,data)
}