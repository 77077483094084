<template>
  <!--弹窗广告-->
  <div id="container">
    <div id="myModal" class="modal" >
      <img class="modal-content" id="pop_up_ad" :src="previewUrl">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColumnDetail',
  props: {
    // 按钮类型
    previewUrl: {
      type: String
    },
  },
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
.yunying-column-detail-popover {
  max-width: 80%;
}
</style>
<style scoped lang="scss">
  #container{
    border-radius: 20px;
    width:360px;
    height:640px;
    position:relative;
  }
  /* 弹窗背景 */
  .modal {
    position: absolute; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.7); /* Black w/ opacity */
    display: flex;
  }
  /* 图片 */
  .modal-content {
    margin: auto;
    display: block;
    width: 70%;
  }     
</style>
