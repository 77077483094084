<template>
  <div>
    <div style="text-align:right;"> 
      <el-button type="primary" @click="dialogTableVisibleitem = true">新增子设备</el-button>
    </div>
    <el-table :data="tableData">
      <el-table-column property="id" label="设备ID"></el-table-column>
      <!-- <el-table-column property="nikeName" label="设备名称"></el-table-column> -->
      <el-table-column property="nikeName" label="设备类型"></el-table-column>
      <el-table-column property="registerTime" label="注册时间">
        <template slot-scope="{row}">{{$getTime(row.registerTime)}}</template>
      </el-table-column>
      <el-table-column prop="deviceType" label="操作" fixed="right">
        <template slot-scope="{row}">
          <el-button type="text" size="small" @click="removeItem(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="收货地址" :visible.sync="dialogTableVisibleitem" append-to-body width="30%">
      <el-form>
        <el-form-item label="活动区域">
          <el-select v-model="type" placeholder="请选择活动区域">
            <el-option v-for="(item, i) in deviceTypeList" :label="item.name" :value="item.id" :key="i"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addItem">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {querySubDevice,removeSubDevice,addSubDevice} from '@/api/parking/deviceInfo'
export default {
  name: 'SystemMenu',
  props:['dialogTableVisible', 'deviceData'],
  data () {
    return {
      type:null,
      tableData:[],
      deviceTypeData:{
        6001: "道闸",
        2002: "LED屏"
      },
      deviceTypeList:[
        {id:6001,name:"道闸"},
        {id:2002,name:"LED屏"},
      ],
      dialogTableVisibleitem:false
    }
  },
  watch:{
    dialogTableVisible:{
      deep:true,
      handler:function(newStaus){
        if(newStaus){
          this.getTableData()
        }
      }
    }
  },
  mounted(){
    this.getTableData()
  },
  methods: {
    getTableData(){
      querySubDevice(this.deviceData.nodeId).then(res=>{
        this.tableData = res
      })
    },
    removeItem(row){
      let that = this
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeSubDevice(row.id).then(res=>{
          that.$message.success('操作成功');
          this.getTableData()
          this.$emit('search')
        })
      })
      
    },
    addItem(){
      let data = {
        parentNodeId: this.deviceData.nodeId,
        type:this.type
      }
      if(this.type == null){
        this.$message.error('请选择设备类型');
        return false
      }
      addSubDevice(data).then(res=>{
        this.dialogTableVisibleitem = false
        this.$message.success('操作成功');
        this.getTableData()
        this.$emit('search')
      }).catch(err => {
        this.$message.error(err.message);    
      });
    }
  },
  created () {

  }
}
</script>

<style lang="scss" scoped>

</style>
