<template>
  <div class="mapbox">
    <baidu-map
      class="map"
      @ready="handler"
      center="中国"
      :map-click="false">
      <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT"></bm-city-list>
      <bml-marker-clusterer
        :averageCenter="true"
      >


        <div v-for="(marker, i) of markers" :key="i">
          <bm-marker
            :dragging="true"
            animation="BMAP_ANIMATION_BOUNCE"
            :position="{lng: marker.lng, lat: marker.lat}"
            @click="infoWindowOpen(marker)"
          >
            <bm-info-window
              title="车场信息"
              :position="{lng: marker.lng, lat: marker.lat}"
              :show="marker.showFlag"
              @close="infoWindowClose(marker)"
              @open="infoWindowOpen(marker)"
            >

              <p><label>车场编号：</label>{{marker.comid}}</p>
              <p><label>车场名称：</label>{{marker.company_name}}</p>
              <p><label>对讲设备数量：</label>{{marker.equipcount}}</p>


            </bm-info-window>
          </bm-marker>
        </div>
      </bml-marker-clusterer>
    </baidu-map>
  </div>
</template>

<script>
  // 按需引入点聚合
  import { BmlMarkerClusterer } from 'vue-baidu-map'
  // 引入marker
  import BmMarker from 'vue-baidu-map/components/overlays/Marker'

  export default {
    name: 'Bmaptrace',
    components: {
      BmlMarkerClusterer,
      BmMarker
    },
    props: {
      chartData: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        markers : new Array(),
        BMap: '',
        map: '',
        show: false,
      }
    },
    watch: {
      chartData: {
        deep: true,
        handler(val) {
//          comid: 30262
//          company_name: "百利来商城停车场"
//          equipcount: 0
//          latitude: 35.973488
//          longitude: 120.189157
          val.expectedData.forEach((item,index,arr) =>{
            const position = {
              lng: item.longitude,
              lat: item.latitude,
              showFlag: false ,
              comid:item.comid,
              company_name:item.company_name,
              equipcount:item.equipcount,
            }
            this.markers.push(position)
          })

        }
      }
    },
    methods: {
      handler ({ BMap, map }) {
        map.enableScrollWheelZoom(true)
        // map.centerAndZoom('青岛市', 13)
        // 赋值，方便调用，本节没用到
        this.BMap = BMap
        this.map = map
      },
      // 关闭信息窗口 @close 自带的方法
      infoWindowClose (marker) {
        marker.showFlag = false

      },
      // 首先点击marker时开启信息窗口，其实没必要在 bm-info-window 上写 @open 因为如果是关闭状态根本就点不到，所以就无法触发 @open
      // 如果在 bm-info-window 上写了 @open 在点击marker时会触发两次infoWindowOpen函数，而且可以很明显的看到有延时
      infoWindowOpen (marker) {
        marker.showFlag = true

      }
    }
  }
</script>
<style scoped>
  .map {
    height:650px;
  }
</style>
