
import request from '@/utils/request'
import OSS from 'ali-oss'

// 根据车场获取广告
export function getADInfoByUnion (data) {
  return request.get('/system/ad/getComAd?comId=' + data)
}
// 获取能操作广告管理的车场集合
export function getAuthCom (data) {
    return request.get('/system/com/managerComList',data)
}
// 获取STS访问权限
export function sts (data) {
  return request.get('/system/file/sts',data)
}
export function getSTS(data) {
  return request.get('/system/upgradefile/sts',data)
}
// 上传广告到OSS后存数据库
export function uploadParam (data) {
  return request.post('/system/ad/upload',data)
}
// 车场下发广告
export function sendAdByCom (comId) {
  return request.get('/system/ad/sendAdByCom?comId=' + comId)
}

// 删除广告
export function deleteAd (data) {
  let url = '/system/ad/deleteAdByCom?';
  for(var key in data){
    url += (key + '=' + data[key] + '&')
  }
  return request.post(url,data)
}
/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
 export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}


/**
 *  [accessKeyId] {String}：通过阿里云控制台创建的AccessKey。
 *  [accessKeySecret] {String}：通过阿里云控制台创建的AccessSecret。
 *  [bucket] {String}：通过控制台或PutBucket创建的bucket。
 *  [region] {String}：bucket所在的区域， 默认oss-cn-hangzhou。
 */
export function client(data) {
  return new OSS({
    region: data.region,
    accessKeyId: data.accessKeyId,
    accessKeySecret:  data.accessKeySecret,
    bucket: data.bucket,
    stsToken: data.securityToken
  })
}