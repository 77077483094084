<template>
  <div class="app-container">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" label-width="100px" inline style="margin-top:20px;">
      <el-form-item label="选择车场">
        <el-select v-model="searchForm.comId" @change="getList">
          <el-option :label="item.name" :value="item.id" v-for="(item,i) in parkList" :key="i"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!--上传广告-->
    <el-row :gutter="20">
      <el-col :span="13">
        <el-card shadow="hover">
          <div style="display: flex;justify-content:space-between">
            <div class="imageBox" id="picture-card-box">
              <div class="title_02">上传广告:</div>
              <el-upload
                action="actions"
                list-type="picture-card"
                :before-upload="beforeUpload"
                :file-list="fileList"
                :http-request="httpRequest"
                :before-remove="onRemove"
                :limit="4"
                ref="upload"
                :on-exceed="onExceed"
              >
                <i class="el-icon-plus"></i>
                <div
                  slot="tip"
                  class="el-upload__tip"
                  style="margin-top:33px;color: red"
                >注意：图片名称不能是中文，图片格式为jpg或png，大小不能超过10MB</div>
              </el-upload>
            </div>
            <div>
              <svg
                t="1586935897503"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="20537"
                width="48"
                height="48"
              >
                <path
                  d="M672.427 460.8h-75.094v252.587h71.68c37.547 0 68.267-6.827 88.747-30.72 20.48-27.307 30.72-47.787 30.72-95.574S774.827 512 757.76 491.52c-23.893-23.893-51.2-30.72-85.333-30.72zM279.893 610.987h105.814l-51.2-153.6-54.614 153.6z"
                  fill="#00B986"
                  p-id="20538"
                  data-spm-anchor-id="a313x.7781069.0.i13"
                  class="selected"
                />
                <path
                  d="M911.36 184.32H112.64c-61.44 0-109.227 47.787-109.227 105.813v604.16c0 58.027 51.2 105.814 109.227 105.814h798.72c61.44 0 109.227-47.787 109.227-105.814v-604.16c0-58.026-47.787-105.813-109.227-105.813zM464.213 761.173h-27.306l-37.547-102.4H262.827l-37.547 102.4h-54.613L307.2 409.6h54.613L491.52 761.173h-27.307z m218.454 0h-143.36V409.6H686.08c44.373 0 81.92 17.067 109.227 47.787 27.306 30.72 44.373 75.093 44.373 129.706 0 54.614-17.067 95.574-44.373 129.707-27.307 27.307-64.854 44.373-112.64 44.373zM880.64 139.947H129.707c-10.24 0-20.48-10.24-20.48-20.48s6.826-17.067 20.48-17.067H880.64c10.24 0 20.48 10.24 20.48 20.48s-10.24 17.067-20.48 17.067zM232.107 61.44c-10.24 0-20.48-10.24-20.48-20.48s10.24-20.48 20.48-20.48h532.48c10.24 0 20.48 10.24 20.48 20.48s-10.24 20.48-20.48 20.48h-532.48z"
                  fill="#00B986"
                  p-id="20539"
                  data-spm-anchor-id="a313x.7781069.0.i10"
                  class="selected"
                />
              </svg>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card shadow="hover">
          <!--上传视频-->
          <div style="display: flex;justify-content:space-between">
            <div style="width:23.4vw;height:26vh;" v-if="videoList.length">
              <video controls loop="loop" autoplay="autoplay" :src="videoSrc" type="video/mp4" width="450" height="240"></video>
            </div>
            <div v-if="!videoList.length">
              <span class="title_02">上传视频:</span>
              <el-upload
                action
                :http-request="Upload"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleExceed"
                :file-list="videoList"
                :on-remove="onRemove"
                drag
                :limit="1"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或
                  <em>点击上传</em>
                </div>
                <div slot="tip" class="el-upload__tip" style="color: red">注意：视频文件名称不能用中文，视频为h264编码格式的mp4，大小不能超过100MB</div>
              </el-upload>
            </div>
            <div>
              <svg
                t="1586935331566"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="12722"
                width="48"
                height="48"
              >
                <path
                  d="M963.584 860.16h-238.592c112.128-81.408 184.832-213.504 184.832-362.496 0-247.296-200.704-448-448-448S13.824 249.856 13.824 497.664c0 245.248 196.608 443.904 440.832 448h508.416c23.552 0 42.496-19.456 42.496-42.496s-18.944-43.008-41.984-43.008z m-163.84-387.584h72.704c13.824 0 24.576 11.264 24.576 24.576s-11.264 24.576-24.576 24.576h-72.704c-13.824 0-24.576-11.264-24.576-24.576s11.264-24.576 24.576-24.576z m-115.712-230.4l51.2-51.2c9.728-9.728 25.088-9.728 34.816 0 9.728 9.728 9.728 25.088 0 34.816l-51.2 51.2c-9.728 9.728-25.088 9.728-34.816 0-9.728-9.216-9.728-25.088 0-34.816z m-245.248-153.6c0-13.824 11.264-24.576 24.576-24.576s24.576 11.264 24.576 24.576v72.704c0 13.824-11.264 24.576-24.576 24.576s-24.576-11.264-24.576-24.576V88.576zM127.488 522.24H54.784c-13.824 0-24.576-11.264-24.576-24.576s11.264-24.576 24.576-24.576h72.704c13.824 0 24.576 11.264 24.576 24.576s-10.752 24.576-24.576 24.576z m115.712 230.4l-51.2 51.2c-9.728 9.728-25.088 9.728-34.816 0-9.728-9.728-9.728-25.088 0-34.816l51.2-51.2c9.728-9.728 25.088-9.728 34.816 0 9.728 9.728 9.728 25.088 0 34.816z m0-475.648c-9.728 9.728-25.088 9.728-34.816 0l-51.2-51.2c-9.728-9.728-9.728-25.088 0-34.816 9.728-9.728 25.088-9.728 34.816 0l51.2 51.2c9.728 9.728 9.728 25.6 0 34.816zM488.448 906.24c0 13.312-11.264 24.576-24.576 24.576s-24.576-11.264-24.576-24.576v-72.704c0-13.824 11.264-24.576 24.576-24.576s24.576 11.264 24.576 24.576V906.24z m-102.4-225.28V313.856l247.808 183.808L386.048 680.96z m297.984 36.864c9.728-9.728 25.088-9.728 34.816 0l51.2 51.2c9.728 9.728 9.728 25.088 0 34.816-9.728 9.728-25.088 9.728-34.816 0l-51.2-51.2c-9.728-9.728-9.728-25.088 0-34.816z"
                  fill="#00B986"
                  p-id="12723"
                  data-spm-anchor-id="a313x.7781069.0.i0"
                  class="selected"
                />
              </svg>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="20" style="margin-top:20px;">
      <el-col :span="23">
        <el-card shadow="hover">
          <div class="information" style="display:flex;">
            <span>进度条:</span>
            <div class="progress">
              <el-progress
                v-show="showProgress"
                :text-inside="true"
                :stroke-width="15"
                :percentage="progress"
                status="success"
              ></el-progress>
            </div>
          </div>
          <div class="information">
            <span>审核状态:</span>
            <span v-if="check_state == '已上传'">上传成功,正在审核中</span>
            <span v-if="check_state == '审核通过'">审核通过</span>
            <span v-if="check_state == '审核不通过'">审核不通过</span>
            <el-button type="success" plain v-if="check_state == '审核通过'" style="margin-left:40px;" @click="sendAdByComFun">下发广告</el-button>
          </div>
          <div class="information">
            <span
              slot="tip"
              class="el-upload__tip"
            >注意:如果审核状态为 “审核不通过”,请检查你的广告是否存在违规内容，然后删除在重新上传。</span>
            <span>
              <el-button
                type="danger"
                @click="deleteAllAD"
                size="mini"
                plain
                style="margin-left:10px"
              >全删广告</el-button>
              <el-button
                type="primary"
                size="mini"
                v-if="check_state == 4002"
                @click="startAD"
                plain
                style="margin-left:10px"
              >应用广告</el-button>
            </span>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- <el-row :gutter="20">
      <el-col :span="23">
        <el-card shadow="hover">
          <video controls loop="loop" autoplay="autoplay" :src="videoSrc" type="video/mp4"></video>
        </el-card>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
import { getADInfoByUnion,getAuthCom,sts,getFileNameUUID,client,uploadParam,deleteAd,sendAdByCom} from "../../api/ad/upLoadAd";
import SparkMD5 from "spark-md5";
export default {
  name: "index",
  data() {
    return {
      file: null, //图片赋值
      vedioFile: null, //图片名字
      fileList: [], //图片存储url
      videoSrc: "", //存储视频的url
      videoList: [], //图片视频 name，url
      uploadUrl: "",
      alidata: {
        accessKeyId: "",
        accessKeySecret: "",
        region: "",
        bucket: "",
        securityToken: ""
      },
      param: {
        //上传文件时，参数
        fileName: "",
        filePath: "",
        uploadTime: "",
        token: '',
        md5Sum: ""
      },
      listQuery: {
        token: '',
        id: null,
        delType: null, //0 单个删除，1全删
        handle_type: 1 //1 修改  2 全删 针对下发广告时
      },
      searchForm:{
        comId:''
      },
      //////////////////////////
      showProgress: false, //进度条的显示
      dataObj: {}, //存签名信息
      progress: 0, //进度条数据
      cacheData: [], //缓存数据
      check_state: 0, //审核状态
      parkList:[],
      actions: 'http://192.168.1.196:9001/system/ad/upload'
    };
  },
  destroyed(){
    document.getElementById('picture-card-box').innerHTML = ''
  },
  created() {
    const token = this.getCookie("yunying-auth-token")
    this.param.token = token;
    this.listQuery.token = token;
    this.getparkList();
  },
  methods: {
    //广告下发
    sendAdByComFun(){
      sendAdByCom(this.searchForm.comId).then(res=>{
        this.$tip.apiSuccess('下发成功')
      })
      .catch(e => {
        this.$message.error(e)
      })
    },
    //获取能操作广告管理的车场集合
    getparkList(){
       getAuthCom()
        .then(records => {
          if(records && records.length){
            this.parkList = records;
            this.searchForm.comId = this.parkList[0].id;
            this.getList();
          }
        })
        .catch(e => {
          this.$message.error(e)
        })
        .finally(() => {
          
        })
    },

    getCookie(objName){//获取指定名称的cookie的值
      var arrStr = document.cookie.split("; ");
      for (var i = 0; i < arrStr.length; i++) {
        var temp = arrStr[i].split("=");
        if (temp[0] == objName){
          return decodeURI(temp[1]);
        }
      }
    },
    //////////////////////////上传图片///////////////////////////////////
    beforeUpload(file) {
      console.log(file)
      let _this = this;
      _this.file = file;
      _this.getMd5Sum(file);

      //获取oss的临时权限
      return new Promise((resolve, reject) => {
        sts()
          .then(response => {
            //获取临时访问权限成功
            _this.alidata.accessKeyId = response.access_key_id;
            _this.alidata.accessKeySecret = response.access_key_secret;
            _this.alidata.securityToken = response.security_token;
            _this.alidata.bucket = response.bucket;
            _this.alidata.region = response.region;
            resolve(true);
          })
          .catch(err => {
            reject(false);
          });
      });
    },
    httpRequest(file,fileList) {
      
      //验证图片格式必须是jpeg,jpg或者png,以及验证图片大小
      let _this = this;
      let picName = _this.file.name
      var myReg = /.*[\u4e00-\u9fa5]+.*$/;
      if (myReg.test(picName)) {
        _this.$message.error("图片名称不能是中文,请删除当前图片!");
        return false;
      }
      let testmsg = /^image\/(jpeg|png|jpg)$/.test(_this.file.type);
      let isLt10M = _this.file.size / 1024 / 1024 <= 10; //图片大小不超过10MB
      if (!testmsg) {
        _this.$message.error("上传图片格式不对!");
        return false;
      }
      if (!isLt10M) {
        _this.$message.error("上传图片大小不能超过10M!");
        return false;
      }
      //开始上传
      let temporary = _this.file.name.lastIndexOf(".");
      let fileNameLength = _this.file.name.length;
      let fileFormat = _this.file.name.substring(temporary + 1, fileNameLength);
      let fileName = getFileNameUUID() + "." + fileFormat; //uuid文件名称
      let filePath = `guanggao/park_2/${fileName}`;
      //存放上传文件的参数
      _this.param.fileName = _this.file.name;
      _this.param.filePath = filePath;
      _this.param.adType = 0;
      _this.param.comId = _this.searchForm.comId;
      console.log(_this.file)
      client(_this.alidata)
        .multipartUpload(filePath, _this.file, {
          progress: function(p) {
            //p进度条的值
            _this.showProgress = true;
            _this.progress = Math.floor(p * 100);
          }
        })
        .then(result => {
          console.log(result)
          //上传成功返回值，可针对项目需求写其他逻辑

          _this.param.uploadTime = new Date().getTime();
          uploadParam(_this.param).then(resp => {
            _this.$tip.apiSuccess('上传成功')
            _this.getList();
          });
        })
        .catch(err => {
          console.log("err:", err);
        });

      // multipartUpload();
    },
    onExceed(files, fileList) {
      this.$message.error("图片最多上传4张")
    },
    //////////////////////////上传图片结束///////////////////////////////////

    //////////////////////////上传视频//////////////////////////////////////
    // 文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      this.$message.error(`每次只能上传 1 个文件`);
    },
    //文件上传前的校验
    beforeAvatarUpload(file) {
      let _this = this;
      _this.getMd5Sum(file);
      _this.vedioFile = file;
      const isLt100M = file.size / 1024 / 1024 < 100;
      const isLt30 = file.name.length < 30;
      if (["video/mp4"].indexOf(file.type) == -1) {
        _this.$message.error("请上传正确的视频格式")
        return false;
      }
      if (!isLt100M) {
        _this.$message.error("上传视频大小必须小于100MB哦!");
        return false;
      }
      if (!isLt30) {
        _this.$message.error("上传视频文件名称长度必须要小于30个文字哦!");
        return false;
      }
      //获取oss的临时权限
      return new Promise((resolve, reject) => {
        sts()
          .then(response => {
            _this.alidata.accessKeyId = response.access_key_id;
            _this.alidata.accessKeySecret = response.access_key_secret;
            _this.alidata.securityToken = response.security_token;
            _this.alidata.bucket = response.bucket;
            _this.alidata.region = response.region;
            resolve(true);
          })
          .catch(err => {
            reject(false);
          });
      });
    },
    // 上传视频
    Upload(file) {
      
      const _this = this;
      async function multipartUpload() {
        let temporary = _this.vedioFile.name.lastIndexOf(".");
        let fileNameLength = _this.vedioFile.name.length;
        let fileFormat = _this.vedioFile.name.substring(
          temporary + 1,
          fileNameLength
        );
        let fileName = getFileNameUUID() + "." + fileFormat;
        let filePath = `guanggao/union/${fileName}`;
        //存放上传文件的参数
        _this.param.fileName = _this.vedioFile.name;
        _this.param.filePath = filePath;
        _this.param.adType = 1;
        _this.param.comId = _this.searchForm.comId;
        
        client(_this.alidata)
          .multipartUpload(filePath, _this.vedioFile, {
            progress: function(p) {
              //p进度条的值
              _this.showProgress = true;
              _this.progress = Math.floor(p * 100);
            }
          })
          .then(result => {
            console.log(result)
            //上传成功返回值，可针对项目需求写其他逻辑
            _this.param.uploadTime = new Date().getTime();
            uploadParam(_this.param).then(resp => {
              
              _this.$message({
                  message: resp.msg,
                  type: "success",
                  duration: 2 * 1000
                });
                //刷新页面
                _this.getList();
            });
          })
          .catch(err => {
            _this.$message.error(err);
          });
      }
      multipartUpload();
    },
    //////////////////////////上传视频结束///////////////////////////////////

    //md5
    getMd5Sum(file) {
      var _this = this;
      // 创建文件读取对象，此对象允许Web应用程序异步读取存储在用户计算机上的文件内容
      var fileReader = new FileReader();
      // 根据浏览器获取文件分割方法
      var blobSlice =
        File.prototype.mozSlice ||
        File.prototype.webkitSlice ||
        File.prototype.slice;
      // 指定文件分块大小(2M)
      var chunkSize = 2 * 1024 * 1024;
      // 计算文件分块总数
      var chunks = Math.ceil(file.size / chunkSize);
      // 指定当前块指针
      var currentChunk = 0;

      // 创建MD5计算对象
      var spark = new SparkMD5.ArrayBuffer();

      // 当读取操作成功完成时调用
      fileReader.onload = function(e) {
        // 将文件内容追加至spark中
        spark.append(e.target.result);
        currentChunk += 1;

        // 判断文件是否都已经读取完
        if (currentChunk < chunks) {
          loadNext();
        } else {
          //            console.log("md5值:"+spark.end())
          //            return
          return spark.end();
        }
      };

      fileReader.onerror = function() {
        console.warn("FileReader error.");
      };

      // FileReader分片式读取文件
      function loadNext() {
        // 计算开始读取的位置
        var start = currentChunk * chunkSize;
        // 计算结束读取的位置
        var end =
          start + chunkSize >= file.size ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }
      // FileReader分片式读取文件
      loadNext();
    },

    //删除单个广告
    onRemove(file, fileList) {
      if(file.status == 'ready'){
        fileList.map(item=>{
          if(item.adName == file.adName){
            fileList = fileList.pop()
          }
        })
      }else{
        let data = {
          delType:0,
          adId:file.id,
          comId:this.searchForm.comId
        }
        deleteAd(data).then(response => {
          this.$tip.apiSuccess(response)
          this.getList();
        }).catch(e => {
          this.$message.error(e)
          
        });
      }
      
      return false;
    },

    //全删广告
    deleteAllAD() {
      this.listQuery.delType = 1;
      this.listQuery.handle_type = 2;
      this.listQuery.comId = this.searchForm.comId;
      deleteAd(this.listQuery).then(response => {
        this.$tip.apiSuccess(response)
      }).catch(e=>{
        this.$message.error(e)
      });
    },
    //下发广告
    startAD() {
      startADByUnion(this.listQuery).then(response => {
        this.$tip.apiSuccess(response)
      }).catch(e=>{
        this.$message.error(e)
      });
    },

    getList() {
      getADInfoByUnion(this.searchForm.comId).then(response => {
        //重置缓存数据为空
        this.fileList = [];
        this.videoList = [];
        this.videoSrc = "";

        var rows = response;
        this.cacheData = response;
        if (rows != null) {
          for (let i = 0; i < rows.length; i++) {
            let url = rows[i].url;
            if (rows[i].adType == 1) {
              this.videoSrc = url;
              this.videoList.push(rows[i]);
            } else {
              this.fileList.push(rows[i]);
            }
          }
        }
        //检查审核状态
        // UPLOAD(4001, "已上传"),
        // CHECK(4002,"审核通过"),
        // SEND(4003,"已下发"),
        // NO_PASS(4004,"审核不通过");
        let count4001 = 0;
        let count4002 = 0;
        let count4004 = 0;
        for (let i = 0; i < response.length; i++) {
          if (response[i].checkState == 4001) {
            count4001 = count4001 + 1;
          }
          if (response[i].checkState == 4002) {
            count4002 = count4002 + 1;
          }
          if (response[i].checkState == 4004) {
            count4004 = count4004 + 1;
          }
        }
        if (count4002 != 0 && count4002 == response.length &&  response.length) {
          this.check_state = '审核通过';
        } else if (count4004 > 0) {
          this.check_state = '审核不通过';
        } else if (count4001 > 0) {
          this.check_state = '已上传';
        }
      });
    }
  }
};
</script>

<style scoped>
video {
  width: 55%;
}
.information {
  font-size: 14px;
  margin-bottom: 5px;
  color: #606266;
}
.information > span {
  font-weight: 600;
}
.information > span:nth-child(2) {
  margin-left: 10px;
  color: #05ad5d;
}
.progress {
  width: 62%;
  margin-left: 10px;
}
.title_02 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.app-container /deep/ .el-row{
  width:100%;
}
 .imageBox /deep/ .el-upload-list--picture-card .el-upload-list__item-actions{
  display: none;
}
</style>
