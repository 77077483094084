import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/device/pageIotInfo', data)
}

// 多个升级
export function upgradeBatch (data) {
  return request.post('/system/upgrade/upgradeBatch' + data)
}

// 单个升级
export function upgradeOne (data) {
  return request.post(`/system/upgrade/upgradeOne?nodeId=${data.nodeId}&autoReboot=${data.autoReboot}`, data)
}