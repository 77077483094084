<template>
  <div id="devicePlayer" v-loading="isLoging">
    <el-dialog title="视频播放"
      top="0"
      :close-on-click-modal="false"
      :visible="showVideoDialog"
      @close="close()">
      <player ref="videoPlayer"
        :visible.sync="showVideoDialog"
        :videoUrl="videoUrl"
        :height="false"
        :hasAudio="hasAudio"
        fluent
        autoplay
        live></player>
      <div id="shared" style="text-align: right; margin-top: 1rem;">
        <div style="width: 100%;">
          <div style="width: 100%; text-align: left;height: 60px;">
            <!-- <span>录像控制</span> -->
            <!-- <el-button-group style="margin-left: 1rem;">
              <el-button size="mini" class="el-icon-video-pause" title="开始" @click="gbPause()"></el-button>
              <el-button size="mini" class="el-icon-video-play" title="暂停" @click="gbPlay()"></el-button>
            </el-button-group> -->
            <el-date-picker style="float: right;" size="mini" v-model="videoHistory.date" type="date" value-format="yyyy-MM-dd" placeholder="日期" @change="queryRecords()"></el-date-picker>
          </div>
          <div style="width: 100%; text-align: left">
            <span class="demonstration" style="padding: 12px 36px 12px 0;float: left;">{{showStartTimeText}}</span>
            <el-slider style="width: 80%; float:left;" v-model="sliderTime" @change="gbSeek" :format-tooltip="formatTooltip" :disabled="!recordStartTime" :step='0.001'></el-slider>
            <span class="demonstration" style="padding: 12px 36px 12px 0;float: left;">{{showEndTimeText}}</span>
          </div>
        </div>
        <el-table :data="videoHistory.searchHistoryResult" height="150" v-loading="recordsLoading">
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="文件" prop="filePath"></el-table-column>
          <el-table-column label="开始时间" prop="startTime" :formatter="timeFormatter"></el-table-column>
          <el-table-column label="结束时间" prop="endTime" :formatter="timeFormatter"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-button icon="el-icon-video-play" size="mini" @click="playRecord(scope.row)">播放</el-button>
                <!-- <el-button icon="el-icon-download" size="mini" @click="downloadRecord(scope.row)">下载</el-button> -->
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <recordDownload ref="recordDownload"></recordDownload>
  </div>
</template>

<script>
import {nowYMD} from '../../utils/timeConversion'
import player from './jessibuca.vue'
import recordDownload from './recordDownload.vue'
export default {
  name: 'devicePlayer',
  props: ['checkPass', ],
  components: {
    player,
    recordDownload,
  },
  created() {},
  data() {
    return {
      video:
        'http://lndxyj.iqilu.com/public/upload/2019/10/14/8c001ea0c09cdc59a57829dabc8010fa.mp4',
      videoUrl: '',
      videoHistory: {
        date: '',
        searchHistoryResult: [], //媒体流历史记录搜索结果
      },
      showVideoDialog: true,
      streamId: '',
      app: '',
      mediaServerId: '',
      convertKey: '',
      tabActiveName: 'media',
      hasAudio: false,
      loadingRecords: false,
      recordsLoading: false,
      isLoging: false,
      controSpeed: 30,
      timeVal: 0,
      timeMin: 0,
      timeMax: 1440,
      presetPos: 1,
      cruisingSpeed: 100,
      cruisingTime: 5,
      cruisingGroup: 0,
      scanSpeed: 100,
      scanGroup: 0,
      tracks: [],
      coverPlaying: false,
      tracksLoading: false,
      recordPlay: '',
      showPtz: true,
      showRrecord: true,
      tracksNotLoaded: false,
      sliderTime: 0,
      seekTime: 0,
      recordStartTime: 0,
      showStartTimeText: '00:00:00',
      showEndTimeText: '23:59:59',
      mediaEndTime: 0,
      deviceId:"11000000011320000004",
      channelId:"11000000011180000001",
      timeFun: null,
      // pathUrl: 'http://192.168.1.196:18081'
      parthUrl: 'https://y.ist.wang/wvp',
      // parthUrl:'https://test22.sciseetech.com/wvp',
      playItem: {}
    }
  },
  mounted(){
    
    this.videoHistory.date = nowYMD() 
    this.deviceId = this.checkPass.deviceId
    this.channelId = this.checkPass.channelId
    this.queryRecords()
  },
  destroyed(){
    clearInterval(this.timeFun)
    this.timeFun = null
  },
  methods: {
    
    play: function (streamInfo, hasAudio) {
      this.hasAudio = hasAudio
      this.isLoging = false
      // this.videoUrl = streamInfo.rtc;
      this.videoUrl = this.getUrlByStreamInfo(streamInfo)
      this.streamId = streamInfo.stream
      this.app = streamInfo.app
      this.mediaServerId = streamInfo.mediaServerId
      this.playFromStreamInfo(false, streamInfo)
    },
    getUrlByStreamInfo(streamInfo) {
      let baseZlmApi =
        process.env.NODE_ENV === 'development'
          ? `${location.host}/debug/zlm`
          : `${location.host}/zlm`
      // return `${baseZlmApi}/${streamInfo.app}/${streamInfo.streamId}.flv`;
      // return `http://${baseZlmApi}/${streamInfo.app}/${streamInfo.streamId}.flv`;
      if (location.protocol === 'https:') {
        if (streamInfo.wss_flv === null) {
          console.error('媒体服务器未配置ssl端口, 使用http端口')
          // this.$message({
          //   showClose: true,
          //   message: '媒体服务器未配置ssl端口, ',
          //   type: 'error'
          // });
          return streamInfo.ws_flv
        } else {
          return streamInfo.wss_flv
        }
      } else {
        return streamInfo.ws_flv
      }
    },
    coverPlay: function () {
      var that = this
      this.coverPlaying = true
      this.$refs.videoPlayer.pause()
      that
        .$axios({
          method: 'post',
          url: '/api/gb_record/convert/' + that.streamId,
        })
        .then(function (res) {
          if (res.data.code == 0) {
            that.convertKey = res.data.key
            setTimeout(() => {
              that.isLoging = false
              that.playFromStreamInfo(false, res.data.data)
            }, 2000)
          } else {
            that.isLoging = false
            that.coverPlaying = false
            that.$message({
              showClose: true,
              message: '转码失败',
              type: 'error',
            })
          }
        })
        .catch(function (e) {
          console.log(e)
          that.coverPlaying = false
          that.$message({
            showClose: true,
            message: '播放错误',
            type: 'error',
          })
        })
    },
    convertStopClick: function () {
      this.convertStop(() => {
        this.$refs.videoPlayer.play(this.videoUrl)
      })
    },
    convertStop: function (callback) {
      var that = this
      that.$refs.videoPlayer.pause()
      this.$axios({
        method: 'post',
        url: '/api/play/convertStop/' + this.convertKey,
      })
        .then(function (res) {
          if (res.data.code == 0) {
            console.log(res.data.msg)
          } else {
            console.error(res.data.msg)
          }
          if (callback) callback()
        })
        .catch(function (e) {})
      that.coverPlaying = false
      that.convertKey = ''
      // if (callback )callback();
    },

    playFromStreamInfo: function (realHasAudio, streamInfo) {
      this.showVideoDialog = true
      this.hasaudio = realHasAudio && this.hasaudio
      this.$refs.videoPlayer.play(this.getUrlByStreamInfo(streamInfo))
    },
    close: function () {
      console.log('关闭视频')
      if (!!this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause()
      }
      this.videoUrl = ''
      if (this.recordPlay != '') {
        this.stopPlayRecord()
      }
      this.recordPlay = ''
      this.$emit('closeDevice')
    },

    copySharedInfo: function (data) {
      console.log('复制内容：' + data)
      this.coverPlaying = false
      this.tracks = []
      let _this = this
      this.$copyText(data).then(
        function (e) {
          _this.$message({
            showClose: true,
            message: '复制成功',
            type: 'success',
          })
        },
        function (e) {
          _this.$message({
            showClose: true,
            message: '复制失败，请手动复制',
            type: 'error',
          })
        }
      )
    },

    queryRecords: function () {
      // if (!this.videoHistory.date) {
      //   return
      // }
      this.recordsLoading = true
      this.videoHistory.searchHistoryResult = []
      let that = this
      console.log(this.videoHistory)
      var startTime = this.videoHistory.date + '%200:00:00'
      var endTime = this.videoHistory.date + '%2023:59:59'
      var xml =new XMLHttpRequest();
      var addr = `${this.parthUrl}/api/gb_record/query/${that.deviceId}/${that.channelId}?startTime=${startTime}&endTime=${endTime}`;
      // var addr = `${this.parthUrl}/api/gb_record/query/11000000011180000001/11000000011320000004?startTime=2022-4-13%2000:00:00&endTime=2022-4-13%2023:59:59`
      xml.onreadystatechange=function()
      {
        if (xml.readyState==4 && xml.status==200)
        {
          // 处理时间信息
          let res = JSON.parse(xml.response)
          that.videoHistory.searchHistoryResult = res.recordList
          that.recordsLoading = false
        }
      }
      xml.open("GET", addr, true);
	    xml.send(); 
    },
    onTimeChange: function (video) {
      // this.queryRecords()
    },
    playRecord: function (row) {
      let that = this
      clearInterval(this.timeFun)
      this.playItem = row
      this.timeFun = null
      let startTime = row.startTime
      this.recordStartTime = row.startTime
      this.showStartTimeText = row.startTime.split(' ')[1]
      let endtime = row.endTime
      this.showEndTimeText = row.endTime.split(' ')[1]
      this.sliderTime = 0
      this.seekTime = new Date(endtime).getTime() - new Date(startTime).getTime()
      console.log(this.seekTime)
      if (that.streamId != '') {
        that.stopPlayRecord(function () {
          that.streamId = ''
          that.playRecord(row)
        })
      } else {
        console.log('历史的时间',this.videoHistory.date,'现在的时间',row.endTime)
        var xml =new XMLHttpRequest();
        var addr = `${this.parthUrl}/api/playback/start/${that.deviceId}/${that.channelId}?startTime=${row.startTime}&endTime=${row.endTime}`;
        // var addr = `${this.parthUrl}/api/playback/start/11000000011180000001/11000000011320000004?startTime=${row.startTime}&endTime=${row.endTime}`;
        xml.onreadystatechange=function()
          {
            if (xml.readyState==4 && xml.status==200)
            {
              // 处理时间信息
              let res = JSON.parse(xml.response)
              console.log(res)
              var streamInfo = res
              that.app = streamInfo.app
              that.streamId = streamInfo.stream
              that.mediaServerId = streamInfo.mediaServerId
              that.ssrc = streamInfo.ssrc
              that.videoUrl = that.getUrlByStreamInfo(streamInfo)
              that.recordPlay = true
              that.setTime()
              that.timeFun = setInterval(()=>{
                that.setTime()
              },1000)
              // 记录下结束时间
              that.mediaEndTime = row.endTime
            }
          }
        xml.open("GET", addr, true);
        xml.send(); 
      }
    },
    setTime(){
      let that = this
      if(this.showStartTimeText == this.playItem.endTime.split(' ')[1]){
        that.stopPlayRecord(function () {
          that.streamId = ''
        })
        this.showStartTimeText = '00:00:00'
        this.sliderTime = 0
        return false
      }
      let time0 = this.playItem.startTime
      let time = this.videoHistory.date + ' ' + this.showStartTimeText
      let showEndTimeText = this.playItem.endTime + ' ' + this.showEndTimeText
      let showTime = new Date(
        new Date(time).getTime() + 1000
      )
      let showTime2 = new Date(showEndTimeText).getTime()   //总时长
      let showTime3 = new Date(time).getTime() + 1000   //播放位置
      let showTime0 = new Date(time0).getTime()   //开始时长
      this.sliderTime = (showTime3 - showTime0) / (showTime2 - showTime0) * 100
      // console.log('this.recordStartTime11111',showTime3 ,'all2222222',showTime2)
      // this.showStartTimeText = showTime
      this.showStartTimeText = this.timeHFM(showTime)
    },
    timeHFM(time){
      let hour = time.getHours()
      let minutes = time.getMinutes()
      let seconds = time.getSeconds()
      return (hour < 10 ? '0' + hour : hour) +
        ':' +
        (minutes < 10 ? '0' + minutes : minutes) +
        ':' +
        (seconds < 10 ? '0' + seconds : seconds)
    },
    stopPlayRecord: function (callback) {
      let that = this;
      this.$refs.videoPlayer.pause()
      this.videoUrl = ''
      var xml =new XMLHttpRequest();
      var addr = `${this.parthUrl}/api/playback/stop/${that.deviceId}/${that.channelId}/${that.streamId}`;
      // var addr = `${this.parthUrl}/api/playback/stop/11000000011180000001/11000000011320000004/${this.streamId}`;
      xml.onreadystatechange=function()
        {
          if (xml.readyState==4 && xml.status==200)
          {
            if (callback) callback()
          }
        }
      xml.open("GET", addr, true);
      xml.send(); 
    },

    formatTooltip: function (val) {
      // let showTime = new Date(
      //   new Date(this.recordStartTime).getTime()
      // )
      // let hour = showTime.getHours()
      // let minutes = showTime.getMinutes()
      // let seconds = showTime.getSeconds()
      // let showStartTimeText =
      //   (hour < 10 ? '0' + hour : hour) +
      //   ':' +
      //   (minutes < 10 ? '0' + minutes : minutes) +
      //   ':' +
      //   (seconds < 10 ? '0' + seconds : seconds)
      //   console.log('时间',this.recordStartTime,showStartTimeText,this.recordStartTime ? showStartTimeText : '00:00:00')
      // return this.showStartTimeText == '00:00:00' ? '00:00:00' : showStartTimeText
      return this.showStartTimeText
    },
    timeFormatter: function (row, column, cellValue, index) {
      return cellValue.split(' ')[1]
    },
    gbPlay() {
      console.log('前端控制：播放')
      var xml =new XMLHttpRequest();
      var addr = `${this.parthUrl}/api/playback/resume/${this.streamId}`;
      xml.onreadystatechange=function(){
        if (xml.readyState==4 && xml.status==200){}
      }
      xml.open("GET", addr, true);
      xml.send(); 
    },
    gbPause() {
      console.log('前端控制：暂停')
      var xml =new XMLHttpRequest();
      var addr = `${this.parthUrl}/api/playback/pause/${this.streamId}`;
      xml.onreadystatechange=function(){
        if (xml.readyState==4 && xml.status==200){}
      }
      xml.open("GET", addr, true);
      xml.send(); 
    },
    gbScale(command) {
      var xml =new XMLHttpRequest();
      var addr = `${this.parthUrl}/api/playback/speed/${this.streamId}`;
      xml.onreadystatechange=function(){
        if (xml.readyState==4 && xml.status==200){}
      }
      xml.open("GET", addr, true);
      xml.send(); 
      console.log('前端控制：倍速 ' + command)
      // this.$axios({
      //   method: 'get',
      //   url: `/api/playback/speed/${this.streamId}/${command}`,
      // }).then(function (res) {})
    },
    gbSeek(val) {
      if(isNaN(val)){
        return false
      }
      let that = this;
      // 伪seek，先关闭视频再重新在选定时间打开视频
      // console.log('*********************',this.videoHistory)
      console.log(this.playItem)
      // console.log('前端控制：seek ',this.recordStartTime,'--------------------',this.seekTime,val)
      // console.log(this.seekTime)
      // console.log(this.sliderTime)
      let itemStart = this.get_unix_time(this.playItem.startTime)
      let start = this.playItem.startTime.split(' ')[1]
      let end = this.playItem.endTime.split(' ')[1]
      let seekTime = new Date(this.playItem.endTime).getTime() - new Date(this.playItem.startTime).getTime()
      console.log('seekTime',seekTime)
      let showTime = new Date(
        // new Date(this.recordStartTime).getTime() + (this.seekTime * val) / 100
        (+itemStart * 1000) + (seekTime * val) / 100
      )
       console.log('itemStartitemStartitemStartitemStartitemStart',itemStart,seekTime,val)
      let hour = showTime.getHours()
      let minutes = showTime.getMinutes()
      let seconds = showTime.getSeconds()
      this.showStartTimeText =
        (hour < 10 ? '0' + hour : hour) +
        ':' +
        (minutes < 10 ? '0' + minutes : minutes) +
        ':' +
        (seconds < 10 ? '0' + seconds : seconds)
      if (that.streamId != '') {
        console.info("当前正在播放，关闭当前播放")  
        that.stopPlayRecord(function () {
          that.streamId = ''
          that.gbSeek(val)
        })
      } else {
        var xml =new XMLHttpRequest();
        var addr = `${this.parthUrl}/api/playback/start/${that.deviceId}/${that.channelId}?startTime=${showTime.format("yyyy-MM-dd hh:mm:ss")}&endTime=${this.mediaEndTime}`;
        xml.onreadystatechange=function(){
          if (xml.readyState==4 && xml.status==200){
            // 处理时间信息
            let res = JSON.parse(xml.response)
            console.log(res)
            var streamInfo = res
            that.app = streamInfo.app
            that.streamId = streamInfo.stream
            that.mediaServerId = streamInfo.mediaServerId
            that.ssrc = streamInfo.ssrc
            that.videoUrl = that.getUrlByStreamInfo(streamInfo)
            that.recordPlay = true
          }
        }
        xml.open("GET", addr, true);
        xml.send(); 
      }
    },
    get_unix_time(dateStr) {
      var newstr = dateStr.replace(/-/g, '/');
      var date = new Date(newstr);
      var time_str = date.getTime().toString();
      return time_str.substr(0, 10);
    },
  },
  
}

Date.prototype.format = function(fmt) { 
     var o = { 
        "M+" : this.getMonth()+1,                 //月份 
        "d+" : this.getDate(),                    //日 
        "h+" : this.getHours(),                   //小时 
        "m+" : this.getMinutes(),                 //分 
        "s+" : this.getSeconds(),                 //秒 
        "q+" : Math.floor((this.getMonth()+3)/3), //季度 
        "S"  : this.getMilliseconds()             //毫秒 
    }; 
    if(/(y+)/.test(fmt)) {
            fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
    }
     for(var k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
             fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
         }
     }
    return fmt; 
} 
</script>

<style>
.control-wrapper {
  position: relative;
  width: 6.25rem;
  height: 6.25rem;
  max-width: 6.25rem;
  max-height: 6.25rem;
  border-radius: 100%;
  margin-top: 1.5rem;
  margin-left: 0.5rem;
  float: left;
}

.control-panel {
  position: relative;
  top: 0;
  left: 5rem;
  height: 11rem;
  max-height: 11rem;
}

.control-btn {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 44%;
  height: 44%;
  border-radius: 5px;
  border: 1px solid #78aee4;
  box-sizing: border-box;
  transition: all 0.3s linear;
}
.control-btn:hover {
  cursor: pointer;
}

.control-btn i {
  font-size: 20px;
  color: #78aee4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.control-btn i:hover {
  cursor: pointer;
}
.control-zoom-btn:hover {
  cursor: pointer;
}

.control-round {
  position: absolute;
  top: 21%;
  left: 21%;
  width: 58%;
  height: 58%;
  background: #fff;
  border-radius: 100%;
}

.control-round-inner {
  position: absolute;
  left: 13%;
  top: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 70%;
  font-size: 40px;
  color: #78aee4;
  border: 1px solid #78aee4;
  border-radius: 100%;
  transition: all 0.3s linear;
}

.control-inner-btn {
  position: absolute;
  width: 60%;
  height: 60%;
  background: #fafafa;
}

.control-top {
  top: -8%;
  left: 27%;
  transform: rotate(-45deg);
  border-radius: 5px 100% 5px 0;
}

.control-top i {
  transform: rotate(45deg);
  border-radius: 5px 100% 5px 0;
}

.control-top .control-inner {
  left: -1px;
  bottom: 0;
  border-top: 1px solid #78aee4;
  border-right: 1px solid #78aee4;
  border-radius: 0 100% 0 0;
}

.control-top .fa {
  transform: rotate(45deg) translateY(-7px);
}

.control-left {
  top: 27%;
  left: -8%;
  transform: rotate(45deg);
  border-radius: 5px 0 5px 100%;
}

.control-left i {
  transform: rotate(-45deg);
}

.control-left .control-inner {
  right: -1px;
  top: -1px;
  border-bottom: 1px solid #78aee4;
  border-left: 1px solid #78aee4;
  border-radius: 0 0 0 100%;
}

.control-left .fa {
  transform: rotate(-45deg) translateX(-7px);
}

.control-right {
  top: 27%;
  right: -8%;
  transform: rotate(45deg);
  border-radius: 5px 100% 5px 0;
}

.control-right i {
  transform: rotate(-45deg);
}

.control-right .control-inner {
  left: -1px;
  bottom: -1px;
  border-top: 1px solid #78aee4;
  border-right: 1px solid #78aee4;
  border-radius: 0 100% 0 0;
}

.control-right .fa {
  transform: rotate(-45deg) translateX(7px);
}

.control-bottom {
  left: 27%;
  bottom: -8%;
  transform: rotate(45deg);
  border-radius: 0 5px 100% 5px;
}

.control-bottom i {
  transform: rotate(-45deg);
}

.control-bottom .control-inner {
  top: -1px;
  left: -1px;
  border-bottom: 1px solid #78aee4;
  border-right: 1px solid #78aee4;
  border-radius: 0 0 100% 0;
}

.control-bottom .fa {
  transform: rotate(-45deg) translateY(7px);
}
.trank {
  width: 80%;
  height: 180px;
  text-align: left;
  padding: 0 10%;
  overflow: auto;
}
.trankInfo {
  width: 80%;
  padding: 0 10%;
}
</style>
