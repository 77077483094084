<template>
  <TableLayout class="menu-layout" :permissions="['system:trtc:getCallLog']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="车场编号" prop="passName">
        <el-input v-model="searchForm.comId" v-trim placeholder="请输入车场编号" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="searchDateRange"
          type="datetimerange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="handleSearchTimeChange"
        ></el-date-picker>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        :default-expand-all="false"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="id" label="序号"></el-table-column>
        <el-table-column prop="comId" label="车场号"></el-table-column>
        <el-table-column prop="passId" label="通道号" min-width="120"></el-table-column>
        <el-table-column prop="userName" label="通话用户" min-width="120"></el-table-column>
        <el-table-column prop="startTime" label="开始时间" min-width="120">
          <template slot-scope="{row}">{{$getTime(row.startTime)}}</template>
        </el-table-column>
        <el-table-column prop="startTime" label="结束时间" min-width="120">
          <template slot-scope="{row}">{{$getTime(row.endTime)}}</template>
        </el-table-column>
        <el-table-column prop="userAudioMinute" label="通话时间(分钟)" min-width="120"></el-table-column>
        
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>

    <recharge ref="operaDefaultRules" :search="search"></recharge>
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import recharge from '@/components/addValueAdded/recharge'
import {queryAllMenu} from '@/api/alarm/alarm'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination,recharge},
  data () {
    return {
      value:false,
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中recharge
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        endTime:'',
        startTime:'',
        comId:''
      },
      statusList:[],
      statusData:{},
      productList:[],
      productData:{},
      triggerSourceList:[],
      triggerSourceData:{},
      alarmTypeData:{},
      unionUserData:{},
      typeList:[
        {value_name:'短信充值',value_no:'1'},
        {value_name:'音视频充值',value_no:'2'}
      ]
    }
  },
  mounted(){
    
  },
  methods: {
    // 时间搜索范围变化
    handleSearchTimeChange (value) {
      this.searchForm.startTime = null
      this.searchForm.endTime = null
      if (value != null) {
        this.searchForm.startTime = value[0] / 1000
        this.searchForm.endTime = value[1] / 1000
      }
      this.search()
    },
  },
  created () {
    this.config({
      module: '菜单',
      api: '/addValueAdded/trtcCall',
      "sorts":[
        {
            "property":"t.start_time",
            "direction":"DESC"
        }
      ]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
