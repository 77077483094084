import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/com/ysCameraInfo', data)
}
// 所属车场查询
export function parkingList () {
    return request.get('/system/com/managerComList')
  }

// // 新建
// export function create (data) {
//   return request.post('/system/tenant/add', data)
// }

// // 修改
// export function updateById (data) {
//   return request.post('/system/tenant/updateById', data)
// }

// // 修改状态
// export function updateStatus (data) {
//   return request.post('/system/tenant/updateStatus', data)
// }

// // 删除
// export function deleteById (id) {
//   return request.post(`/system/tenant/delete/?id=${id}`)
// }
