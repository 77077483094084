<template>
  <div>
    <TableLayout class="menu-layout" :permissions="['system:alarm:queryRule']" v-if="!defayltTableShow">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline >
        <el-form-item label="产品">
          <el-select v-model="searchForm.product" placeholder="请选择产品">
            <el-option :label="item.label" :value="item.value" v-for="(item,i) in productList" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.state" placeholder="请选择状态">
            <el-option :label="item.value_name" :value="item.value_no" v-for="(item,i) in statusList" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="触发源" prop="loginTime">
          <el-select v-model="searchForm.triggerSource" placeholder="请选择触发源">
            <el-option :label="item.value_name" :value="item.value_no" v-for="(item,i) in triggerSourceList" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <section>
          <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
          <el-button @click="reset">重置</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->
      <template v-slot:table-wrap>
        <ul class="toolbar" v-permissions="['system:alarm:addRule']">
          <li><el-button icon="el-icon-setting" @click="defayltTableShow = true">默认启用</el-button></li>
          <li v-permissions="['system:alarm:addRule']"><el-button type="primary" @click="$refs.operaDefaultRules.open('新建告警规则')" icon="el-icon-plus">新建</el-button></li>
          
        </ul>
        <el-table
          ref="table"
          v-loading="isWorking.search"
          :data="tableData.list"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          row-key="id"
          stripe
          height="60vh"
          :default-expand-all="false"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" class="demo-table-expand">
                <el-form-item label="类型:">
                  <span>{{ alarmTypeData[ props.row.alarmType] }}</span>
                </el-form-item>
                <el-form-item label="触发条件:">
                  <span>{{ props.row.trigger }}</span>
                </el-form-item>
                <el-form-item label="接收人:">
                  <span>{{ props.row.usersStr }}</span>
                </el-form-item>
                <el-form-item label="通知方式:">
                  <span>{{ props.row.noticeWay }}</span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="编号"></el-table-column>
          <el-table-column prop="ruleName" label="名称" min-width="120"></el-table-column>
          <el-table-column prop="product" label="产品">
            <template slot-scope="{row}">{{productData[row.product]}}</template>
          </el-table-column>
          <el-table-column prop="triggerSource" label="触发源">
            <template slot-scope="{row}">{{triggerSourceData[row.triggerSource]}}</template>
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="{row}">{{statusData[row.state]}}</template>
          </el-table-column>
          <el-table-column
            v-if="containPermissions(['system:alarm:editRule', 'system:alarm:deleteRule', 'system:alarm:operateRule'])"
            label="操作"
            min-width="140"
            fixed="right"
          >
            <template slot-scope="{row}">
              <el-button type="text" icon="el-icon-edit" @click="$refs.operaDefaultRules.open('编辑告警规则',row)" v-permissions="['system:alarm:editRule']">编辑</el-button>
              <el-button type="text" icon="el-icon-circle-check" style="color:#F56C6C;" @click="deleteById(row)" v-permissions="['system:alarm:deleteRule']">删除</el-button>
              <el-switch v-model="row.states" style="margin-left:16px;" @change="switchState(row)" v-permissions="['system:alarm:operateRule']"> </el-switch>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        ></pagination>
      </template>
    </TableLayout>
    <DefaultRules v-if="defayltTableShow"></DefaultRules>
    <addDefaultRules 
      ref="operaDefaultRules" 
      @success="handlePageChange1(tableData.pagination.pageIndex)"
      :productList="productList" 
      :alarmTypeList="alarmTypeList" 
      :cycleList="cycleList"
      :levelList="levelList"
      :conditionList="conditionList"
      :unitList="unitList"
      :unionUserList="unionUserList"
      :noticeWayList="noticeWayList"
      :rankList="rankList"
      :triggerSourceList="triggerSourceList"/>
  </div>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import DefaultRules from '@/components/alarm/DefaultRules'
import addDefaultRules from '@/components/alarm/addDefaultRules'
import {queryAllMenu,operateRule} from '@/api/alarm/alarm'
import { deviceCategoryGetEnum } from '@/api/parking/deviceInfo'
import Vue from 'vue'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination,DefaultRules,addDefaultRules},
  data () {
    return {
      value:false,
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        product:'',
        state:'',
        tenantId:'',
        triggerSource:'',
      },
      statusList:[],
      statusData:{},
      productList:[],
      productData:{},
      triggerSourceList:[
        {value_no: 1002, value_name: "平台触发"}
      ],
      triggerSourceData:{},
      alarmTypeData:{},
      unionUserData:{},
      defayltTableShow:false,
      alarmTypeList:[
        // {value_no: 2005, value_name: "IOT设备离线"},
        // {value_no: 2010, value_name: "离线"},
        // {value_no: 2009, value_name: "子设备离线"}
        {value_no: 2005, value_name: "设备离线"}
      ],
      cycleList:[],
      levelList:[],
      conditionList:[],
      unitList:[],
      unionUserList:[],
      rankList:[],
      noticeWayList:[]
    }
  },
  mounted(){
    this.deviceCategoryGetEnumFun()
  },
  methods: {
    deviceCategoryGetEnumFun() {
      this.productLis = []
      deviceCategoryGetEnum().then(res=>{
        res.map(item => {
          if (item.label != '手机APP' && item.label != '看板软件' && item.label != '对讲软件') {
            this.productList.push(item)
          }
          // this.devicetypeObj[item.value] = item.label
        })
      }).catch(e=>{
        this.$message.error(e);
      })
    },
    handlePageChange1(){
      if(this.$refs.operaDefaultRules){
        this.$refs.operaDefaultRules.form = {
          id:null,
          triggerWay:3001,
          noticeWay:'',
          startTime:'00:00:00',
          endTime:'23:59:59',
          hour:1,
          times:1
        }
        this.$refs.operaDefaultRules.noticeWay = [];
        this.$refs.operaDefaultRules.unionUser = [];
      }
      this.handlePageChange()
    },
    //获取所有枚举
    queryAllMenuFuc(){
      queryAllMenu()
        .then(records => {
          // this.productList = JSON.parse(JSON.stringify(records.product))
          this.statusList = JSON.parse(JSON.stringify(records.status))
          // this.triggerSourceList = JSON.parse(JSON.stringify(records.triggerSource))
          // this.alarmTypeList = JSON.parse(JSON.stringify(records.alarmType))
          this.cycleList = JSON.parse(JSON.stringify(records.cycle))
          this.levelList = JSON.parse(JSON.stringify(records.level))
          this.conditionList = JSON.parse(JSON.stringify(records.condition))
          this.unitList = JSON.parse(JSON.stringify(records.unit))
          this.unionUserList = JSON.parse(JSON.stringify(records.unionUser))
          this.rankList = JSON.parse(JSON.stringify(records.rank))
          this.noticeWayList = JSON.parse(JSON.stringify(records.noticeWay))
          records.product.map(item=>{
            this.productData[item.value_no] = item.value_name
          })
          records.status.map(item=>{
            this.statusData[item.value_no] = item.value_name
          })
          records.triggerSource.map(item=>{
            this.triggerSourceData[item.value_no] = item.value_name
          })
          records.alarmType.map(item=>{
            // this.alarmTypeList.push(item)
            this.alarmTypeData[item.value_no] = item.value_name
          })
          records.unionUser.map(item=>{
            this.unionUserData[item.value_no] = item.value_name
          })
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
        })
    },
    //修改状态
    switchState(row){
      let data = {};
      data.id = row.id;
      data.state = row.states ? 0 : 2
      operateRule(data)
        .then(records => {
          this.$tip.apiSuccess('操作成功')
          this.search();
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
        })
    },
    //
    closeDefayltTable(){
      this.defayltTableShow = false;
    },
  },
  created () {
    this.queryAllMenuFuc()
    this.config({
      module: '菜单',
      api: '/alarm/alarm',
      'field.main': 'ruleName'
      
    })
    this.search('alarm')
    
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
