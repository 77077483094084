<template>
  <TableLayout class="menu-layout" :permissions="['system:omr:call']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="呼叫类型">
        <el-select v-model="searchForm.callType" placeholder="呼叫类型" clearable>
          <el-option v-for="item in callTypeOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="通道类型">
        <el-select v-model="searchForm.passType" placeholder="请选择车场类型">
          <el-option label="入" value="0"></el-option>
          <el-option label="出" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="呼叫时间" prop="loginTime">
        <el-date-picker
          v-model="searchDateRange"
          type="datetimerange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="handleSearchTimeChange"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="呼叫设备编号">
          <el-input v-model="searchForm.callerSn" placeholder="呼叫设备编号" clearable />
        </el-form-item>
        <el-form-item label="操作人员编号">
          <el-input v-model="searchForm.userId" placeholder="操作人员编号" clearable />
        </el-form-item>
        <el-form-item label="车场编号">
          <el-input v-model="searchForm.comId" placeholder="车场编号" clearable />
        </el-form-item>
        <el-form-item label="处理状态">
          <el-select v-model="searchForm.status" placeholder="处理状态" clearable>
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="resetNew">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        default-expand-all
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="id" label="记录编号"></el-table-column>
        <el-table-column prop="callerSn" label="呼叫设备编号" min-width="160px"></el-table-column>
        <el-table-column prop="comid" label="车场编号" min-width="80px"></el-table-column>
        <el-table-column prop="userId" label="操作人员编号" min-width="100px"></el-table-column>
        <el-table-column prop="description" label="呼叫时间" min-width="100px">
          <template slot-scope="{row}">{{$getTime(row.startTime)}}</template>
        </el-table-column>
        <el-table-column prop="description" label="结束时间" min-width="100px">
          <template slot-scope="{row}">{{$getTime(row.completeTime)}}</template>
        </el-table-column>
        <el-table-column prop="description" label="处理状态" min-width="100px">
          <template slot-scope="{row}">
            <el-tag :type="statusData[row.status].type">{{statusData[row.status].label}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="呼叫类型" min-width="100px">
          <template slot-scope="{row}">{{callTypeList[row.callType]}}</template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination},
  data () {
    return {
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        callType:'',
        calledSn:'',
        callerSn:'',
        comId:'',
        endTime:'',
        startTime:'',
        status:'',
        userId:''
      },
      callTypeList:{
        0:'相机主叫',
        1:'APP主叫',
        2:'APP广播',
        3:'WEB主叫',
      },
      statusData:{
        0:{label:'未处理',type:'danger'},
        1:{label:'处理中',type:'warning'},
        2:{label:'已处理',type:'success'},
        3:{label:'超时未处理',type:'danger'},
      },
      statusOptions: [{
        value: '0',
        label: '未处理'
      }, {
        value: '1',
        label: '处理中'
      }, {
        value: '2',
        label: '已处理'
      }],
      callTypeOptions: [{
        value: '0',
        label: '相机主叫'
      }, {
        value: '1',
        label: 'APP主叫'
      }, {
        value: '2',
        label: 'APP广播'
      }]
    }
  },
  methods: {
    // 时间搜索范围变化
    handleSearchTimeChange (value) {
      this.searchForm.startTime = null
      this.searchForm.endTime = null
      if (value != null) {
        this.searchForm.startTime = value[0] / 1000
        this.searchForm.endTime = value[1] / 1000
      }
      this.search()
    },
    //重置
    resetNew(){
      this.searchForm = {
        startTime:this.searchDateRange[0] / 1000,
        endTime:this.searchDateRange[1] / 1000,
        callType: "",
        calledSn: "",
        callerSn: "",
        comId: "",
        status: "",
        userId: "",
      }
      this.search()
    },
  },
  created () {
    const timeStart=new Date(new Date().toLocaleDateString()).getTime();
    let timeEnd=new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)
    this.searchDateRange = [timeStart-86400000*3,timeEnd]
    this.searchForm.startTime = this.searchDateRange[0] / 1000
    this.searchForm.endTime = +((this.searchDateRange[1] / 1000).toFixed(0)) - 1
    this.config({
      module: '菜单',
      api: '/omr/call',
      sorts: [{
        property: 'start_time',
        direction: 'DESC'
      }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
