<template>
  <TableLayout class="menu-layout" :permissions="['system:omr:alarmRecord']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="车场编号" >
        <el-input v-model="searchForm.comId" placeholder="请输入车场编号" clearable />
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="searchForm.status" placeholder="请选择车场类型">
          <el-option :label="item.label" :value="item.value" v-for="(item,i) in selectArr" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="告警时间" prop="loginTime">
        <el-date-picker
          v-model="searchDateRange"
          type="datetimerange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="handleSearchTimeChange"
        ></el-date-picker>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="resetNew">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        default-expand-all
        @selection-change="handleSelectionChange"
      >
         <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="comid" label="车场号"></el-table-column>
        <el-table-column prop="channelId" label="通道">
          <template slot-scope="{row}">{{passData[row.channelId]}}</template>
        </el-table-column>
        <el-table-column prop="iotEquipId" label="设备编号" min-width="180px"></el-table-column>
        <el-table-column prop="alarmTime" label="告警时间" min-width="130px">
          <template slot-scope="{row}">{{$getTime(row.alarmTime)}}</template>
        </el-table-column>
        <el-table-column prop="alarmMsg" label="告警内容" min-width="100px"></el-table-column>
        <el-table-column prop="level" label="级别">
          <template slot-scope="{row}">{{level[row.level]}}</template>
        </el-table-column>
        <el-table-column prop="describe" label="描述" min-width="180px"></el-table-column>
        <el-table-column prop="handleTime" label="处理时间" min-width="130px">
          <template slot-scope="{row}">{{$getTime(row.handleTime)}}</template>
        </el-table-column>
        <el-table-column prop="handleUser" label="处理人" min-width="100px"></el-table-column>
        <el-table-column prop="status" label="处理状态">
          <template slot-scope="{row}">
            <el-tag type="danger" v-if="row.status == '10001'">未处理</el-tag>
            <el-tag type="info" v-if="row.status == '10003'">忽略</el-tag>
            <el-tag type="success" v-if="row.status == '10002'">已处理</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="100px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['system:alarm:process'])"
          label="操作"
          min-width="140"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" icon="el-icon-edit" @click="openPrompt(row,'10002')" v-permissions="['system:alarm:process']">处理</el-button>
            <el-button type="text" icon="el-icon-circle-check" @click="deleteById(row,'10003')" v-permissions="['system:alarm:process']">忽略</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>


  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import {alarmOperate,getPass} from '@/api/omr/alarmRecord'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination},
  data () {
    return {
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        alarmMsg:'',
        alarmTimeEnd:'',
        alarmTimeStart:'',
        channelId:'',
        comId:'',
        handleTimeEnd:'',
        handleTimeStart:'',
        handleUser:'',
        id:'',
        iotEquipId:'',
        level:'',
        status:null,
      },
      selectArr: [
        { label: "未处理", value: "10001" },
        { label: "已处理", value: "10002" },
        { label: "忽略", value: "10003" },
      ],
      level:{
        4001:'提示',
        4002:'警告',
        4003:'严重',
        4004:'紧急',
      },
      passData:{}
    }
  },
  mounted(){
    this.getPassFuc()
  },
  methods: {
    //获取通道数据
    getPassFuc(){
      getPass()
        .then(records => {
          records.map(item=>{
            this.passData[item.id] = item.passname
          })
          
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
        })
    },
    //处理警告
    handleAlarm(item,status,value){
      let data = {
        id:item.id,
        mark:value,
        status:status
      }
      alarmOperate(data)
        .then(records => {
          this.handleSizeChange()
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
        })
    },
    
    openPrompt(item,status){
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.handleAlarm(item,status,value)
      }).catch(() => {
               
      });
    },
    // 时间搜索范围变化
    handleSearchTimeChange (value) {
      this.searchForm.alarmTimeEnd = null
      this.searchForm.alarmTimeStart = null
      if (value != null) {
        this.searchForm.alarmTimeStart = value[0] / 1000
        this.searchForm.alarmTimeEnd = value[1] / 1000
      }
      this.search()
    },
    //重置
    resetNew(){
      this.searchForm = {
        alarmMsg:'',
        alarmTimeEnd:'',
        alarmTimeStart:'',
        channelId:'',
        comId:'',
        handleTimeEnd:'',
        handleTimeStart:'',
        handleUser:'',
        id:'',
        iotEquipId:'',
        level:'',
        status:null,
      }
      this.search()
    },
  },
  created () {
    const timeStart=new Date(new Date().toLocaleDateString()).getTime();
    let timeEnd=new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)
    this.searchDateRange = [timeStart-86400000*3,timeEnd]
    this.searchForm.alarmTimeStart = this.searchDateRange[0] / 1000
    this.searchForm.alarmTimeEnd = +((this.searchDateRange[1] / 1000).toFixed(0)) - 1
    this.config({
      module: '菜单',
      api: '/omr/alarmRecord',
      // sorts: [{
      //   property: 'start_time',
      //   direction: 'DESC'
      // }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
