<template>
  <TableLayout class="menu-layout">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="设备类型">
        <el-select v-model="searchForm.category" placeholder="请选择设备类型" @change="changedeciveChidList('searchForm')">
          <el-option :label="item.name" :value="item.code" v-for="(item) in deciveList" :key="item.code + '1'"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备型号">
        <el-select v-model="searchForm.model" placeholder="请选择设备型号">
          <el-option :label="item.name" :value="item.code" v-for="(item) in deciveChidList" :key="item.code + '2'"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车场编号" >
        <el-input v-model="searchForm.comId" placeholder="请输入车场编号" clearable />
      </el-form-item>
      <el-form-item label="设备编号" >
        <el-input v-model="searchForm.nodeId" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <el-form-item label="设备状态" >
        <el-select v-model="searchForm.status" placeholder="请选择设备类型">
          <el-option :value="0" label="在线"></el-option>
          <el-option :value="1" label="离线"></el-option>
          <el-option :value="2" label="未激活"></el-option>
          <el-option :value="3" label="已禁用"></el-option> 
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="resetNew">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <div style="text-align:right;">
        <el-button type="primary" @click="doUpgradeBatch">批量升级</el-button>
      </div>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="nodeId"
        stripe
        height="60vh"
        default-expand-all
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="nikeName" label="设备名称"></el-table-column>
        <el-table-column prop="nodeId" label="设备编号" width="240"></el-table-column>
        <el-table-column prop="comId" label="车场编号"></el-table-column>
        <el-table-column prop="comName" label="车场名称"></el-table-column>
        <el-table-column prop="registerTime" label="注册时间" min-width="130px">
          <template slot-scope="{row}">{{$getTime(row.registerTime / 1000)}}</template>
        </el-table-column>
        <el-table-column prop="deviceType" label="设备类型">
          <template slot-scope="{row}">{{deciveData[row.deviceType]}}</template>
        </el-table-column>
        <el-table-column prop="hardVer" label="设备型号"></el-table-column>
        <el-table-column prop="status" label="设备状态" align="center">
          <template slot-scope="{row}">{{statusData[row.status]}}</template>    
        </el-table-column>
        <el-table-column prop="softVer" label="当前版本"></el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-tag type="success" effect="dark" style="margin-right: 12px;cursor: pointer" @click="doUpgradeOne(row)">升级</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>


  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import {deciveList} from '@/api/device/upFile'
import {upgradeBatch,upgradeOne} from '@/api/device/upgrade'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination},
  data () {
    return {
      deciveData:{},
      uploadUrl:'',
      fileForm:{
        "category": '',
        "model": '',
        "comId": '',
        "nodeId": ''
      },
      upFileUrl:'',
      upFileShow:false,
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        "category": '',
        "model": '',
        "comId": '',
        "nodeId": '',
        'status':null
      },
      deviceId:'',
      selectArr: [
        { label: "未处理", value: "10001" },
        { label: "已处理", value: "10002" },
        { label: "忽略", value: "10003" },
      ],
      level:{
        4001:'提示',
        4002:'警告',
        4003:'严重',
        4004:'紧急',
      },
      statusData:{
        0:'在线',
        1:'离线',
        2:'未激活', 
        3:'已禁用'
      },
      passData:{},
      deciveList:[],
      deciveChidList:[],
      multipleSelection:[]
    }
  },
  mounted(){
    this.getdeciveList()
  },
  methods: {
    doUpgradeBatch(){
      let url = ''
      if(this.multipleSelection.length){
        this.multipleSelection.map(item=>{
          url += `nodeIds=${item.nodeId}&`
        })
        this.$confirm('升级后是否重启设备?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          url += `autoReboot=1`
          this.upgradeBatchFun(url)
        }).catch(() => {
          url += `autoReboot=0`
          this.upgradeBatchFun(url)       
        });
      }else{
        this.$message({
          type: 'error',
          message: '请选择设备!'
        });
      }
    },
    handleSelectionChange(val){
      this.multipleSelection = val
    },
    //多个升级
    upgradeBatchFun(data){
      upgradeBatch(data).then(res=>{
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      })
    },
    //单个升级
    doUpgradeOne(row){
      let data = {
        autoReboot:'0',
        nodeId:row.nodeId
      }
      this.$confirm('升级后是否重启设备?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        data.autoReboot = '1'
        this.upgradeOneFun(data)
      }).catch(() => {
        this.upgradeOneFun(data)       
      });
    },
    upgradeOneFun(data){
      upgradeOne(data).then(res=>{
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      })
    },
    changedeciveChidList(val){
      if(this[val].category == ''){
        return false
      }
      this.deciveList.map(item=>{
        if(item.code == this[val].category){
          this.deviceId = item.id
        }
      })
      this[val].model = ''
      let data = {
        capacity: 200,
        model: this.deviceId,
        page: 1
      }
      deciveList(data).then(res=>{
        this.deciveChidList = res.records
      })
    },
    getdeciveList(){
      let data = {
        capacity: 200,
        model: 0,
        page: 1
      }
      deciveList(data).then(res=>{
        res.records.map(item=>{
          this.deciveData[item.code] = item.name
        })
        this.deciveList = res.records
        
      })
    },
    //重置
    resetNew(){
      this.searchForm = {
        "deviceCategory": '',
        "deviceModel": '',
        "fileName": '',
        "fileVersion": ''
      }
      this.search()
    },
  },
  created () {
    this.config({
      module: '菜单',
      api: '/device/upgrade',
      // sorts: [{
      //   property: 'start_time',
      //   direction: 'DESC'
      // }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
