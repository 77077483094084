
import request from '@/utils/request'

// 查询默认规则
export function fetchList (data) {
  return request.post('/system/alarm/queryDefaultRules',data)
}

// 使用默认规则
export function useDefaultRule (data) {
    return request.post('/system/alarm/useDefaultRule',data)
  }
  