<template>
  <TableLayout class="menu-layout" :permissions="['system:com:ysCameraInfo']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" label-width="100px" inline>
      <el-form-item label="车场名称" prop="companyName">
        <el-select v-model="searchForm" placeholder="请选择车场">
          <el-option :label="item.name" :value="item.id" v-for="(item,i) in parkingList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        default-expand-all
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="parkId" label="车场ID"></el-table-column>
        <el-table-column prop="passId" label="通道ID"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="100px">
          <template slot-scope="{row}">{{$getTime(row.createTime)}}</template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import { parkingList } from '@/api/parking/ysCamera'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination},
  data () {
    return {
      parkingList:[],
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm:null
    }
  },
  mounted(){
    this.getParkingListFuc()
  },
  methods: {
    // 时间搜索范围变化
    handleSearchTimeChange (value) {
      this.searchForm.createTimeStart = null
      this.searchForm.createTimeEnd = null
      if (value != null) {
        this.searchForm.createTimeStart = value[0] / 1000
        this.searchForm.createTimeEnd = value[1] / 1000
      }
      this.search()
    },
    getParkingListFuc(){
      parkingList()
        .then(records => {
          this.parkingList = records
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
        })
    }
  },
  created () {
    this.config({
      module: '菜单',
      api: '/parking/ysCamera',
    //   sorts: [{
    //     property: 'create_time',
    //     direction: 'DESC'
    //   }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
