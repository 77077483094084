import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/deviceCategory/query', data)
}

// 新增
export function add (data) {
  return request.post('/system/deviceCategory/add', data)
}

// 删除
export function del (data) {
  return request.post('/system/deviceCategory/delete?id=' + data, data)
}