<template>
  <div class="control-container">
    <div class="page-icon" @click="show3 = !show3">
      <i class="el-icon-caret-bottom" style="font-size: 30px;"></i>
    </div>
    <el-collapse-transition>
      <div class="page-box" v-show="show3">
        <el-pagination
          background 
          layout="prev, pager, next" 
          :total="total" 
          :page-size="16" 
          @current-change="changePage"
          @prev-click="changePage"
          @next-click="changePage"
          :current-page="page"/>
      </div>
    </el-collapse-transition>
    
    <el-row>
      <el-col :span="6" v-for="(item,i) in videoObj" :key="i">
        <div class="vedio-box" v-if="item.isPlaying && item.is">
          
          <div :id="item.DIVid" 
            @dblclick="toFullScreen(item.DIVid)"
            style="height:25vh;"
            v-loading="item.isLoading" 
            element-loading-text="视频加载中" 
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)" >
              <div class="control-pass-name">
                <div>车场名称：{{item.comName}}</div>
                <div>车场编号：{{item.comid}}</div>
                <div>通道名称：{{item.passname}}</div>
                <div>通道编号：{{item.passId}}</div>
              </div>
          </div>
        </div>

        <div v-show="!item.isPlaying" class="vedio-box" style="background:#102563;border:1px solid #DCDFE6;">
          <el-image style="width: 60px; height: 60px" :src="warnImage" class="warn-img"></el-image>
          <div class="warring-text" >视频加载失败.....</div>
          <div class="control-pass-name2" :id="`pass-name-${i}`">
            <div>车场名称：{{item.comName}}</div>
            <div>车场编号：{{item.comid}}</div>
            <div>通道名称：{{item.passname}}</div>
            <div>通道编号：{{item.passId}}</div>
          </div>
        </div>

        <!-- 空白填充 -->
        <div v-if="!item.is " class="vedio-box" style="background:#102563;border:1px solid #DCDFE6;">
          <i class="el-icon-video-camera-solid" style="font-size:40px;line-height:25vh;color:#ffffff;"></i>
        </div>
      </el-col>
    </el-row>
    <remote-js src="https://pv.sohu.com/cityjson?ie=utf-8"></remote-js>
  </div>
</template>



<script>
import Vue from 'vue'
import videoLoadWarn from '../../assets/images/videoLoadWarn.png';
import { getPassListByBindingUser,deviceInfo } from '@/api/control/control'
import { webRTCConfig } from "@/api/work/work";
import { getToken } from "@/utils/auth"; // get token from cookie
  export default {
    name: 'datacenterUnion',
    components: {
      'remote-js': {
          render(createElement) {
                return createElement('script', { attrs: { type: 'text/javascript', src: this.src }});
          },
          props: {
              src: { type: String, required: true },
            },
      },
    },
    data() {
      return {
        show3: false,
        warnImage:videoLoadWarn,
        isLoading:true,
        IP:'',
        pc:[],
        isPlaying:{},
        currentTime:null,
        total: 0,
        videoObj:[
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
          {isPlaying:true,is:false},
        ],
        sdpList:[],
        gap_time:null,
        beforeUnload_time:null,
        deData:[],
        ipUrl:'',
        websock:null,
        heartSend:null,
        wbStaus:false,
        iceServers:[],
        passList: {},
        page: 1
      }
    },
    created() {
      let url = window.location.href
      console.log(url)
      let str = url.split('?')[1]
      let page = str.split('=')[1]
      this.page = +page
    },
    destroyed(){
      this.deData.map((item,i)=>{
        this.pc[i].close();
        this.pc[i] = null;
      })
      let that = this;
      clearInterval(that.heartSend)
      this.heartSend = null;
      clearInterval(that.refreshToken)
      this.refreshToken = null;
      this.websock.close(); //离开路由之后断开websocket连接
    },
    mounted(){
      webRTCConfig().then(res=>{
        if(res.length){
          res.map(item=>{
            let obj1 = {urls: `stun:${item}`}
            let obj2 = {
              "url":`turn:${item}`, 
              "credential":"webrtc", 
              "username":"admin"
            }
            that.iceServers.push(obj1)
            that.iceServers.push(obj2)
          })
        }
        console.log('1111',that.iceServers)
      })
      window.addEventListener("beforeunload", function(e) {
        console.log("I want to cancel");
        // Cancel the event
        var video = document.getElementsByTagName("video");
        console.log(video);
        for(var i = 0; i <= video.length; i ++){
          video[i].pause()
        }
        e.preventDefault();
        
      });
      let that = this;
      // this.IP = localStorage.getItem('IP')
      this.getWebSocket()
      this.IP = returnCitySN['cip']
      //刷新token
      this.refreshToken = setInterval(()=>{
        console.log('刷新token')
        refreshToken().then(res=>{

        })
      },600000)
    },
    methods: {
      // 翻页
      changePage(page) {
        let routeUrl = this.$router.resolve({
          path: '/controlPage',
          query: { page }
        })
        window.open(routeUrl.href, '_blank')
      },
      websocketclose(e) {
        this.wbStaus = false;
        //关闭
        console.log("断开连接", e);
      },
      getWebSocket() {
        //初始化weosocket
        this.websock = new WebSocket(this.$websocket + getToken() + '/monitor')
        this.websock.onopen = this.websocketonopen;
        this.websock.onmessage = this.websocketonmessage;
        this.websock.onerror = this.websocketonerror;
        this.websock.onclose = this.websocketclose;
        this.wbStaus = true;
        
        //发送心跳
        this.heartSend = setInterval(()=>{
          this.websock.send('0x11')
        },30000)
      },
      websocketonopen(e) {
        //连接建立之后执行send方法发送数据
        this.unloadFn()
        console.log('链接成功')
      },
      websocketonerror(e) {
        let that = this;
        //连接建立失败重连
        clearInterval(that.heartSend)
        this.heartSend = null;
        this.wbStaus = false;
        this.getWebSocket();
      },
      websocketonmessage(res){
        let that = this;
        if(res.data != '0x12'){
          let resData = JSON.parse(res.data);
          console.log(resData)

          //获取设备sdp
          if(resData.service_name == "download_sdp"){
            if(resData.state == 1){
            }else{
              let index = 0
              for(var key in that.videoObj){
                if(that.videoObj[key].passId == resData.data.pass_id){
                  index = key
                  let item = Object.assign(that.videoObj[key],resData.data);
                  item.isPlaying = true;
                  that.videoObj[key]
                  that.getSdp(item)
                }
              }
            }
          }

          //获取candidate
          if(resData.service_name == "download_candidate"){
            // console.log('下发candidate',that.videoObj)
            if(resData.msg == "下发candidate"){
              
              for(var key in that.videoObj){
                console.log('下发candidate',that.videoObj[key].connect_id ,resData.data.connect_id)
                if(that.videoObj[key].connect_id == resData.data.connect_id){
                  that.videoObj[key].candidate = resData.data.candidate;
                  console.log('全景addIceCandidate',resData.data.candidate)
                }
              }
              
            }
          }
        }
      },
      getSdp(data){
        let that = this;
        let obj = {
          sn:data.sn,
          connectId:data.connectId
        }
        this.deData.push(obj)
        this.sdpList.push(data);
        localStorage.setItem('deData',JSON.stringify(this.deData))
        console.log(that.videoObj)
        setTimeout(()=>{
          let item = null;
          for(var key in that.videoObj){
            if(that.videoObj[key].passId == data.pass_id){
              item = that.videoObj[key];
              that.getVideoNew(data.sdp,item,key,data)
              // console.log('收到的sdp',data.sdp)
            }
          }
        },1500)
      },

      unloadFn() {
        let that = this;
        if(localStorage.getItem('deData')){
          let deData = localStorage.getItem('deData');
//           closeConnect(deData)
        }
        this.getPassListByBindingUserFun()
      },


      //查询通道
      async getPassListByBindingUserFun(){
        let data = {"page":1,"capacity":100,"model":{"deviceId":"","deviceType":"1004","nodeId":"","parkId":"","shortId":"","userId":""}}
        this.passList = await deviceInfo(data)
        console.log(this.passList)
        getPassListByBindingUser().then(res=>{
          let arr = res
          let allPassList = []
          arr.map(ite=>{
            let pass = ite
            pass.status = 1
            this.passList.records.map(it=>{
              if(it.passId == ite.id){
                pass.passId = it.passId
                pass.status = it.status
              }
            })
            allPassList.push(pass)
          })
          allPassList.sort((a, b) => {
            return a.status - b.status // 升序
          })
          this.total = allPassList.length
          allPassList.map((item,i)=>{
            if (i >= (this.page - 1) * 16 && i < this.page * 16) {
              item.isLoading = true;
              item.isPlaying = false;
              item.num = 0
              item.is = true;
              item.DIVid = 'video-wrap-' + i + Date.parse(new Date());
              item.currentTime = null;
              if(!item.status){
                let data = {
                  data:{
                    "passId":item.passId,
                    "type":3,
                    "ip":this.IP
                  },
                  "service_name": "get_sdp",
                }
                this.websock.send(JSON.stringify(data))
              }
              
              //获取通道sdp
              // this.querySdpFun(item,i)
              Vue.set(this.videoObj,i,item)
            }
          })
          console.log(this.allPassList)

        })
      },
      //单个通道重连
      linkCameraSingle(item){
        if(item.num > 2){
          return false
        }
        let data = {
          data:{
            "passId":item.passId,
            "type":3,
            "ip":this.IP
          },
          "service_name": "get_sdp",
        }
        this.websock.send(JSON.stringify(data))
      },
      //双击放大
      toFullScreen(id){
        let div = document.getElementById(id);
        if(div.style.width != '100vw'){
          div.style.position = "fixed";
          div.style.top = "0";
          div.style.left = "0";
          div.style.width = '100vw';
          div.style.height = '100vh';
          div.style.zIndex = '2001';
        }else{
          div.style.position = "relative";
          div.style.width = '100%';
          div.style.height = '100%';
          div.style.zIndex = '0';
        }
      },

      getVideoNew(res,item,i,resData){
        // console.log(res)
        let that = this;
        let servers = {
          iceServers: that.iceServers
        }
        that.pc[i] = new RTCPeerConnection(servers);
        let isWarn = true;
        that.pc[i].ontrack = function (event) {
          if(event.track.kind == 'video'){
            var el = document.createElement(event.track.kind);
            el.style.width = '100%';
            el.style.height = '100%';
            el.style.objectFit = 'fill';
            el.srcObject = event.streams[0];
            el.autoplay = true;
            el.poster = ''
            el.muted = true
            console.log(item, i)
            document.getElementById(item.DIVid).appendChild(el);
            
            
            if (el.nodeName === "VIDEO") {
              el.onloadstart=()=>{
                console.log('在视频开始加载时执行')
              }
              el.ondurationchange=()=>{
                console.log('当视频的时长发生变化时')
              }
              el.onloadedmetadata=()=>{
                console.log('在视频的元数据加载后')
              }
              el.onloadeddata=()=>{
                console.log('当前帧的数据加载完成且还没有足够的数据播放视频/音频')
              }
              el.onprogress=()=>{
                // console.log('视频正在下载')
              }
              el.oncanplay = () => {
                console.log('视频播放')
                isWarn = false;
                that.isLoading = false;
                let obj = that.videoObj[i]
                obj.isLoading = false
                Vue.set(that.videoObj,i,obj)
              };
            }   
          }
          
          
        };
        setTimeout(()=>{
          if(isWarn){
            let obj = item;
            document.getElementById(item.DIVid).innerHTML = ''
            obj.isPlaying = false;
            obj.num += 1
            Vue.set(that.videoObj,i,obj)
            that.linkCameraSingle(item)
          }
            
        },60000)
        let sdp = '';
        let candidate = '';
        //添加来自stun服务器的ICE
        that.pc[i].onicecandidate = function(event) {
          console.log('+++++++++++++ Remote ICE candidate:',event.candidate);
          onIceCandidate(that.pc[i], event);
        }
        that.pc[i].oniceconnectionstatechange = function(e) {
          onIceStateChange(that.pc[i], e);
        };

        function onIceStateChange(pc, event) {
          if (pc) {
            console.log('ICE state: ' + pc.iceConnectionState);
            console.log('ICE state change event: ', event);
          }
        }


        function onIceCandidate(pc, event) {
          console.log('获取本地candidate',event)
          if (event.candidate) {
            //ICE发送给相机
            let uploadcandidatedata = {
              candidate:event.candidate,
              connectId:resData.connect_id,
              sn:resData.sn
            }
            let data = {
              data:uploadcandidatedata,
              "service_name": "upload_candidate",
            }
            console.log('上传本地设备candidateo o o o   o o o o ','upload_candidate',uploadcandidatedata)
            that.websock.send(JSON.stringify(data))
            
          }
        }
        that.pc[i].createOffer().then((offer) => {
          offer.sdp = res;
			    offer.type = 'offer';
          that.pc[i].setRemoteDescription(offer,function(){
            that.pc[i].createAnswer().then(function( answer ) {
              that.pc[i].setLocalDescription( answer,function(){
                console.log('setLocalDescription',answer)

              })
              let uploadSpddata = {
                connectId:resData.connect_id,
                sn:resData.sn,
                sdp:answer.sdp + '',
                type:0,
                "ip":that.IP
              }
              let data = {
                data:uploadSpddata,
                "service_name": "upload_sdp",
              }
              that.websock.send(JSON.stringify(data))
              // console.log('我发出去的sdp',uploadSpddata.sdp)
              setTimeout(()=>{
                for(var key in that.videoObj){
                  if(that.videoObj[key].connect_id == resData.connect_id && that.videoObj[key].candidate){
                    console.log('candidate发出去了',that.videoObj[key],resData.connect_id,that.videoObj[key].candidate)
                    that.pc[i].addIceCandidate(new RTCIceCandidate(JSON.parse(that.videoObj[key].candidate)));
                  }
                }
                // that.pc[i].addIceCandidate(new RTCIceCandidate(resData.data.candidate));
              },500)
            })
            
          })
        })
        
        that.pc[i].getStats().then(function(stats) {
          console.log('-------------------------------',stats,stats.values())
        });
      },


  
      getVideoDuration(item,i) {
        
        var video = document.getElementsByTagName("video")[i];
        //  如果没有获取到视频元素
        if (!video) {
            return;
        }

        let that = this;

        video.addEventListener("timeupdate", () => {
          that.currentTime = getTime(video.currentTime);
        });

        var getTime = function (time) {
            let hour =
                Math.floor(time / 3600) < 10
                    ? "0" + Math.floor(time / 3600)
                    : Math.floor(time / 3600);
            let min =
                Math.floor((time % 3600) / 60) < 10
                    ? "0" + Math.floor((time % 3600) / 60)
                    : Math.floor((time % 3600) / 60);
            var sec =
                Math.floor(time % 60) < 10
                    ? "0" + Math.floor(time % 60)
                    : Math.floor(time % 60);
            return hour + ":" + min + ":" + sec;
        };
      },
    }
  }
</script>

<style lang="scss" scoped>
.control-container{
  width:100vw;
  height:100vh;
  overflow: hidden;
  // background-image: url("../../assets/images/bg.png");
  background-size: 100% 100%;
  background-position: center center;
  background-repeat:  no-repeat;
  background: #F2F6FC;
}
  .el-row{
    height:25vh;
  }
  .vedio-box{
    height:25vh;
    vertical-align:middle;
    border-radius: 6px;
    text-align:center;
    box-sizing: border-box;
  }
  .cover {
    bottom: 0px;
    height: 300px;
    position: absolute;
    width: 100%;
    z-index: 2;
    &:hover,
    &:focus,
    &:focus-within {
      .controls {
        display: flex;
        }
    }
  }
  .vedio-box{
    // box-shadow: 0px 1px 43px 0px rgba(15, 203, 235, 0.4) inset;
    // border: 1px solid rgba(15, 196, 229, 0.54);
    position:relative;
  } 
  .control-pass-name{
    position:absolute;
    left:10px;
    top:10px;
    text-align: left;
    color:#F56C6C;
    font-size:16px;
    font-weight: bolder;
    background: rgba(96, 98, 102, 0.4);
    padding: 6px 12px;
    border-radius: 2px;
    z-index: 100;
  }
    .control-pass-name2{
    position:absolute;
    left:10px;
    top:10px;
    text-align: left;
    color:#F56C6C;
    font-size:16px;
    font-weight: bolder;
    background: rgba(96, 98, 102, 0.4);
    padding: 6px 12px;
    border-radius: 2px;
    z-index: 100;
  }
  .comid{
    right:60px;
  }
  /deep/ .el-loading-mask{
    width:100%;
    height:100%;
  }
  /deep/ .el-loading-spinner{
    position: relative;
    top:16vh;
  }
  .vedio-box{
    
  }
  .warring-text{
    color:#ffffff;
    text-align: center;
    letter-spacing: 1px;
  }
  .warn-img{
    margin:0 auto;
    margin-top:9vh;
  }
  .page-icon {
    position: fixed;
    width: 40px;
    height: 30px;
    border-radius: 6px;
    z-index: 3000;
    font-size: 30px;
    text-align: center;
    background: rgba(255, 255, 255, 0.26);
    left: calc(50vw - 20px);
    cursor: pointer;
  }
  .page-box {
    position: fixed;
    width: 100vw;
    text-align: center;
    top: 70px;
    left: 0;
    z-index: 3000;
  }
</style>