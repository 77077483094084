<template>
  <div id="orderPage">

    <!-- 广告 -->
    <div class="box-card">
      <div style="text-align: center;height: 12rem;">
        <img fit="cover" id="adImg" :src="previewUrl" style="width:100%;height:100%">
      </div>
    </div>

    <!--订单卡片-->
    <div >
      <div class="box-card">
        <div slot="header" class="clearfix" style="margin-bottom:5%">
          <span style="color: #606266;"><strong>订单详情</strong></span>
          <div style="float: right; padding: 3px 0" type="text"><span  style="color: #67C23A;">常见问题？</span></div>
        </div>
        <div style="display: flex;">
          <div style="width:40%;height:24%;margin-left:10px;">
            <img fit="cover" src="" style="width:100%;height:100%" alt="暂无图片">
          </div>
          <div style="flex-grow: 1;text-align: left;box-sizing: border-box;padding-left:16px;">
            <div style="color:#606266;font-size: 18px;">车牌号码</div>
            <div id="carNumber" style="color:#67C23A;font-size:18px;font-weight: bold;line-height: 36px;">XXXXXXX</div>
          </div>
        </div>
        <div style="border-top:2px dashed #DCDFE6;margin-top:10px;padding-top:5px;">
          <div>
            <div class="text item">优惠减免&nbsp;&nbsp;&nbsp;
            <span id="ticketUse" style="color:#67C23A;line-height:24px; ">XXXXXXX</span>
          </div>
        </div>
        <div>
          <div class="text item">已停时长&nbsp;&nbsp;&nbsp;
            <span id="parkTime" style="line-height:24px;color:#909399;">XXXXXXX</span>
          </div>
        </div>
        <div>
          <div class="text item">停车位置&nbsp;&nbsp;&nbsp;
            <span id="parkName" style="line-height:24px;color:#909399;">XXXXXXX</span>
          </div>
        </div>
        
      </div>
            
    </div>
  </div>
    <!--步骤条-->
    <div>
      <el-steps :active="2"  :align-center="true">
        <el-step id="iTime" title="入场" description=""></el-step>
        <el-step id="cTime" title="停车中" description=""></el-step>
        <el-step id="oTime" title="离场"></el-step>
      </el-steps>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColumnDetail',
  props: {
    // 预览链接
    previewUrl: {
      type: String
    },
  },
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
.yunying-column-detail-popover {
  max-width: 80%;
}
</style>
<style scoped lang="scss">
  #orderPage{
    border-radius: 20px;
    width:360px;
    height:640px;
  }
  p { height: 100px; text-align: center;
    transition:height 0.5s, line-height 0.5s;
  }
  #totalShow{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 70%;
  }
  #gotoPay{
    position: fixed;
    bottom: 0px;
    right: 0px;
    width: 30%;
  }
  .el-step__description.is-finish {
    color: #303133;
  }
  .el-step__title.is-finish {
    color: #67C23A;
  }
  .el-step__title.is-process {
    color: gray;
    font-weight: 400;
    font-size: 14px;
  }
  .el-step__icon-inner {
    display: inline-block;
    user-select: none;
    text-align: center;
    font-weight: 300;
    line-height: 1;
    color: #303133;
  }
  .el-step__head.is-finish {
    color: #303133;
    border-color: #303133;
  }
  .el-step__title.is-finish {
    color: #67C23A;
    font-size: 14px;
  }
  .el-step__icon.is-text {
    border-radius: 50%;
    border: 1px solid;
    border-color: inherit;
  }
  .el-step.is-horizontal .el-step__line{
    top:14px;
    height:1px;
  }
  .el-step__line-inner{
    border-width:0.7px;
  }
  .el-step__icon.is-text {
    border-radius: 50%;
    border: 1px solid;
    border-color: inherit;
  }
  .el-step__icon {
    z-index: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 15px;
    height: 18px;
    font-size: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #FFF;
    -webkit-transition: .15s ease-out;
    transition: .15s ease-out;
  }
  .box-card{
    border:1px solid #F5F5F5;
    /* box-shadow:1px 1px 5px #ccc ; */
    font-size: 15px;
  }
  .clearfix{
    padding: 15px;
    border-bottom:2px solid #F2F4F8;
  }
  .text{
    padding-left: 15px;
    padding-bottom: 5px;
    padding-right: 15px;
    color:#606266;
  }
  .status{
    display: flex;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
  }
  .status>div>div:first-child{
    text-align: center;
    width:20px;
    height: 20px;
    line-height: 20px;
    border-radius: 20px;
    margin-left: 15px;
    color:#fff;
  }
  .status>div>div{
     margin-top: 5%;
  }
  .status>div>div:nth-child(2){
    color:#75D172;
    font-size: 12px;
  }
  .status>div>div:nth-child(3){
    color:black;
    font-size: 12px;
  }
  .status>div>div:nth-child(5){
    color:#919192;
    font-size: 10px;
  }
  .status>div>div>span{
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #5DD278;
  }
  .status>div{
    width: 16.5%;
    text-align: center;
  }
  .button{
    width: 100%; 
    font-size: 14px; 
  }
  .button>div:first-child{
    width: 69%;
    padding: 10px;
    text-align: center;
    border-top: 1px solid #ccc;
    color:#606266;
    background-color: #fff;
  }
  .button>div:nth-child(2){
    width: 30%;
    padding: 10px;
    color:#FFF;
    text-align: center;
    border-top: 1px solid #ccc;
    background-color: #4CC348;
  }
  .light{
    width:25% !important;
    height: 2px;
    margin-top: 12px;
    background-color: #33D37E;
  }
  .theplay{
    width:25% !important;
    height: 1px;
    margin-top: 12px;
    background-color: #C7CBD2;
  }
</style>
