<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {

    /**
     * 设置横坐标数据
     */
    setXData() {
      var hours = new Date().getHours()+1;
      var arr = new Array();
      for(var i = 0; i<=hours; i++){
        arr.push(i)
      }
      this.xData = arr
    },

    initChart() {
      this.setXData();
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    },
    setOptions({ expectedData} = {}) {

      var xDataArr = expectedData.map((value,index,array) => {
        return index+'点--'+(index+1)+'点'
      });

      this.chart.setOption(
//          {
//        title: {
//          text: '开闸数据统计',
//          x: 'center',
//        },
//        xAxis: {
//          type: 'category',
//          data: this.xData,
//          boundaryGap: false,
//          axisTick: {
//            show: false
//          },
//        },
//        grid: {
//          left: 10,
//          right: 10,
//          bottom: 20,
//          top: 30,
//          containLabel: true
//        },
//        tooltip: {
//          trigger: 'axis',
//          axisPointer: {
//            type: 'cross'
//          },
//          padding: [5, 10]
//        },
//        yAxis: {
//          axisTick: {
//            show: false
//          },
//          type: 'value'
//        },
//        legend: {
//          data: ['操作次数'],
//          x: 'right'
//        },
//        series: [{
//          name: '操作次数', itemStyle: {
//            normal: {
//              color: '#FF005A',
//              lineStyle: {
//                color: '#FF005A',
//                width: 2
//              }
//            }
//          },
//          smooth: true,
//          type: 'bar',
//          data: expectedData,
//          animationDuration: 2800,
//          animationEasing: 'cubicInOut'
//        },
//       ]
//      }

        {
          title: {
            text: '开闸数据统计',
            x: 'center',
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            padding: [5, 10]
          },
          legend: {
          data: ['操作次数'],
          x: 'right'
           },
          xAxis: {
            type: 'category',
            data: xDataArr,
          },
          yAxis: {
            type: 'value'
          },
          series: [{

          name: '操作次数', itemStyle: {
            normal: {
              color: '#00CED1',
              lineStyle: {
                color: '#00CED1',
                width: 2
              }
            }
          },
            data: expectedData,
            type: 'bar'
          }]
        }
      )
    }
  }
}
</script>
