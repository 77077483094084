
import request from '@/utils/request'

// 查询充值记录
export function fetchList (data) {
  return request.post('/system/addValueAdded/getRechargeRecord',data)
}

// 获取服务详情
export function getInfo () {
    return request.get('/system/addValueAdded/getInfo')
  }
  
// 充值
export function doRecharge (data) {
    return request.post('/system/addValueAdded/recharge',data)
  }
// 修正
export function update (value) {
  return request.post('/system/addValueAdded/update?value=' + value,{value:value})
}


// 获取运营商服务详情
export function getInfoByTanant (tenantId) {
  return request.get('/system/addValueAdded/getInfoByTenant?tenantId=' + tenantId)
}

//查询所有运营商列表
export function queryAllTtenant (data) {
  return request.post('system/tenant/queryAll',data)
}

//修改运营商增值服务
export function updateInfoByTenant (data) {
  return request.post('/system/addValueAdded/updateInfoByTenant',data)
}