var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TableLayout',{attrs:{"permissions":['system:position:query']},scopedSlots:_vm._u([{key:"table-wrap",fn:function(){return [_c('ul',{directives:[{name:"permissions",rawName:"v-permissions",value:(['system:position:create', 'system:position:delete']),expression:"['system:position:create', 'system:position:delete']"}],staticClass:"toolbar"},[_c('li',[_c('el-button',{directives:[{name:"permissions",rawName:"v-permissions",value:(['system:position:create']),expression:"['system:position:create']"}],attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.$refs.operaPositionWindow.open('新建岗位')}}},[_vm._v("新建")])],1),_c('li',[_c('el-button',{directives:[{name:"permissions",rawName:"v-permissions",value:(['system:position:delete']),expression:"['system:position:delete']"}],attrs:{"icon":"el-icon-delete"},on:{"click":_vm.deleteByIdInBatch}},[_vm._v("删除")])],1)]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isWorking.search),expression:"isWorking.search"}],attrs:{"data":_vm.tableData.list,"tree-props":{children: 'children', hasChildren: 'hasChildren'},"row-key":"id","stripe":"","height":"60vh","default-expand-all":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55","fixed":"left"}}),_c('el-table-column',{attrs:{"prop":"name","label":"岗位名称","fixed":"left","min-width":"200px"}}),_c('el-table-column',{attrs:{"prop":"code","label":"岗位编码","fixed":"left","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"userCount","label":"岗位人数","min-width":"100px"}}),_c('el-table-column',{attrs:{"prop":"createUser","label":"创建人","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.createUserInfo == null ? '' : row.createUserInfo.username))]}}])}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","min-width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.$getTime(row.createTime)))]}}])}),_c('el-table-column',{attrs:{"prop":"updateUser","label":"更新人","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.updateUserInfo == null ? '' : row.updateUserInfo.username))]}}])}),_c('el-table-column',{attrs:{"prop":"updateTime","label":"更新时间","min-width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.$getTime(row.updateTime)))]}}])}),(_vm.containPermissions(['system:position:update', 'system:position:query', 'system:position:delete']))?_c('el-table-column',{attrs:{"label":"操作","min-width":"310","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{directives:[{name:"permissions",rawName:"v-permissions",value:(['system:position:update']),expression:"['system:position:update']"}],attrs:{"type":"text","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$refs.operaPositionWindow.open('编辑岗位', row)}}},[_vm._v("编辑")]),_c('el-button',{directives:[{name:"permissions",rawName:"v-permissions",value:(['system:position:update']),expression:"['system:position:update']"}],attrs:{"type":"text","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$refs.operaPositionWindow.open('新增下级岗位', null, row)}}},[_vm._v("新增下级岗位")]),_c('el-button',{directives:[{name:"permissions",rawName:"v-permissions",value:(['system:position:queryUsers']),expression:"['system:position:queryUsers']"}],attrs:{"type":"text","icon":"el-icon-user-solid"},on:{"click":function($event){return _vm.$refs.positionUserWindow.open(row.id, row.name)}}},[_vm._v("查看人员")]),_c('el-button',{directives:[{name:"permissions",rawName:"v-permissions",value:(['system:position:delete']),expression:"['system:position:delete']"}],attrs:{"type":"text","icon":"el-icon-delete"},on:{"click":function($event){return _vm.deleteById(row)}}},[_vm._v("删除")])]}}],null,false,3059423194)}):_vm._e()],1)]},proxy:true}])},[_c('OperaPositionWindow',{ref:"operaPositionWindow",on:{"success":_vm.handlePageChange}}),_c('PositionUserWindow',{ref:"positionUserWindow"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }