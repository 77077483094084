import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/tenant/query', data)
}

// 新建
export function create (data) {
  return request.post('/system/tenant/add', data)
}

// 修改
export function updateById (data) {
  return request.post('/system/tenant/edit', data)
}

// 修改状态
export function updateStatus (data) {
  return request.post('/system/tenant/updateStatus', data)
}

// 删除
export function deleteById (id) {
  return request.post(`/system/tenant/delete/?id=${id}`)
}

// 绑定/解除 车场与租户的关系
export function bindTenant (data) {
  return request.post(`/receive/com/bindTenant`, data)
}

// 重置密码
export function resetPwdTenant (data) {
  return request.post(`/system/user/resetPwd`, data)
}
