<template>
  <!-- 新建/修改 -->
  <GlobalWindow
    title="绑定车场"
    :visible.sync="visible"
    :confirm-working="isWorking"
    :width="size"
    @confirm="confirm"
  >
    <TableLayout class="menu-layout" :withBreadcrumb="false">
      <!-- 搜索表单 -->
      <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
        <el-form-item label="车场编号" prop="comId">
          <el-input v-model="searchForm.comId" v-trim placeholder="请输入车场编号" @keypress.enter.native="search"/>
        </el-form-item>
        <el-form-item label="车场名称" prop="companyName">
          <el-input v-model="searchForm.companyName" v-trim placeholder="请输入车场名称" />
          
        </el-form-item>
        <el-form-item label="车场类型">
          <el-select v-model="searchForm.sourceType" placeholder="请选择车场类型">
            <el-option label="网络相机车场" value="1"></el-option>
            <el-option label="第三方车场软件车场" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定状态">
          <el-select v-model="searchForm.isBinding" placeholder="请选择绑定状态" clearable>
            <el-option label="已绑定" :value="true"></el-option>
            <el-option label="未绑定" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <section>
          <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        </section>
      </el-form>
      <!-- 表格和分页 -->
      <template v-slot:table-wrap>
        <el-table
          ref="table"
          :data="tableData.list"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          row-key="id"
          stripe
          
          default-expand-all
        >
          <el-table-column prop="id" label="车场ID"></el-table-column>
          <el-table-column prop="companyName" label="车场名称" min-width="200px"></el-table-column>
          <el-table-column prop="parkingType" label="车场类型" min-width="100px">
            <template slot-scope="{row}">{{row.sourceType == '1' ? '网络相机车场' : '第三方软件车场'}}</template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="100"
            fixed="right"
          >
            <template slot-scope="{row}">
              <el-button type="primary" v-if="bindString.indexOf(row.id) == -1" @click="selectParkFun(row.id)">绑定</el-button>
              <el-button type="danger" v-else @click="delPark(row.id)">解绑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        ></pagination>
      </template>
    </TableLayout>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import { tenantManagerComList,bindTenant,unBindTenant,checkBindingTenant } from '@/api/parking/parking';
import { fetchList } from '@/api/parking/parking';
export default {
  name: 'OperaUserWindow',
  extends: BaseOpera,
  components: { GlobalWindow,TableLayout, Pagination },
  data () {
    return {
      size:'70vw',
      // 表单数据
      searchForm: {
        "comId": null,
        "companyName": "",
        "createTimeBegin": "",
        "createTimeEnd": "",
        "isBinding": null,
        "mobile": null,
        "sourceType": null,
        "tenantId": null
      },
      parkTagListDel:[],
      parkTagListAdd:[],
      fetchAllList:[],
      parkTagList:[],
      allParkList:[],
      selectPark:'',
      list:[],
      tenantId:null,
      isWorking:false,
      userObj:{},
      // 表格数据
      tableData: {
        // 已选中的数据
        selectedRows: [],
        // 排序的字段
        sorts: [],
        // 当前页数据
        list: [],
        // 分页
        pagination: {
          pageIndex: 1,
          pageSize: 10,
          total: 0
        }
      },
      bindString:''
    }
  },
  methods: {
    /**
     * @title 窗口标题
     * @target 编辑的用户对象
     */
    open (item) {
      this.tableData.list = []
      this.searchForm = {
        "comId": null,
        "companyName": "",
        "createTimeBegin": "",
        "createTimeEnd": "",
        "isBinding": null,
        "mobile": null,
        "sourceType": null,
        "tenantId": null
      }
      this.userObj = item;
      this.tenantId = item.managerUser.tenantId;
      let that = this;
      this.search()
      this.tenantManagerComListFun()
      
      
      this.visible = true;
    },
    //   获取用户绑定车场
    tenantManagerComListFun(){
      
      tenantManagerComList(this.tenantId).then(res=>{
        if(res.length){
          let ids = []
          res.map(item=>{
            item.isBind = true;
            this.parkTagList.push(item)
            ids.push(item.id)
          })
          this.bindString = ids.join(',')
        }else{
          this.bindString = ''
        }
        
      })
    },
    //获取所有车场
    search(){
      if(this.searchForm.isBinding === null || this.searchForm.isBinding === ""){
        this.searchForm.tenantId = ''
      }
      if(this.searchForm.isBinding === true || this.searchForm.isBinding === false ){
        this.searchForm.tenantId = this.tenantId
      }
      this.handlePageChange(1)
    },
    // 每页显示数量变更处理
    handleSizeChange (pageSize) {
      this.tableData.pagination.pageSize = pageSize
      this.search()
    },
    // 页码变更处理
    handlePageChange (pageIndex) {
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm,
        sorts: this.tableData.sorts
      })
        .then(data => {
         if(data !== null){
            this.tableData.list = []
            data.records.map(item=>{
              
              this.tableData.list.push(item)
            })
            // this.tableData.list = data.records
            this.tableData.pagination.total = data.total
          }
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    //筛选下拉框内容
    setParkList(){
      this.allParkList = this.list.filter(v=>{
        return this.parkTagList.every(e => e.id != v.id)
      })
    },

    //选中车场后
    selectParkFun(park){
      checkBindingTenant(park).then(res=>{
        if(!res.isBinding){
          let data = {
            comId:park,
            id:this.userObj['id'],
            tenantId:this.tenantId
          }
          bindTenant(data).then(res=>{
            this.search()
            this.tenantManagerComListFun()
            this.$tip.apiSuccess('绑定成功');
          }).catch(e=>{
            this.$tip.apiFailed('绑定失败')
          })
        }else{
          this.$message.error('该车场存在绑定关系不能重复绑定');
        }
      })
      
      
    },
    confirm () {
      this.parkTagListDel = []
      this.parkTagListAdd = []
      this.fetchAllList = []
      this.parkTagList = []
      this.allParkList = []
      this.selectPark = ''
      this.list = []
      this.userId = null
      this.isWorking = false
      this.visible = false;
    },
    //移除车场
    delPark(park){
      let data = {
        comId:park,
        id:this.userObj['id'],
        tenantId:this.tenantId
      }
      unBindTenant(data).then(res=>{
        this.search()
        this.tenantManagerComListFun()
        this.$tip.apiSuccess('解绑成功');
      }).catch(e=>{
        this.$tip.apiFailed('解绑失败')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.global-window {
  /deep/ .el-date-editor {
    width: 100%;
  }
  // 表单头像处理
  /deep/ .form-item-avatar {
    .el-radio.is-bordered {
      height: auto;
      padding: 6px;
      margin: 0 10px 0 0;
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding: 0;
        width: 48px;
        display: block;
        img {
          width: 100%;
        }
      }
    }
  }
}
.park-tag-box{
  padding:10px 0;
}
.park-tag-box /deep/ .el-tag{
  margin:10px;
}
</style>
