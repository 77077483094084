<template>
  <TableLayout class="menu-layout">
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <div v-if="type == '1'">
        <div style="text-align:right;">
          <el-button type="primary" @click="addType('')">新增大类</el-button>
        </div>
        <el-table
          ref="table"
          v-loading="isWorking.search"
          :data="tableData.list"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          row-key="id"
          stripe
          height="60vh"
          default-expand-all
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="name" label="类型名称"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" min-width="130px">
            <template slot-scope="{row}">{{$getTime(row.createTime)}}</template>
          </el-table-column>
          <el-table-column label="操作" min-width="140" fixed="right">
            <template slot-scope="{row}">
              <el-tag type="success" effect="dark" @click="showDetail(row)" style="margin-right: 12px;">详情</el-tag>
              <el-tag type="danger" effect="dark" @click="del(row.id)">删除</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        ></pagination>
      </div>
      <deviceDetail v-if="type == '0'" :detailDetail="detailDetail" @addType="addType" @toBack="toBack"></deviceDetail>
    </template>

    <el-dialog title="新增大类" :visible.sync="dialogFormVisible" width="30vw">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="设备code" prop="code">
          <el-input v-model="form.code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import {fetchList, add, del} from '@/api/device/type'
import deviceDetail from '@/components/device/deviceDetail'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination, deviceDetail},
  data () {
    return {
      detailDetail:{},
      rules:{
        name:[
          { required: true, message: '请输入设备名称', trigger: 'blur' },
        ],
        code:[
          { required: true, message: '请输入设备code', trigger: 'blur' },
        ]
      },
      form:{
        code:'',
        name:'',
        pid:''
      },
      dialogFormVisible:false,
      type:'1',
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: 0,
      selectArr: [
        { label: "未处理", value: "10001" },
        { label: "已处理", value: "10002" },
        { label: "忽略", value: "10003" },
      ],
      level:{
        4001:'提示',
        4002:'警告',
        4003:'严重',
        4004:'紧急',
      },
      passData:{}
    }
  },
  mounted(){

  },
  methods: {
    toBack(){
      this.type = '1'
    },
    showDetail(row){
      this.detailDetail = row
      this.type = '0'
    },
    del(id){
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del(id).then(res=>{
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.search()
        })
        
      })
      
    },
    addType(pid){
      this.dialogFormVisible = true
      this.form.pid = pid
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          add(this.form).then(res=>{
            this.$message({
              message: '新增成功',
              type: 'success'
            });
          })
          this.dialogFormVisible = false
          this.search()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    openPrompt(item,status){
      this.$prompt('请输入备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.handleAlarm(item,status,value)
      }).catch(() => {
               
      });
    },
    
  },
  created () {
    this.config({
      module: '菜单',
      api: '/device/type',
      // sorts: [{
      //   property: 'start_time',
      //   direction: 'DESC'
      // }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
/deep/ .el-tag{
  cursor: pointer;
}
</style>
