
import request from '@/utils/request'

// 更新省份广告类型
export function updateProvinceType (data) {
  return request.post('/system/orderAd/updateProvinceType',data)
}
// 查询所有省份广告类型
export function provinceTypeList (data) {
  return request.get('/system/orderAd/provinceTypeList',data)
}

// 查询所有广告类型
export function AdTypeList () {
  return request.get('/system/orderAd/AdTypeList')
}