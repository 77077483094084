<template>
  <TableLayout class="menu-layout" :permissions="['system:ad:queryAllAd']">
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="车场编号">
        <el-input v-model="searchForm.comId" v-trim placeholder="请输入车场编号" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="审核状态">
        <el-select v-model="searchForm.checkState" placeholder="请选择审核状态" filterable>
          <el-option v-for="(item , i) in CheckAdStateList" :key="i" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        :default-expand-all="false"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="adName" label="广告名称"></el-table-column>
        <el-table-column prop="adPath" label="广告路径" min-width="160"></el-table-column>
        <el-table-column prop="product" label="创建时间">
          <template slot-scope="{row}">{{$getTime(row.createTime / 1000)}}</template>
        </el-table-column>
        <el-table-column prop="parkId" label="所属车场"></el-table-column>
        <el-table-column prop="product" label="广告类型">
          <template slot-scope="{row}">{{row.adType == 0 ? '图片' : '视频'}}</template>
        </el-table-column>
        <el-table-column prop="product" label="审核状态">
          <template slot-scope="{row}">
            <!-- {{CheckAdStateEnum[row.checkState]}} -->
            <el-tag v-show="row.checkState == 4001" type="info">已上传</el-tag>
            <el-tag v-show="row.checkState == 4002" type="success">审核通过</el-tag>
            <el-tag v-show="row.checkState == 4003" >已下发</el-tag>
            <el-tag v-show="row.checkState == 4004" type="danger">审核不通过</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          v-if="containPermissions(['system:ad:checkAd'])"
          label="操作"
          min-width="140"
          fixed="right"
        >
          <template slot-scope="{row}">
            <!-- <el-button @click="openPrompt(row,'10002')" type="primary">预览</el-button> -->
            <el-button @click="openPrompt(row,'10002')" type="primary" v-permissions="['system:ad:checkAd']">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <el-dialog
      title="广告审核"
      :visible.sync="dialogVisible"
      width="50%">
      <div v-if="adType == 0" style="text-align:center;">
        <el-image 
          style="width: 100px; height: 100px"
          :src="url" 
          :preview-src-list="srcList">
        </el-image>
      </div>
      <div v-show="adType == 1" style="text-align:center;">
        <video width="320" height="240" controls :src="url" id="adVideo">
          <!-- <source :src="url" type="video/mp4"> -->
        </video>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" @click="auditAd(0)">不 通 过</el-button>
        <el-button type="primary" @click="auditAd(1)">通 过</el-button>
      </span>
    </el-dialog>

  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import {getAuthCom,preview,checkNotPass,checkPass} from '@/api/ad/deviceManager'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination},
  data () {
    return {
      adType:0,
      srcList:[],
      url:'',
      dialogVisible:false,
      value:false,
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        comId:'',
        checkState:4001
      },
      statusList:[],
      statusData:{},
      productList:[],
      productData:{},
      triggerSourceList:[],
      triggerSourceData:{},
      alarmTypeData:{},
      unionUserData:{},
      getAuthComData:{},
      CheckAdStateEnum:{
        4001:'已上传',
        4002:'审核通过',
        4003:'已下发',
        4004:'审核不通过',
      },
      CheckAdStateList:[
        {value:4001,label:'已上传'},
        {value:4002,label:'审核通过'},
        {value:4003,label:'已下发'},
        {value:4004,label:'审核不通过'},
      ],
      adData:{}
    }
  },
  mounted(){
    this.getAuthComFun()
  },
  methods: {
    //重置
    reset(){
      this.searchForm = {
        comId:'',
        checkState:4001
      }
    },
    //获取能操作广告管理的车场集合
    getAuthComFun(){
      getAuthCom().then(res=>{
        
      })
    },
    //广告预览
    openPrompt(data){
      this.adType = data.adType;
      this.adData = data;
      this.url = '';
      this.srcList=[];
      this.dialogVisible = true;
      preview(data.adPath).then(res=>{
        this.url = res;
        this.srcList.push(res)
      })
    },
    //广告审核
    auditAd(type){
      let data = {
        adId:this.adData.id,
        comId:this.adData.parkId,
      }
      if(!type){
        checkNotPass(data).then(res=>{
          this.$tip.apiSuccess(res)
          this.dialogVisible = false;
        }).catch(err => {
          this.$message.error(err);
        });
      }else{
        checkPass(data).then(res=>{
          this.$tip.apiSuccess(res)
          this.dialogVisible = false;
        }).catch(err => {
          this.search()
          this.dialogVisible = false;
          this.$message.error(err);
        });
      }
      this.getAuthComFun()
    },
  },
  created () {
    this.config({
      module: '菜单',
      api: '/ad/deviceManager',
      // sorts: [{
      //   property: 'start_time',
      //   direction: 'DESC'
      // }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
