<template>
  <TableLayout class="menu-layout" :permissions="['system:com:deviceInfo']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="车场编号" prop="parkId">
        <el-input v-model="searchForm.parkId" v-trim placeholder="请输入车场编号" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="设备编号" prop="nodeId">
        <el-input v-model="searchForm.nodeId" v-trim placeholder="请输入设备编号" @keypress.enter.native="search"/>
      </el-form-item>
<!--       <el-form-item label="APP用户编号" prop="userId">
        <el-input v-model="searchForm.userId" v-trim placeholder="请输入用户编号" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="P2P编号" prop="shortId">
        <el-input v-model="searchForm.shortId" v-trim placeholder="请输入P2P编号" @keypress.enter.native="search"/>
      </el-form-item> -->
      <el-form-item label="车场类型">
        <el-select v-model="searchForm.sourceType" placeholder="请选择车场类型">
          <el-option label="网络相机车场" value="1"></el-option>
          <el-option label="第三方车场软件车场" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备类型">
        <el-select v-model="searchForm.deviceType" placeholder="设备类型" clearable>
          <el-option v-for="item in devicetypeOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
      </el-form-item>
      <el-form-item label="设备状态" >
        <el-select v-model="searchForm.status" placeholder="设备状态" clearable>
          <el-option v-for="item in deviceStatusOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="resetNew">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        default-expand-all
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="nikeName" label="设备名称" width="120"></el-table-column>
        <el-table-column prop="nodeId" label="设备编号" min-width="160"></el-table-column>
        <!-- <el-table-column prop="passName" label="所属通道"></el-table-column> -->
        <el-table-column prop="comId" label="车场编号"></el-table-column>
        <el-table-column prop="comName" min-width="140" label="车场名称"></el-table-column>
        <el-table-column prop="passId" label="通道编号"></el-table-column>
        <el-table-column prop="passName" label="通道名称"></el-table-column>
        <!-- <el-table-column prop="userId" label="APP用户编号" min-width="130"></el-table-column>
        <el-table-column prop="shortId" label="P2P编号" min-width="100"></el-table-column> -->
        <el-table-column prop="deviceType" label="设备类型">
          <template slot-scope="{row}">{{devicetypeObj[row.deviceType]}}</template>
        </el-table-column>
        <el-table-column prop="status" label="设备状态" >
          <template slot-scope="{row}">
            <div :style="{color:statucColor[row.status]}">{{deviceStatusObj[row.status]}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="deviceType" label="子设备管理" fixed="right">
          <template slot-scope="{row}">
            <el-button type="text" size="small" @click="openSudeviceinfo(row)" :disabled="row.parentNodeId != null">查询</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" :permissions="['system:device:frpc']">
          <template slot-scope="{row}">
            <el-button type="success" size="small" @click="openfrpc(row)" :disabled="row.deviceType != 4001">开启设备frpc</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
      
    </template>

    <el-dialog title="子设备" :visible.sync="dialogTableVisible" :destroy-on-close="true">
      <SubDevice :dialogTableVisible="dialogTableVisible" :deviceData="deviceData" @closedialogTableVisible="closedialogTableVisible" @search="search"/> 
    </el-dialog>

    <el-dialog title="设备frpc" :visible.sync="dialogFormVisible" :destroy-on-close="true">
      
      <div class="frpc-body">
        <div style="font-size:18px;width:100px;">telnetAddr:</div>
        <div style="font-size:18px;">{{telnetAddr}}</div>
      </div>
      <div class="frpc-body">
        <div style="font-size:18px;width:100px;">vncAddr:</div>
        <div style="font-size:18px;">{{vncAddr}}</div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import SubDevice from '@/components/parking/SubDevice'
import { sendAdByCom, openEnableFRpc, deviceCategoryGetEnum } from '@/api/parking/deviceInfo'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination, SubDevice},
  data () {
    return {
      telnetAddr:'',
      vncAddr:'',
      dialogFormVisible:false,
      deviceData:{},
      dialogTableVisible:false,
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        deviceId:'',
        deviceType:'',
        nodeId:'',
        parkId:'',
        shortId:'',
        status:'',
        userId:'',
        sourceType:''
      },
      devicetypeObj:{},
      devicetypeOptions: [],
      deviceStatusObj:{
        0 : '在线',
        1 : '离线',
        2 : '未激活',
        3 : '禁用',
      },
      statucColor:{
        0 : '#67C23A',
        1 : '#909399',
        2 : '#E6A23C',
        3 : '#F56C6C',
      },
      deviceStatusOptions: [
        { value: 0, label: '在线' },
        { value: 1, label: '离线' },
        { value: 2, label: '未激活' },
        { value: 3, label: '禁用' }
      ],

    }
  },
  mounted() {
    this.deviceCategoryGetEnumFun()
  },
  methods: {
    deviceCategoryGetEnumFun() {
      deviceCategoryGetEnum().then(res=>{
        this.devicetypeOptions = res
        res.map(item => {
          this.devicetypeObj[item.value] = item.label
        })
      }).catch(e=>{
        this.$message.error(e);
      })
    },
    openfrpc(row) {
      openEnableFRpc(row.nodeId).then(res=>{
        this.telnetAddr = res.telnetAddr
        this.vncAddr = res.vncAddr
        this.dialogFormVisible = true
      }).catch(e=>{
        this.$message.error(e);
      })
    },
    openSudeviceinfo(row){
      this.dialogTableVisible = true
      this.deviceData = row
    },
    closedialogTableVisible(){
      this.dialogTableVisible = false
    },
    // 时间搜索范围变化
    handleSearchTimeChange (value) {
      this.searchForm.createTimeStart = null
      this.searchForm.createTimeEnd = null
      if (value != null) {
        this.searchForm.createTimeStart = value[0] / 1000
        this.searchForm.createTimeEnd = value[1] / 1000
      }
      this.search()
    },
    //下发广告
    sendAdByComFun(data){
      sendAdByCom(data.comId).then(res=>{
        this.$tip.apiSuccess(res)
      }).catch(e=>{
        this.$message.error(e);
      })
    },
    //重置
    resetNew(){
      this.searchForm = {
        deviceId:'',
        deviceType:'',
        nodeId:'',
        parkId:'',
        shortId:'',
        status:'',
        userId:'',
      }
      this.search()
    },
  },
  created () {
    this.config({
      module: '菜单',
      api: '/parking/deviceInfo',
    //   sorts: [{
    //     property: 'create_time',
    //     direction: 'DESC'
    //   }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
.frpc-body {
  display: flex;
  font-size:18px;

}
.frpc-body>div{
  margin-right:20px;
  margin-top:20px;
}
</style>
