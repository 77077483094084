<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
    top="6vh"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <!-- <el-form-item label="规则名称">
        <el-input v-model="ruleName" readonly/>
      </el-form-item> -->
      <div class="form-title">告警对象</div>
      <el-form-item label="产品" required>
        <el-select v-model="form.product" placeholder="请选择产品" :disabled="isEdit">
          <el-option :label="item.label" :value="item.value" v-for="(item,i) in productList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="触发源" required>
        <el-select v-model="form.triggerSource" placeholder="请选择触发源" :disabled="isEdit">
          <el-option :label="item.value_name" :value="item.value_no" v-for="(item,i) in triggerSourceList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="告警类型" required>
        <el-select v-model="form.alarmType" placeholder="请选择类型" :disabled="isEdit">
          <el-option :label="item.value_name" :value="item.value_no" v-for="(item,i) in alarmTypeList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <div class="form-title">触发方式</div>
      <el-form-item>
        <el-radio-group v-model="form.triggerWay" :disabled="isEdit">
          <el-radio :label="3001" :value="3001">立即触发</el-radio>
          <el-radio :label="3002" :value="3002" disabled>累计触发</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="form-title">触发条件</div>
      <!-- <el-form-item label="级别">
        <el-select v-model="level" placeholder="请选择级别">
          <el-option :label="item.value_name" :value="item.value_no" v-for="(item,i) in levelList" :key="i"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="等待时间">
        <el-select v-model="cycle" placeholder="等待时间">
          <el-option :label="item.value_name" :value="item.value_no" v-for="(item,i) in cycleList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <!-- <div class="elTag">
        <el-tag type="danger" size="mini" effect="dark">级别指：告警的紧急程度，周期指：{{[2005,2007].indexOf(form.alarmType)!==-1?'设备离线到上线的时间':'周期时间内设备离线的频率'}}</el-tag>
      </div> -->
      <el-form-item label="条件" v-if="form.triggerWay == 3002">
        <el-select v-model="condition" placeholder="请选择条件">
          <el-option :label="item.value_name" :value="item.value_no" v-for="(item,i) in conditionList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数值" v-if="form.triggerWay == 3002">
        <el-input-number v-model="value" :min="1"></el-input-number>
      </el-form-item>
      <el-form-item label="单位" v-if="form.triggerWay == 3002">
        <el-select v-model="unit" placeholder="请选择单位">
          <el-option :label="item.value_name" :value="item.value_no" v-for="(item,i) in unitList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <div class="form-title">消息通知</div>
      <el-form-item label="接收者">
        <el-select v-model="unionUser" placeholder="请选择接收者" multiple filterable>
          <el-option :label="item.value_name" :value="item.value_no" v-for="(item,i) in unionUserList" :key="i" ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="通知方式">
        <el-checkbox-group v-model="noticeWay">
          <el-checkbox v-for="(item,i) in noticeWayList" :key="i" :label="item.value_no" @change="setnoticeWay">{{item.value_name}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="通知限制">
        <div class="select_08">
          <div class="select_08">
            <!-- <div class="select_09" style="padding-left:0">每</div> -->
              <div>
                <el-select v-model="form.hour" placeholder="请选择">
                  <el-option v-for="(t,i) in times" :key="i" :label="t" :value="t"></el-option>
                </el-select>
              </div>
            <div class="select_09">小时,</div>
          </div>

          <div class="select_08">
            <div class="select_09">通知</div>
              <div>
                <el-select v-model="form.times" placeholder="请选择" disabled>
                  <el-option v-for="(f,i) in nums" :key="i" :label="f" :value="f"></el-option>
                </el-select>
              </div>
            <div class="select_09">次</div>
          </div>
        </div>
        <div class="elTag">
          <el-tag type="danger" size="mini" effect="dark">级别指：告警的紧急程度，周期指：{{[2005,2007].indexOf(form.alarmType)!==-1?'设备离线到上线的时间':'周期时间内设备离线的频率'}}</el-tag>
        </div>
      </el-form-item>
      
      <el-form-item label="通知时间">
        <el-time-picker v-model="form.startTime" value-format="HH:mm:ss" placeholder="任意时间点" :clearable="false" disabled></el-time-picker>
          <span class="select_09">至</span>
        <el-time-picker v-model="form.endTime" value-format="HH:mm:ss" placeholder="任意时间点" :clearable="false" disabled></el-time-picker>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaRoleWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  props:["triggerSourceList","productList","alarmTypeList","cycleList","levelList","conditionList","unitList","unionUserList","rankList","noticeWayList"],
  data () {
    return {
      // 原角色码
      originRoleCode: '',
      // 表单数据
      form: {
        id:null,
        triggerWay:3001,
        noticeWay:'',
        startTime:'00:00:00',
        endTime:'23:59:59',
        hour:1,
        times:1
      },
      ruleName:'',
      noticeWay:[],
      unionUser:[],
      times: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
      nums: [1],
      // 验证规则
      rules: {
        product: [
          { required: true, message: '请选择产品' }
        ],
        triggerSource: [
          { required: true, message: '请选择触发源' }
        ],
        alarmType:[
          { required: true, message: '请选择告警类型' }
        ],
      },
      level:4003,
      cycle:5001,
      condition:6001,
      value:1,
      unit:7001,
      isEdit:false,
    }
  },
  methods: {
    //设置通知方式
    setnoticeWay(){
      this.form.noticeWay = this.noticeWay.join(',')
    },
    //设置规则姓名
    // setRuleName(){
    //   this.ruleName = 
    // },
    open (title, target) {
      let that = this;
      that.title = title
      that.visible = true
      that.isEdit = false;
      that.ruleName = '';
      that.level = 4003;
      that.cycle = 5001;
      that.condition = 6001;
      that.unit = 7001;
      that.value = 1;
      // 新建
      if (target == null) {
        that.$nextTick(() => {
          that.$refs.form.resetFields()
          that.form[that.configData['field.id']] = null
        })
        return
      }
      console.log(target)
      // 编辑
      that.isEdit = true;
      that.form.product = target.product;
      that.form.alarmType = target.alarmType;
      that.form.triggerSource = target.triggerSource;
      that.form.triggerWay = target.triggerWay;
      that.level = target.triggerList[0].level;
      that.cycle = target.triggerList[0].cycle;
      that.condition = target.triggerList[0].condition;
      that.unit = target.triggerList[0].unit;
      that.value = target.triggerList[0].value;
      that.unionUser = target.noticeConditionList[0].userIds.split(',');
      that.unionUser = that.unionUser.map(Number); 
      that.noticeWay = target.noticeConditionList[0].noticeWay.split(',');
      that.noticeWay = that.noticeWay.map(Number); 
      that.form.hour = target.noticeConditionList[0].hour;
      that.form.times = target.noticeConditionList[0].times;
      that.form.startTime = target.noticeConditionList[0].startTime;
      that.form.endTime = target.noticeConditionList[0].endTime;
      that.form.alarmRuleId = target.id;
      that.form.triggerConditionId = target.triggerList[0].id
      that.form.msgNoticeId = target.noticeConditionList[0].id
    },
    confirm () {
      let that = this;
      // that.form.triggerCondition = that.level + '_' + that.cycle + '_' + that.condition + '_' + that.value + '_' + that.unit;
      that.form.triggerCondition = '4003' + '_' + that.cycle + '_' + that.condition + '_' + that.value + '_' + that.unit;
      that.form.msgNotice = '_' + that.unionUser.join(',');
      that.form.noticeWay = that.noticeWay.join(',')
      if(!that.form.product){
        that.$message({
          message: '请选择产品',
          type: 'warning'
        });
        return false
      }
      if(!that.form.triggerSource){
        that.$message({
          message: '请选择触发源',
          type: 'warning'
        });
        return false
      }
      if(!that.form.alarmType){
        that.$message({
          message: '请选择告警类型',
          type: 'warning'
        });
        return false
      }
      that.productList.map(item=>{
        if(item.value == that.form.product){
          that.form.ruleName = item.label
        }
      })
      that.triggerSourceList.map(item=>{
        if(item.value_no == that.form.triggerSource){
          that.form.ruleName += '_' + item.value_name
        }
      })
      that.alarmTypeList.map(item=>{
        if(item.value_no == that.form.alarmType){
          that.form.ruleName += '_' + item.value_name
        }
      })
      that.form.ruleName += '_' + that.form.triggerWay
      console.log('that.form',that.form)
      if (that.form.alarmRuleId == null || that.form.alarmRuleId === '') {
        that.__confirmCreate()
        return
      }else{
        that.__confirmEdit()
        return
      }
    },
  },
  created () {
    this.config({
      api: '/alarm/alarm'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.form-title {
  color:#409EFF;
  font-weight: 600;
  border-bottom:1px solid #409EFF;
  padding-bottom:10px;
  margin-bottom:10px;
}
.elTag{
  padding:0 0 10px 0;
}
.select_08 {
  display: flex;
  color: #606266;
}
.select_09 {
  padding: 0 5px;
  color: #606266;
}
</style>