
import request from '@/utils/request'
import OSS from 'ali-oss'

// 根据车场获取广告
export function getADInfoByUnion (comId,isSend) {
  return request.get('/system/orderAd/getComAd?comId=' + comId + '&isSend=' + isSend)
}
// 获取能操作广告管理的车场集合
export function getAuthCom () {
    return request.get('/system/orderAd/getAllOpenComInfo')
}
// 获取STS访问权限
export function sts (data) {
  return request.get('/system/file/sts/falcon',data)
}
export function getSTS(data) {
  return request.get('/system/upgradefile/sts',data)
}
// 上传广告到OSS后存数据库
export function uploadParam (data) {
  return request.post('/system/ad/upload',data)
}
// 上传广告
export function sendAdByCom (data) {
  return request.post('/system/orderAd/upload',data)
}

// 删除广告order-ad/park
export function deleteAd (data) {
  let url = '/system/ad/deleteAdByCom?';
  for(var key in data){
    url += (key + '=' + data[key] + '&')
  }
  return request.post(url,data)
}
/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
 export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}

// 根据车场获取广告类型
export function getAdType (comId,isSend) {
  return request.get('/system/orderAd/getAdType?comId=' + comId)
}

// 查询所有广告类型
export function AdTypeList () {
  return request.get('/system/orderAd/AdTypeList')
}

// 设置默认广告
export function defaultAdType (type) {
  return request.get('/system/orderAd/defaultAdType?type=' + type)
}


// admin层查询默认广告
export function getDefaultAdType () {
  return request.get('/system/orderAd/getDefaultAdType')
}

// 获取广告点击次数
export function adCountList () {
  return request.get('/system/orderAd/adCountList')
}

 // 查询广告日报
export function adDailyList (data) {
  return request.get(`/system/orderAd/adDailyList?startDate=${data.startDate}&endDate=${data.endDate}`)
}

// 查询所有广告类型
export function getAdTypeList (data) {
  return request.get(`/system/orderAd/AdTypeList`)
}

// 查询省份广告
export function adDailyListByProvince (data) {
  return request.get(`/system/orderAd/adDailyListByProvince?startDate=${data.startDate}&endDate=${data.endDate}`)
}

// 查询省份广告
export function adDailyListByProvinceExport (data) {
  return request.get(`/system/orderAd/adDailyListByProvinceExport?startDate=${data.startDate}&endDate=${data.endDate}`)
}


/**
 *  [accessKeyId] {String}：通过阿里云控制台创建的AccessKey。
 *  [accessKeySecret] {String}：通过阿里云控制台创建的AccessSecret。
 *  [bucket] {String}：通过控制台或PutBucket创建的bucket。
 *  [region] {String}：bucket所在的区域， 默认oss-cn-hangzhou。
 */
export function client(data) {
  return new OSS({
    region: data.region,
    accessKeyId: data.accessKeyId,
    accessKeySecret:  data.accessKeySecret,
    bucket: data.bucket,
    stsToken: data.securityToken
  })
}