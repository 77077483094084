import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/videoPlayback/comQuery', data)
}

// 地区编码查询
export function areaCode (data) {
  return request.get(`/system/videoPlayback/areaCode?level=${data.level}&pid=${data.pid}`, data)
}

// 行业编码查询
export function industryCode () {
  return request.get(`/system/videoPlayback/industryCode?type=1`)
}

// 新增回放车场
export function comAdd (data) {
  return request.post(`/system/videoPlayback/comDeviceAdd?comId=${data.comId}&industryCode=${data.industryCode}&areaCode=${data.areaCode}`,data)
}

// 删除回放车场
export function comDelete (data) {
  return request.post(`/system/videoPlayback/comDelete?comId=${data.comId}`,data)
}

// 查询回放车场下的通道
export function passQuery (data) {
  return request.post('/system/videoPlayback/passQuery', data)
}

// 查询回放车场下的通道2
export function passInfo (data) {
  return request.post('/system/com/passInfo', data)
}
// 新增回放车场下的通道
export function passAdd (data) {
  return request.post(`/system/videoPlayback/passAdd?passId=${data.passId}&deviceId=${data.deviceId}`,data)
}

// 删除回放车场下的通道
export function passDelete (data) {
  return request.post(`/system/videoPlayback/passDelete?passId=${data.passId}`,data)
}

// 根据车场查询回放车场设备
export function comDeviceQuery (data) {
  return request.get(`/system/videoPlayback/comDeviceQuery?comId=${data.comId}`,data)
}
// 删除回放车场设备
export function comDeviceDelete (data) {
  return request.post(`/system/videoPlayback/comDeviceDelete?deviceId=${data.deviceId}`,data)
}
// 通道刷新
export function passSync (data) {
  return request.post(`/system/videoPlayback/passSync?deviceId=${data.deviceId}`,data)
}
