import request from '@/utils/request'

// 微信授权回调接口
export function backurl () {
  return request.get('/wx/backurl')
}
// 获取二维码URL
export function getWxScanUrl (data) {
  return request.post(`/wx/getQRCodeUrl?type=${data.type}&id=${data.id}`)
}
// 获取微信绑定结果接口
export function getWxLoginBoundResult (data) {
  return request.get(`/wx/getWxLoginBoundResult?state=${data.state}`)
}
// 微信授权登录账号绑定接口
export function wxLoginBoundId (data) {
  return request.get(`/wx/wxLoginBoundId?reqParameterMap=${data.reqParameterMap}`)
}
// 微信授权登录账号绑定页面
export function wxLoginBoundPage (data) {
  return request.get(`/wx/wxLoginBoundPage`)
}
// 解绑微信登录接口
export function wxLoginDelBound (data) {
  return request.post(`/wx/wxLoginDelBound`)
}
// 微信授权登录/绑定结果页面
export function wxLoginResultPage (data) {
  return request.post(`/wx/wxLoginResultPage`)
}
//获取微信授权信息
export function getInfo (data) {
  return request.get(`/wx/getInfo`)
}