<template>
  <div class="app-container parkAnalysis-container">
    <!-- 搜索框开始 -->
    <div class="searchheader" style="display: flex;">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="选择时间">
          <div class="block">
            <el-date-picker
              v-model="timeValue"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions2"
              value-format="yyyy-MM-dd"
              :clearable="false"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="adDailyListFun">搜索</el-button>
        </el-form-item>
      </el-form>
      <div style="flex-grow: 1;text-align: right;">
        <el-button type="success" @click="adDailyListByProvinceExportFun">导出</el-button>
      </div>
    </div>
    <!-- 搜索框结束 -->
    <!-- 表格 -->
    <div style="margin-top:10px;">
      <el-table :data="tableData" ref="customerReportDateTable" border style="overflow: visible !important;" v-loading="loading" lement-loading-spinner="el-icon-loading" height="700px" show-summary>
        <el-table-column type="index" label="索引" width="100" />
        <el-table-column prop="province" label="省份" />
        <el-table-column prop="IST" label="IST广告观看次数" />
        <el-table-column prop="ISTClick" label="IST广告点击次数" />
        <el-table-column prop="PP" label="PP广告观看次数" />
        <el-table-column prop="PPClick" label="PP广告点击次数" />
        <el-table-column prop="IZF" label="艾之付广告观看次数" />
        <el-table-column prop="IZFClick" label="艾之付广告点击次数" />
      </el-table>
    </div>
  </div>
</template>

<script>
import { adDailyListByProvince, getAdTypeList, adDailyListByProvinceExport } from "@/api/ad/oderAdUpLoad";

export default {
  name: "index",
  data() {
    return {
      value1: null,
      tableData: [],
      loading: false,
      formInline: {},
      timeValue: [],
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      adType: 'pp',
      searchForm: {},
      click: ''
    };
  },
  created() {
    
  },
  mounted(){
    let currentDate = this.currentDate()
    let timeList = currentDate.split('-')
    timeList[2] = '01'
    this.timeValue = [ timeList.join('-'), currentDate]
    
    this.adDailyListFun()
    this.getAdTypeListFun()
  },
  updated() {

    this.$nextTick(() => {

      this.$refs.customerReportDateTable.doLayout();

    });

  },
  methods: {

    adDailyListByProvinceExportFun(){
      let data = {
        startDate: this.timeValue[0],
        endDate: this.timeValue[1],
      }
      var url = window.location.origin
      var url2 = url + '/yunying/system/orderAd/adDailyListByProvinceExport'
      window.open(url2 + `?startDate=${data.startDate}&endDate=${data.endDate}`)
    },
    getAdTypeListFun() {
      getAdTypeList().then(res=>{
        

      })
    },
    adDailyListFun() {
      let data = {
        startDate: this.timeValue[0],
        endDate: this.timeValue[1],
      }
      adDailyListByProvince(data).then(res=>{
        if (res.length) {
          res.map(item => {
            item['IST'] = item['IST'] || 0
            item['ISTClick'] = item['ISTClick'] || 0
            item['PP'] = item['PP'] || 0
            item['PPClick'] = item['PPClick'] || 0
            item['IZF'] = item['IZF'] || 0
            item['IZFClick'] = item['IZFClick'] || 0
          })
        }
        this.tableData = res 

      })
    },
    searchData() {},
    currentDate() {
      //返回当前日期年月日 2018-03-20
      let start = new Date();
      return start.getFullYear() + '-' + this.formatNumber(start.getMonth() + 1) + '-' + this.formatNumber(start.getDate());
    },
    // 获取最近时间
    formatNumber(num) {
      //数字格式化成两位 xx
        return num > 9 ? num : '0' + num;
    },
  }
};
</script>

<style scoped>
  .parkAnalysis-container{
    margin: 16px;
    background: #fff;
    box-sizing: border-box;
    padding: 26px;
    border-radius: 4px;
    padding-bottom: 82px;
  }
</style>
