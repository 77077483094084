import request from '@/utils/request'

// 模糊查询车牌
export function queryParkCarNumber (data) {
  return request.get(`/system/console/queryCarNumber?comId=${data.comId}&carNumber=${data.carNumber}`)
}

// 根据车场号和相机sn查询iot_device_tb设备
export function queryIotDevice (data) {
  return request.get(`/system/console/queryIotDevice/${data.comId}/${data.passId}`)
}

// 查询月卡会员详情
export function queryMonthCardDetails (data) {
  return request.get(`/system/console/queryMonthCardDetails?comId=${data.comId}&carNumber=${data.carNumber}`)
}

// 查询订单详情
export function queryOrderDetails (data) {
  return request.get(`/system/console/queryOrderDetails?comId=${data.comId}&carNumber=${data.carNumber}`)
}

// 查询全景相机视频地址
export function queryPanoramicCameraVideoUrl (data) {
  return request.get(`/system/console/queryPanoramicCameraVideoUrl?comId=${data.comId}&passId=${data.passId}`)
}

// 查询车场详情
export function queryParkDetails (data) {
  return request.get(`/system/console/queryParkDetails/${data.park_id}`)
}

// 查询软件车场列表
export function queryParkList (data) {
  return request.get(`/system/console/queryParkPassList`)
}

// 根据车场号和相机sn查询通道数据
export function queryPassData (data) {
  return request.get(`/system/console/queryPassData/${data.comId}/${data.sn}`)
}
  
// 查询通道详情
export function queryPassDetails (data) {
  console.log('查询通道详情',data)
  return request.get(`/system/console/queryPassDetails/${data.comId}/${data.passId}`)
}
  
// 查询通道列表
export function queryParkPass (data) {
  return request.get(`/system/console/queryPassList/${data.park_id}`)
}
  
// 查询通道视频地址
export function queryPassVideoUrl (data) {
  return request.get(`/system/console/queryPassVideoUrl/${data.comId}/${data.channelId}`)
}

// 查看用户所属租户所能查看的车场
export function queryManagerComList (data) {
  return request.get(`/system/com/managerComList`)
}

//获取告警列表
export function alarmQueryInfor(data){
  return request.post(`/system/omr/alarmRecord`,data)
}

//告警消息-表格-处理
export function alarmOperateInfor(data) {
  let url = '/system/alarm/process?';
  for(var key in data){
    url += (key + '=' + data[key] + '&')
  }
  return request.post(url,data)
}

//获取通道下的左右设备
export function queryAllIotDeviceInfor(data){
  return request.get(`/system/console/queryAllIotDevice/${data.comid}/${data.channelId}`)
}

//重启设备
export function deviceRestart(data){
  return request.post(`/system/device/deviceRestart?sn=${data.sn}`)
}

//微信对讲获取SI数据
export function getUSAndSI(data){
  return request.get(`/system/TRTC/getUSAndSI?userId=${data.userId}`)
}

//微信对讲通知用户进房
export function notifyingUserJoin(data){
  return request.get(`/system/TRTC/notifyingUserJoin?b=true&requestNum=${data.requestNum}&userId=${data.userId}&wsToken=${data.wsToken}`)
}
// /根据通道id查询详情
export function getPassDatails(data){
  return request.get(`/system/comPass/getDatails/${data}`)
}
// /修改订单信息（车牌号）
export function updateOrderInfo(data){
  return request.post(`/system/console/updateOrderInfo?comId=${data.comId}&newCarNumber=${data.newCarNumber}&oldCarNumber=${data.oldCarNumber}`)
}
// 刷新token
export function refreshToken(){
  return request.get(`/system/refresh`)
}
// /修改订单信息（车牌号）
export function queryDevice(data){
  return request.get(`/system/console/queryDevice/${data.comId}/${data.passId}`)
}

// 开关闸
export function onOffGate(data){
  return request.post(`/system/console/onOffGate?comId=${data.comId}&passId=${data.passId}&type=${data.type}`,data)
}
// 补单缴费
export function makeupOrderWithPay(data){
  return request.post(`/system/console/makeupOrderWithPay`,data)
}
// 结算离场
export function settlementWithOut(data){
  return request.post(`/system/console/settlementWithOut`,data)
}
// 入场放行
export function inParkRelease(data){
  return request.post(`/system/console/inParkRelease`,data)
}
// 获取ws地址
export function webRTCConfig(){
  return request.get(`/webRTC/config`)
}

// 手动下发车牌
export function manualSetCarNumber(data){
  return request.post(`/system/console/manualSetCarNumber`,data)
}

// 特殊车辆类型
export function specialVehicleType(){
  return request.get(`/system/console/specialVehicleType`)
}

// 特殊车辆
export function specialVehicle(data){
  return request.post(`/system/console/specialVehicle`, data)
}