<template>
  <div class="dashboard-editor-container">

    <panel-group :count-data="count"/>



    <el-row :gutter="32">

      <el-col :xs="24" :sm="24" :lg="24">
        <div class="chart-wrapper">
          <!--<map-china :height="mapHeight"></map-china>-->
          <device-map :chart-data="unionMapList"/>
        </div>
      </el-col>
      <!--<el-col :xs="24" :sm="24" :lg="8">-->
        <!--<div class="chart-wrapper">-->
          <!--<bar :height="lineHeight"></bar>-->
        <!--</div>-->
      <!--</el-col>-->
    </el-row>

  </div>
</template>



<script>
  import PanelGroup from '@/components/dataCenter/union/PanelGroup'
  import DeviceMap from '@/components/dataCenter/union/DeviceMap'
  import LineChart from '@/components/dataCenter/union/LineChart'
  import RaddarChart from '@/components/dataCenter/union/RaddarChart'
  import PieChart from '@/components/dataCenter/union/PieChart'
  import PieChart2 from '@/components/dataCenter/union/PieChart2'
  import PieChartDevice from '@/components/dataCenter/union/PieChartDevice'
  import BarChart from '@/components/dataCenter/union/BarChart'
  import MapChina from '@/components/dataCenter/union/MapChina'
  import Bar from '@/components/dataCenter/union/Bar'
  import { getCenterDataByUnion } from '@/api/dataCenter/union'


  export default {
    name: 'datacenterUnion',
    components: {
      DeviceMap,
      PanelGroup,
      LineChart,
      RaddarChart,
      PieChart,
      PieChart2,
      BarChart,
      PieChartDevice,
      MapChina,
      Bar
    },
    data() {
      return {

        mapHeight:'600px',
        lineHeight:'600px',

        count: {
          parkTotal:0,
          opencount:0,
          managerTotal:0,
          equipcount:0,
          callcount:0,
          alarmcount:0,
        },
        unionMapList:{
          expectedData: [],
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData(){
        getCenterDataByUnion().then(response => {
          const data = response
          this.count={
            parkTotal:data.comNums,
            opencount:data.dayOpenNums,
            managerTotal:data.adminCount,
            equipcount:data.deviceCount,
            callcount:data.allCallCount,
            alarmcount:data.dayAlarmNums,
          }
          this.unionMapList = {
            expectedData: data.comInfoList,
          }
        })

      }
    }
  }
</script>

<style lang="scss" scoped>
  .dashboard-editor-container {
    padding: 20px;
    background-color: rgb(240, 242, 245);
    position: relative;

    .github-corner {
      position: absolute;
      top: 0px;
      border: 0;
      right: 0;
    }

    .chart-wrapper {
      background: #fff;
      padding: 16px 16px 0;
      margin-bottom: 20px;
    }
  }

  @media (max-width:1024px) {
    .chart-wrapper {
      padding: 8px;
    }
  }
</style>
