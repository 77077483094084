
import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/upgrade/fileInfo', data)
}

// 新增
export function add (data) {
  return request.post('/system/deviceCategory/add', data)
}

// 删除
export function del (data) {
  return request.post('/system/upgrade/deleteFile?id=' + data, data)
}



// 查询设备
export function deciveList (data) {
  return request.post('/system/deviceCategory/query', data)
}


// 上传文件
export function uploadFile (data) {
  return request.post('/system/upgrade/uploadFile', data)
}

/**
 * 生成随机文件名称
 * 规则八位随机字符，加下划线连接时间戳
 */
 export const getFileNameUUID = () => {
  function rx() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  return `${+new Date()}_${rx()}${rx()}`
}

// 获取STS访问权限
export function sts (data) {
  return request.get('/system/file/sts',data)
}