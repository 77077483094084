
import request from '@/utils/request'

// 分页查询广告
export function fetchList (data) {
  return request.post('/system/orderAd/getAllAd',data)
}
// 获取能操作广告管理的车场集合
export function getAuthCom (data) {
    return request.get('/system/orderAd/getAllOpenComInfo',data)
  }
// 预览图片和视频
export function preview (adPath) {
  return request.get('/system/orderAd/preview?adPath=' + adPath)
}
// 审核不通过
export function checkNotPass (data) {
  return request.post(`/system/orderAd/checkNotPass?adId=${data.adId}&comId=${data.comId}`,data)
}
// 审核通过
export function checkPass (data) {
return request.post(`/system/orderAd/checkPass?adId=${data.adId}&comId=${data.comId}` ,data)
}