import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/user/page', data)
}

// 新建
export function create (data) {
  return request.post('/system/user/create', data, {
    trim: true
  })
}

// 修改
export function updateById (data) {
  return request.post('/system/user/updateById', data, {
    trim: true
  })
}

// 删除
export function deleteById (id) {
  return request.get(`/system/user/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/system/user/delete/batch', {
    params: {
      ids
    }
  })
}

// 配置用户角色
export function createUserRole (data) {
  return request.post('/system/user/createUserRole', data)
}

// 重置密码
export function resetPwd (data) {
  return request.post('/system/user/resetPwd', data)
}

// 查询所有
export function fetchAll () {
  return request.get('/system/role/all')
}

// 查询用户绑定的所有通道id
export function getPassListByBindingUser (usrId) {
  return request.get('/system/comPass/getPassListByBindingUser/' + usrId)
}

//绑定用户和通道关系
export function bindingUserToPass (data) {
  return request.post(`/system/comPass/bindingUserToPass?passIds=${data.passIds}&userId=${data.userId}`,data)
}

//解绑用户和通道关系
export function unBindingUserToPass (data) {
  return request.post(`/system/comPass/unBindingUserToPass?passIds=${JSON.parse(data.passIds)}&userId=${data.userId}`,data)
}

//查看用户所属租户所能查看的车场
export function managerComList () {
  return request.get(`/system/com/managerComList`)
}

//条件查询通道数据
export function passInfo (data) {
  return request.post(`/system/com/passInfo`,data)
}