<template>
  <div class="home">
    <div v-if="isAdmin == 'false'">
      <unionDataCenter></unionDataCenter>
    </div>
    <div v-if="isAdmin == 'true' ">
      <adminDataCenter></adminDataCenter>
    </div>
    
  </div>
</template>

<script>
import unionDataCenter from "./dataCenter/union.vue"
import adminDataCenter from "./dataCenter/admin.vue"
export default {
  components: {unionDataCenter,adminDataCenter},
  name: 'Workbench',
  provide() {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isAdmin:null,
      isReload: true
    }
  },
  mounted(){
    
    this.isAdmin = JSON.parse(JSON.stringify(sessionStorage.getItem('isAdmin')));
  },
  methods: {
    reload() {
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/variables.scss";
.home {
  text-align: center;
  color: #777;
  .wrap {
    padding-top: 20px;
    img {
      width: 240px;
    }
  }
  h2 {
    font-size: 32px;
    color: #555;
    margin-top: 20px;
    margin-bottom: 12px;
  }
  h3 {
    color: #555;
    line-height: 30px;
    font-size: 20px;
  }
  p {
    line-height: 24px;
    margin: 0;
  }
  .star {
    margin-top: 12px;
  }
  .guide {
    margin: 30px 0 40px 0;
    display: flex;
    justify-content: center;
    a {
      margin-right: 12px;
      padding: 12px 40px;
      border-radius: 30px;
      background: $primary-color;
      color: #fff;
      text-decoration: none;
    }
  }
  em,a {
    font-style: normal;
    font-weight: bold;
    margin: 0 3px;
    color: $primary-color;
  }
  .award {
    position: absolute;
    right: 20px;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 12px;
    border: 1px solid #eee;
    box-shadow: -1px 1px 10px #ccc;
    h4 {
      font-weight: bold;
      margin-bottom: 8px;
    }
    img {
      width: 160px;
      margin-bottom: 20px;
      opacity: 0.8;
      transition: opacity ease .3s;
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
