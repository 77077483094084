<template>
  <!-- 表格和分页 -->
    <div>
      <section style="text-align: right;margin-bottom: 10px;">
        <el-button type="primary" plain @click="toBack" style="float:left;">返回</el-button>
        <el-button type="primary" @click="dialogFormVisible = true" icon="el-icon-plus">新建</el-button>
      </section>
      <el-table
        ref="table"
        :data="tableList"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        default-expand-all
      >
        <el-table-column prop="name" label="设备型号"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="130px">
          <template slot-scope="{row}">{{$getTime(row.createTime)}}</template>
        </el-table-column>
        <el-table-column label="操作" min-width="140" fixed="right">
          <template slot-scope="{row}">
            <el-tag type="danger" effect="dark" @click="del(row.id)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pagination.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
        @size-change="sizeChange"
        @current-change="currentChange"
        background>
      </el-pagination>
      <el-dialog title="新增小类" :visible.sync="dialogFormVisible" width="30vw">
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item label="设备code" prop="code">
            <el-input v-model="form.code" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="设备名称" prop="name">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('form')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
    
</template>

<script>
import {fetchList, add, del} from '@/api/device/type'
export default {
  name: 'SystemMenu',
  props:['detailDetail'],
  data () {
    return {
      dialogFormVisible:false,
      rules:{
        name:[
          { required: true, message: '请输入设备名称', trigger: 'blur' },
        ],
        code:[
          { required: true, message: '请输入设备code', trigger: 'blur' },
        ]
      },
      form:{
        code:'',
        name:'',
        pid:''
      },
      tableList:[],
      pagination:{
        pageSize:20,
        pageIndex:1,
        total:0
      }
    }
  },
  mounted(){
    this.currentChange(1)
  },
  methods: {
    del(id){
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del(id).then(res=>{
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.currentChange(1)
        })
        
      })
      
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.pid = this.detailDetail.id
          add(this.form).then(res=>{
            this.$message({
              message: '新增成功',
              type: 'success'
            });
          })
          this.dialogFormVisible = false
          this.currentChange(1)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 每页显示数量变更处理
    sizeChange (pageSize) {
      this.pagination.pageSize = pageSize
      this.currentChange()
    },
    // 页码变更处理
    currentChange (pageIndex) {
      this.pagination.pageIndex = pageIndex || this.pagination.pageIndex
      let data = {
        capacity: this.pagination.pageSize,
        model: this.detailDetail.id,
        page: this.pagination.pageIndex
      }
      fetchList(data)
        .then(data => {
         if(data !== null){
            this.tableList = []
            data.records.map(item=>{
              
              this.tableList.push(item)
            })
            this.pagination.total = data.total
          }
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    toBack(){
      this.$emit('toBack');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
