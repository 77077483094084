<template>
  <TableLayout class="menu-layout" :permissions="['system:videoPlayback:query']">
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <div v-if="!showcameraPass">
        <section style="text-align: right;margin-bottom: 10px;">
          <el-button type="primary" @click="openAdd" icon="el-icon-plus" v-permissions="['system:videoPlayback:add']">新建</el-button>
        </section>
        <el-table
          ref="table"
          v-loading="isWorking.search"
          :data="tableData.list"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          row-key="id"
          stripe
          height="60vh"
          default-expand-all
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="id" label="车场编号"></el-table-column>
          <el-table-column prop="companyName" label="车场名称"></el-table-column>
          <el-table-column prop="createTime" label="创建时间">
            <template slot-scope="{row}">{{$getTime(row.createTime)}}</template>
          </el-table-column>
          <el-table-column prop="status" label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="openDevice(scope.row)">查看设备</el-button> 
              <el-button size="mini" type="success" @click="opencameraPass(scope.row)">通道</el-button> 
              <el-button size="mini" type="danger" @click="comDeleteFun(scope.row.id)" v-permissions="['system:videoPlayback:delete']">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        ></pagination>
      </div>

      <cameraPass v-if="showcameraPass" :checkPark="checkPark" @toPark="toPark"></cameraPass>
    </template>

    <el-dialog title="新增回放设备" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="form" style="width:300px;margin:0 auto;" label-position="right">
        <el-form-item label="选择车场">
          <el-select v-model="form.comId" filterable placeholder="请选择车场">
            <el-option v-for="(item,i) in parkList" :label="item.companyName" :value="item.id" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择省">
          <el-select v-model="form.areaCodeS" placeholder="请选择省" @change="changeareaCodeS($event,'2')">
            <el-option v-for="(item,i) in areaCode1" :label="item.name" :value="item.id" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择市">
          <el-select v-model="form.areaCodeS2" placeholder="请选择市" @change="changeareaCodeS($event,'3')">
            <el-option v-for="(item,i) in areaCode2" :label="item.name" :value="item.id" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择县">
          <el-select v-model="form.areaCode" placeholder="请选择县" @change="changeareaCodeS($event,'4')">
            <el-option v-for="(item,i) in areaCode3" :label="item.name" :value="item.id" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择行业">
          <el-select v-model="form.industryCode" placeholder="请选择行业">
            <el-option v-for="(item,i) in industryCodeList" :label="item.name" :value="item.id" :key="i"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="comAddFun">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="NVR设备地址" :visible.sync="showDeviceList" width="40%">
      <el-table :data="deviceData">
        <el-table-column property="deviceId" label="设备ID"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{row}">
            <div style="#67C23A" v-if="row.isOnline">在线</div>
            <div v-if="!row.isOnline">离线</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="passSyncFun(scope.row)" :disabled="!scope.row.isOnline">通道刷新</el-button> 
            <el-button size="mini" type="danger" @click="deviceDeleteFun(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import cameraVideoPass from '@/components/parking/cameraVideoPass'
import cameraPass from '@/components/parking/cameraPass'
import {areaCode, industryCode, comAdd, comDelete, comDeviceQuery, comDeviceDelete, passSync} from '@/api/parking/cameraVideo'
import { fetchList } from '@/api/parking/parking'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination, cameraVideoPass, cameraPass},
  data () {
    return {
      checkPark:{},
      showcameraPass: false,
      showcameraVideoPass:false,
      form:{
        industryCode:'',
        comId:'',
        areaCode:'',
      },
      dialogFormVisible:false,
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        deviceId:'',
        deviceType:'',
        nodeId:'',
        parkId:'',
        shortId:'',
        status:'',
        userId:'',
        sourceType:''
      },
      parkList:[],
      areaCode1:[],
      areaCode2:[],
      areaCode3:[],
      industryCodeList:[],
      showDeviceList:false,
      deviceData : []
    }
  },
  methods: {
    passSyncFun(row){
      let data = {
        deviceId : row.deviceId
      }
      passSync(data).then(res=>{
        this.$message.success('操作成功');
      })
    },
    deviceDeleteFun(row){
      let data = {
        deviceId : row.deviceId
      }
      this.$confirm('确认删除该设备?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        comDeviceDelete(data).then(res=>{
          this.$message.success('删除成功');
          this.openDevice({id:row.comId})
        })
      })
    },
    openDevice(row){
      let data = {
        comId : row.id
      }
      comDeviceQuery(data).then(res=>{
        console.log(res)
        this.deviceData = res
      })
      this.showDeviceList = true
    },
    toPark(){
      this.showcameraPass = false
    },
    opencameraPass(item){
      this.checkPark = item
      this.showcameraPass = true
    },
    comDeleteFun(comId){
      this.$confirm('确认删除该车场?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        comDelete({comId:comId}).then(res=>{
          this.$message.success('操作成功');
          this.search()
        }).catch(e=>{
          this.$message.error(e);
        })
      })
      
    },
    closedialogTableVisible(){
      this.dialogTableVisible = false
    },
    comAddFun(){
      let data = this.form
      for(var key in data){
        if(!data[key]){
          this.$message.error('请填写完整信息')
          return false
        }
      }
      comAdd(data).then(res=>{
        this.$message.success('操作成功');
        this.dialogFormVisible = false;
        this.search()
      }).catch(e=>{
        this.$message.error(e);
      })
    },
    tocameraVideoPass(){
      this.showcameraVideoPass = true
      
    },
    getindustryCode(){
      industryCode().then(res=>{
        this.industryCodeList = res
      }).catch(e=>{
        this.$message.error(e);
      })
    },
    changeareaCodeS(item,level){
      this.$forceUpdate()
      let data = {
        pid:item,
        level:level
      }
      if(level == '2'){
        this.form.areaCodeS2 = ''
        this.form.areaCode = ''
      }
      if(level != '4'){
        this.getareaCode(data)
      }
      
    },
    getareaCode(data){ 
      areaCode(data).then(res=>{
        this['areaCode' + data.level] = res;
      }).catch(e=>{
        this.$message.error(e);
      })
    },
    //新增页面
    openAdd(){
      let data = {"page":1,"capacity":200,"model":{"deviceId":"","deviceType":"","nodeId":"","parkId":"","shortId":"","status":"","userId":"","sourceType":""}}
      fetchList(data).then(res=>{
        this.parkList = res.records
        this.dialogFormVisible = true
      }).catch(e=>{
        this.$message.error(e);
      })
      this.getareaCode({level:1,pid:'1'})
      this.getindustryCode()
    },
    
  },
  created () {
    this.config({
      module: '菜单',
      api: '/parking/cameraVideo',
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
