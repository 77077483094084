<template>
  <div class="app-container parkAnalysis-container">
    <!-- 搜索框开始 -->
    <div class="searchheader" style="display: flex;">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="选择省份">
          <el-select v-model="Province" filterable placeholder="Select">
            <el-option label="全部" value=""/>
            <el-option v-for="item in allProvince" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="provinceTypeListFun2">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 搜索框结束 -->
    <!-- 表格 -->
    <div style="margin-top:10px;">
      <el-table :data="tableData" ref="customerReportDateTable" border style="overflow: visible !important;" v-loading="loading" lement-loading-spinner="el-icon-loading" height="700px">
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column prop="name" label="省份" />
        <el-table-column prop="typeS" label="广告类型" />
        <el-table-column label="操作" min-width="160" fixed="right">
          <template slot-scope="{row}">
            <el-button type="text" icon="el-icon-edit" @click="opendialogVisible(row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="编辑广告类型" :visible.sync="dialogVisible"  width="300px">
      <el-select v-model="editData.type">
        <el-option :label="item.name" :value="item.type" v-for="item in adTypeList" :key="item.type"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateProvinceType, provinceTypeList, AdTypeList } from "@/api/ad/regionalAd";

export default {
  name: "index",
  data() {
    return {
      adTypeList: [],
      value1: null,
      tableData: [],
      loading: false,
      formInline: {},
      timeValue: [],
      searchForm: {},
      click: '',
      allProvince: [],
      Province: '',
      editData: {},
      dialogVisible: false
    };
  },
  created() {
    
  },
  mounted(){
    this.provinceTypeListFun()
    this.getAdTypeList()
  },
  updated() {

    this.$nextTick(() => {

      this.$refs.customerReportDateTable.doLayout();

    });

  },
  methods: {
    getAdTypeList() {
      AdTypeList().then(res=>{
        this.adTypeList = res
      })
    },
    doEdit() {
      updateProvinceType(this.editData).then(res=>{
        this.$message.success('操作成功');
        this.dialogVisible = false
        this.editData = {}
        this.provinceTypeListFun()
      })
    },
    opendialogVisible(row) {
      this.editData = row
      this.dialogVisible = true
    },
    provinceTypeListFun2() {
      this.tableData = []
      if (this.Province) {
        this.allProvince.map(item => {
          if (item.id == this.Province) {
            this.tableData.push(item)
          }
        })
      } else {
        this.tableData = JSON.parse(JSON.stringify(this.allProvince))
      }
    },
    provinceTypeListFun() {
      provinceTypeList().then(res=>{
        if (res.length) {
          res.map(item => {
            item.typeS = item.type === 'OFF' ? '关闭' : item.type
          })
        }
        this.tableData = res 
        this.allProvince = res
      })
    }
  }
};
</script>

<style scoped>
  .parkAnalysis-container{
    margin: 16px;
    background: #fff;
    box-sizing: border-box;
    padding: 26px;
    border-radius: 4px;
    padding-bottom: 82px;
  }
</style>
