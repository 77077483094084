import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/omr/openRecord',data)
}

// 获取设备名称
export function getDeviceNames (data) {
    return request.get('/system/omr/deviceNames',data)
  }
  // 查询通道
export function getPass () {
    return request.get('/system/omr/pass',)
  }