<template>
  <TableLayout class="menu-layout">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="设备类型">
        <el-select v-model="searchForm.deviceCategory" placeholder="请选择设备类型" @change="changedeciveChidList('searchForm')">
          <el-option :label="item.name" :value="item.code" v-for="(item,i) in deciveList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备型号">
        <el-select v-model="searchForm.deviceModel" placeholder="请选择设备型号">
          <el-option :label="item.name" :value="item.code" v-for="(item,i) in deciveChidList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文件名称" >
        <el-input v-model="searchForm.fileName" placeholder="请输入车场编号" clearable />
      </el-form-item>
      <el-form-item label="版本号" >
        <el-input v-model="searchForm.fileVersion" placeholder="请输入车场编号" clearable />
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="resetNew">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <div style="text-align:right;">
        <el-button type="primary" @click="upFileOpen">上传文件</el-button>
      </div>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        default-expand-all
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="fileName" label="文件名" width="440px"></el-table-column>
        <el-table-column prop="createTime" label="上传时间" min-width="130px">
          <template slot-scope="{row}">{{$getTime(row.createTime)}}</template>
        </el-table-column>
        <el-table-column prop="remark" label="介绍"></el-table-column>
        <el-table-column prop="version" label="版本"></el-table-column>
        <el-table-column prop="deviceModel" label="设备型号"></el-table-column>
        <el-table-column
          label="操作"
          min-width="140"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-tag type="danger" effect="dark" style="cursor: pointer" @click="delFun(row.id)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
      <el-dialog title="上传文件" :visible.sync="upFileShow" width="30vw">
        <el-form :model="fileForm" ref="fileForm">
          <el-form-item label="设备类型">
            <el-select v-model="fileForm.deviceCategory" placeholder="请选择设备类型" @change="changedeciveChidList('fileForm')">
              <el-option :label="item.name" :value="item.code" v-for="(item,i) in deciveList" :key="i"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备型号">
            <el-select v-model="fileForm.deviceModel" placeholder="请选择设备型号" @change="$forceUpdate()">
              <el-option :label="item.name" :value="item.code" v-for="(item,i) in deciveChidList" :key="i"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="版本">
            <el-input v-model="fileForm.version" placeholder="请输入版本" clearable />
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="fileForm.remark" type="textarea" placeholder="请输入备注" clearable />
          </el-form-item>
          <div>
            <el-upload
              class="upload-demo"
              ref="upload"
              :action="uploadUrl"
              :before-upload="beforeUpload"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              :http-request="upload2OSS"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>

              <!-- <div slot="tip" class="el-upload__tip">只能上传.pack格式文件</div> -->
            </el-upload>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="upFileShow = false">取 消</el-button>
          <el-button type="primary" @click="submitUpload" :loading="upLoading">确 定</el-button>
        </div>
      </el-dialog>
    </template>


  </TableLayout>
</template>

<script>
import {client} from "../../api/ad/oderAdUpLoad";
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import {deciveList, getFileNameUUID, uploadFile, del ,sts} from '@/api/device/upFile'
import SparkMD5 from 'spark-md5'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination},
  data () {
    return {
      upLoading:false,
      uploadUrl:'',
      fileForm:{
        "deviceCategory": '',
        "deviceModel": '',
        "fileName": '',
        "fileVersion": ''
      },
      upFileUrl:'',
      upFileShow:false,
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        "deviceCategory": '',
        "deviceModel": '',
        "fileName": '',
        "fileVersion": ''
      },
      deviceId:'',
      selectArr: [
        { label: "未处理", value: "10001" },
        { label: "已处理", value: "10002" },
        { label: "忽略", value: "10003" },
      ],
      level:{
        4001:'提示',
        4002:'警告',
        4003:'严重',
        4004:'紧急',
      },
      passData:{},
      deciveList:[],
      deciveChidList:[],
      fileList:[],
      alidata: {},
      param:{},
      file:{}
    }
  },
  mounted(){
    this.getdeciveList()
  },
  methods: {
    delFun(id){
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        del(id).then(res=>{
          this.$message({
            type: 'success',
            message: '操作成功!'
          });
          this.search()
        })
      })
    },
    submitUpload() {
      let that = this
      this.upLoading = true
      setTimeout(()=>{
        that.$refs.upload.submit();
      },1000)
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {},
    async beforeUpload(file){
      const _this = this;
      let fileFormat = file.name.substring(//文件后缀名.pack
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );

//        if(fileFormat.indexOf("pack") == -1 ){//文件不是.pack类型
//          _this.$message.error("请上传正确的升级文件格式");
//          return false;
//        }

      //todo 验证文件大小以及文件后缀

      _this.fileForm.md5sum = await _this.getMd5Sum(file)
      //获取oss的临时权限
      return new Promise((resolve, reject) => {
        sts().then(response => {
          _this.alidata.accessKeyId = response.access_key_id;
          _this.alidata.accessKeySecret = response.access_key_secret;
          _this.alidata.securityToken = response.security_token;
          _this.alidata.bucket = response.bucket;
          _this.alidata.region = response.region;
          resolve(true);
        }).catch(err => {
          console.log(err);
          reject(false);
        });
      });
    },
    upload2OSS(file){
      const _this = this;
      async function multipartUpload() {
        let temporary = file.file.name.lastIndexOf(".");
        let fileNameLength = file.file.name.length;
        let fileFormat = file.file.name.substring(//文件后缀名.pack
          temporary + 1,
          fileNameLength
        );
        let fileName = getFileNameUUID() + "." + fileFormat;//uuid文件名称
        let filePath = `upgrade_file/${_this.fileForm.deviceCategory}/${_this.fileForm.deviceModel}/${fileName}`
        //存放上传文件的参数
        _this.param.fileName = file.file.name
        _this.param.filePath = filePath
        _this.param.deviceCategory = _this.fileForm.deviceCategory
        _this.param.deviceModel = _this.fileForm.deviceModel
        _this.param.remark = _this.fileForm.remark
        _this.param.version = _this.fileForm.version
        console.log(_this.fileForm.md5sum)
        _this.param.md5sum = _this.fileForm.md5sum
        client(_this.alidata)
          .multipartUpload(filePath, file.file, {
            progress: function(p) {
              //p进度条的值

              // _this.showProgress = true;
              // _this.progress = Math.floor(p * 100);
            }
          })
          .then(result => {
            //上传成功返回值，可针对项目需求写其他逻辑
            console.log(result)
            // _this.param.uploadTime = new Date().getTime();
            uploadFile(_this.param).then(res => {

              _this.$message({
                type: 'success',
                message: '上传成功!'
              });
              _this.search()
              _this.upFileShow = false
              _this.upLoading = false
            })
          })
          .catch(err => {
            console.log("err:", err);
          });
      }
      setTimeout(() => {
        multipartUpload()
      }, 1000)
    },
    getMd5Sum(file) {
      let _this = this;
      // 创建文件读取对象，此对象允许Web应用程序异步读取存储在用户计算机上的文件内容
      var fileReader = new FileReader();
      // 根据浏览器获取文件分割方法
      var blobSlice =
        File.prototype.mozSlice ||
        File.prototype.webkitSlice ||
        File.prototype.slice;
      // 指定文件分块大小(2M)
      var chunkSize = 2 * 1024 * 1024;
      // 计算文件分块总数
      var chunks = Math.ceil(file.size / chunkSize);

      // 指定当前块指针
      var currentChunk = 0;

      // 创建MD5计算对象
      var spark = new SparkMD5.ArrayBuffer();

      // 当读取操作成功完成时调用
      fileReader.onload = function (e) {
        // 将文件内容追加至spark中
        spark.append(e.target.result);
        currentChunk += 1;

        // 判断文件是否都已经读取完
        console.log(currentChunk,chunks,currentChunk <= chunks)
        if (currentChunk < chunks) {
           console.log( currentChunk,chunks)
          loadNext();
        } else {
          _this.fileForm.md5sum = spark.end()
          console.log( _this.fileForm.md5sum)
          return spark.end();
        }
      };
      fileReader.onerror = function () {
        console.warn("FileReader error.");
      };

      // FileReader分片式读取文件
      function loadNext() {
        // 计算开始读取的位置
        var start = currentChunk * chunkSize;
        // 计算结束读取的位置
        var end =
          start + chunkSize >= file.size ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
      }
      // FileReader分片式读取文件
      loadNext();
    },
    upFileOpen(){
      this.fileForm.deviceCategory =  this.deciveList[0].code
      this.changedeciveChidList('fileForm')
      this.upFileShow = true
    },
    changedeciveChidList(val){
      if(this[val].deviceCategory == ''){
        return false
      }
      this.deciveList.map(item=>{
        if(item.code == this[val].deviceCategory){
          this.deviceId = item.id
        }
      })
      this[val].deviceModel = ''
      let data = {
        capacity: 200,
        model: this.deviceId,
        page: 1
      }
      deciveList(data).then(res=>{
        this.deciveChidList = res.records
      })
    },
    getdeciveList(){
      let data = {
        capacity: 200,
        model: 0,
        page: 1
      }
      deciveList(data).then(res=>{
        this.deciveList = res.records
        
      })
    },
    //重置
    resetNew(){
      this.searchForm = {
        "deviceCategory": '',
        "deviceModel": '',
        "fileName": '',
        "fileVersion": ''
      }
      this.search()
    },
  },
  created () {
    this.config({
      module: '菜单',
      api: '/device/upFile',
      // sorts: [{
      //   property: 'start_time',
      //   direction: 'DESC'
      // }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
