<template>
  <el-card style="margin-bottom:20px;">
    <div class="user-profile">
      <div class="box-center">
        <pan-thumb :image="user.wxImgUrl" :height="'100px'" :width="'100px'" :hoverable="false">
          <div>Hello</div>
        </pan-thumb>
      </div>
      <div class="box-center">
        <!--<div class="user-name text-center">{{ wxName }}</div>-->
        <div class="user-name text-center">{{ user.name }}</div>
      </div>
      <div class="box-center">
        <el-link v-if="showBoundWX" type="primary" @click="handleWx">绑定微信</el-link>
        <el-link v-if="!showBoundWX" type="primary" @click="handleDelWx">解绑微信</el-link>
      </div>
    </div>


    


    <el-dialog
      title="微信扫码绑定"
      :visible.sync="showWxScanDialog"
      width="20%"
      :show-close="false"
      :destroy-on-close="true"
      :before-close="closeWxScanDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center>
     <div id="qrcode" ></div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="closeWxScanDialog">取 消</el-button>
  </span>
    </el-dialog>
  </el-card>
</template>

<script>
import PanThumb from '@/components/PanThumb'
import CountTo from 'vue-count-to'
import {getWxScanUrl,wxLoginDelBound,getWxLoginBoundResult,getInfo} from '@/api/system/wechat'
import QRCode from 'qrcodejs2'
import { mapState } from 'vuex'

export default {
  components: { PanThumb ,CountTo},
  computed: {
    ...mapState(['userInfo'])
  },

  data() {
    return {
      wxOpenid:'',
      wxImgurl:'',
      wxName:'',
      userid:'',
      showBoundWX:true,
      showWxScanDialog:false,
      user:{
        name: '',
        email: '',
        avatar: '',
        roles: '',
        openid:'',
        userid:'',
        wxImgUrl:''
      },
      queryStatus:null,
    }
  },
  created() {
    this.userid = this.userInfo.id;
    this.getUserBaseInfo()

  },
  destroyed(){
    let that = this;
    if(this.queryStatus != null){
      clearInterval(that.queryStatus)
      that.queryStatus = null;
    }
  },
  methods: {
    handleDelWx(){
      var that = this
      var param = {
        'userid':that.userid,
        'openid':that.wxOpenid
      }
      wxLoginDelBound(param).then(response => {
        that.getUserBaseInfo()
        this.$message({
          message: response,
          type: 'success',
          duration: 2 * 1000
        })
      }).catch(e=>{
        this.$message({
          message: '解绑失败',
          type: 'error',
          duration: 2 * 1000
        })
      })
    },
    hasOpenid(){
   
      if(this.wxOpenid !=null && this.wxOpenid != ''){
        this.showBoundWX=false
      }else {
        this.showBoundWX=true
      }
    },
    closeWxScanDialog(){
      var that = this
      that.showWxScanDialog=false
      clearInterval(that.queryStatus)
      that.queryStatus = null;
    },
    getWxLoginBoundResult(state){
      var that = this
      var param = {
        'state':state,
      }
      getWxLoginBoundResult(param).then(response => {
        var data = response
        if(data){
          clearInterval(that.queryStatus)
          that.queryStatus = null;
          that.showBoundWX=false

          that.getUserBaseInfo()

          this.$message({
            message: data,
            type: 'success',
            duration: 2 * 1000
          })

          that.closeWxScanDialog()
        }else {
          clearInterval(that.queryStatus)
          this.$message({
            message: data.msg,
            type: 'error',
            duration: 2 * 1000
          })
        }
      })
    },
    getUserBaseInfo(){
      var that = this
      getInfo().then(res =>{
        this.user.name = res.name;
        this.user.wxOpenId = res.openId;
        this.user.wxImgUrl = res.isBinding ? res.imgUrl : '';
        this.showBoundWX = !res.isBinding;
      })
    },

   handleWx(){
     var that = this

     //获取二维码链接
     getWxScanUrl({'type':1,'id':that.userid}).then(response => {
       console.log(response)
       if(response){//获取成功
         that.showWxScanDialog=true
         that.msg='请使用微信扫码'
         that.$nextTick(function () {
           document.getElementById("qrcode").innerHTML = "";
           let qrcode = new QRCode("qrcode", {
             width: 150,
             height: 150,
             text:response,
           });
         });

         var arr = response.split("=")

         that.queryStatus = setInterval(function () {
           that.getWxLoginBoundResult(arr[1]);
         },1000);
       }
     })
   },
  }
}
</script>

<style lang="scss" scoped>
 .box-center {
   margin: 0 auto;
   display: table;
 }

 .text-muted {
   color: #777;
 }

 .user-profile {
   .user-name {
     font-weight: bold;
   }

   .box-center {
     padding-top: 10px;
   }

   .user-role {
     padding-top: 10px;
     font-weight: 400;
     font-size: 14px;
   }

   .box-social {
     padding-top: 30px;

     .el-table {
       border-top: 1px solid #dfe6ec;
     }
   }

   .user-follow {
     padding-top: 20px;
   }
 }

 .user-bio {
   margin-top: 20px;
   color: #606266;

   span {
     padding-left: 4px;
   }

   .user-bio-section {
     font-size: 14px;
     padding: 15px 0;

     .user-bio-section-header {
       border-bottom: 1px solid #dfe6ec;
       padding-bottom: 10px;
       margin-bottom: 10px;
       font-weight: bold;
     }
   }
 }
 #qrcode{
   width: 150px;
   margin: 0 auto;
 }
</style>
