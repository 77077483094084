import request from '@/utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/omr/alarmRecord?',data)
}

// 处理警告
export function alarmOperate (data) {
  let url = '/system/alarm/process?';
  for(var key in data){
    url += (key + '=' + data[key] + '&')
  }
  return request.post(url,data)
}

// 查询通道
export function getPass () {
  return request.get('/system/omr/pass',)
}

// 获取所有的枚举值
export function queryAllMenu () {
  return request.get('/system/alarm/queryAllMenu',)
}