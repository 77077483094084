<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" label-width="90px">
      <el-form-item label="运营商:">
        <el-select v-model="form.tenantId" placeholder="请选择车场类型" @change="changTenant">
          <el-option v-for="(tenant , i) in tenantList" :key="i" :label="tenant.name" :value="tenant.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="`剩余${form.type == '1' ? '条数' : '分钟'}:`">
        {{form.type == '1' ? smsCount : wxVoice}}
        <!-- <el-input v-model="smsCount" :disabled="true"></el-input> -->
      </el-form-item>
      <el-form-item :label="`充值${form.type == '1' ? '条数' : '分钟'}:`">
        <el-input-number v-model="form.value" :min="1" :step="1" step-strictly></el-input-number>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import {doRecharge,getInfoByTanant,update,queryAllTtenant} from '@/api/addValueAdded/index'
export default {
  name: 'OperaRoleWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  props:["search"],
  data () {
    return {
      // 表单数据
      form: {
        id:null,
        value:1,
        type:'',
        tenantId:''
      },
      smsCount:0,
      tenantList:[],
      wxVoice:0
    }
  },
  methods: {
    open (type) {
      this.form = {
        id:null,
        value:1,
        type:'',
        tenantId:''
      }
      this.queryAllTtenantFun();
      this.visible = true
      this.form.type = type
      this.title = type == '1' ? '短信充值' : '音视频充值'
    },
    //获取运营商列表
    queryAllTtenantFun(){
      let data = {
        model:{
          "code": "",
          "createTimeEnd": null,
          "createTimeStart": null,
          "tenantId": null,
          "tenantName": ""
        }
      }
      queryAllTtenant(data).then(res=>{
        this.tenantList = res;
        this.form.tenantId = res[0].id;
        this.changTenant()
      })
    },
    //选择运营商
    changTenant(){
      getInfoByTanant(this.form.tenantId).then(res=>{
        this.smsCount = res.smsCount;
        this.wxVoice = res.wxVoice; 
      })
    },
    confirm () {
      doRecharge(this.form)
        .then(records => {
          this.$message({
            message: '充值成功',
            type: 'success'
          });
          this.search()
          this.visible = false
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
        })
    }
  },
  created () {
    this.config({
      api: '/addValueAdded/index'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.form-title {
  color:#409EFF;
  font-weight: 600;
  border-bottom:1px solid #409EFF;
  padding-bottom:10px;
  margin-bottom:10px;
}
.elTag{
  padding:0 0 10px 0;
}
.select_08 {
  display: flex;
  color: #606266;
}
.select_09 {
  padding: 0 5px;
  color: #606266;
}
</style>