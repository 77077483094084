<template>
  <el-row :gutter="40" class="panel-group">
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('callTimes')">
        <div class="card-panel-icon-wrapper icon-call">
          <el-image style="width:48px;height:48px;" :src="require('../../../assets/images/device.png')"></el-image>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            设备总数
          </div>
          <count-to :start-val="0" :end-val="this.countData.deviceCount" :duration="10" class="card-panel-num" style="color:#5D998F"/>
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('openTimes')">
        <div class="card-panel-icon-wrapper icon-open">
          <el-image style="width:48px;height:48px;" :src="require('../../../assets/images/on.png')"></el-image>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            在线设备
          </div>
          <count-to :start-val="0" :end-val="this.countData.onlineCount" :duration="10" class="card-panel-num" style="color:#83F267"/>
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('alermTimes')">
        <div class="card-panel-icon-wrapper icon-duijiang">
          <el-image style="width:48px;height:48px;" :src="require('../../../assets/images/off.png')"></el-image>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            离线设备
          </div>
          <count-to :start-val="0" :end-val="this.countData.offlineCount" :duration="10" class="card-panel-num" style="color:#DADF42"/>
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('onlineDevices')">
        <div class="card-panel-icon-wrapper icon-online">
          <el-image style="width:48px;height:48px;" :src="require('../../../assets/images/url.png')"></el-image>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            已绑定车场设备
          </div>
          <count-to :start-val="0" :end-val="this.countData.bindCount" :duration="10" class="card-panel-num" style="color:#4352C0"/>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CountTo from 'vue-count-to'

export default {
  props: {
    countData: {
      type: Object,
      required: true
    }
  },
  components: {
    CountTo
  },
  methods: {
    handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }
      .icon-call {
        background: #dbdbdb;
      }
      .icon-open {
        background: #dbdbdb;
      }
      .icon-online {
        background: #dbdbdb;
      }
      .icon-duijiang {
        background: #dbdbdb;
      }

    }

    .icon-call {
      color: #d81e06;
    }

    .icon-message {
      color: #36a3f7;
    }


    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
