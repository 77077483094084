<template>
  <!-- 表格和分页 -->
    <div>
      <section style="text-align: right;margin-bottom: 10px;">
        <el-button type="primary" plain @click="toPark" style="float:left;">返回</el-button>
        <el-button type="primary" @click="openAdd" icon="el-icon-plus" v-permissions="['system:videoPlayback:add']">新建</el-button>
      </section>
      <el-table
        ref="table"
        :data="tableData"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        default-expand-all
      >
        <el-table-column prop="passId" label="通道ID"></el-table-column>
        <el-table-column prop="passName" label="通道名称"></el-table-column>
        <el-table-column prop="channelId" label="通道编号"></el-table-column>
        <el-table-column prop="deviceId" label="设备ID"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="{row}">
            <div style="#67C23A" v-if="row.isOnline">在线</div>
            <div v-if="!row.isOnline">离线</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <el-button :disabled="!scope.row.isOnline" size="mini" type="success" @click="tocameraVideoPass(scope.row)">设备录像</el-button> 
            
            <el-button size="mini" type="danger" @click="passDeleteFun(scope.row.passId)" v-permissions="['system:videoPlayback:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="pagination"
      ></pagination>

      <el-dialog title="新增通道" :visible.sync="dialogFormVisible" width="30%">
        <el-form :model="form" style="width:300px;margin:0 auto;" label-position="right">
          <el-form-item label="选择通道">
            <el-select v-model="form.passId" placeholder="请选择通道">
              <el-option v-for="(item,i) in passList" :label="item.passname" :value="item.id" :key="i"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择设备">
            <el-select v-model="form.deviceId" placeholder="请选择设备">
              <el-option v-for="(item,i) in deviceIdList" :label="item.deviceId" :value="item.deviceId" :key="i"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="passAddFun">确 定</el-button>
        </div>
      </el-dialog>

      <cameraVideoPass v-if="showcameraVideoPass" :checkPass="checkPass" @closeDevice="closeDevice"></cameraVideoPass> 
    </div>
</template>

<script>
import {passQuery, passInfo, passAdd,passDelete,comDeviceQuery,passSync} from '@/api/parking/cameraVideo'
import cameraVideoPass from '@/components/parking/cameraVideoPass'
import Pagination from '@/components/common/Pagination'
export default {
  name: 'SystemMenu',
  props:['checkPark'],
  components: { Pagination, cameraVideoPass},
  data () {
    return {
      checkPass:{},
      pagination:{
        pageIndex:1,
        pageSize:20,
        total:0
      },
      showcameraVideoPass:false,
      form:{
        passId:''
      },
      dialogFormVisible:false,
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      parkList:[],
      areaCode1:[],
      areaCode2:[],
      areaCode3:[],
      industryCodeList:[],
      passList:[],
      tableData:[],
      deviceIdList:[]
    }
  },
  mounted(){
    this.passQueryFun()
  },
  methods: {
    passSyncFun(row){
      let data = {
        deviceId : row.deviceId
      }
      passSync(data).then(res=>{
        this.$message.success('操作成功');
      })
    },
    closeDevice(){
      this.showcameraVideoPass = false
    },
    openDevice(){
      let data = {
        comId : this.checkPark.id
      }
      comDeviceQuery(data).then(res=>{
        this.deviceIdList = res
      })
    },
    tocameraVideoPass(data){
      this.checkPass = data
      this.showcameraVideoPass = true
    },
    passAddFun(){
      let data = this.form
      for(var key in data){
        if(!data[key]){
          this.$message.error('请选择通道')
          return false
        }
      }
      passAdd(data).then(res=>{
        this.$message.success('操作成功');
        this.dialogFormVisible = false;
        this.passQueryFun()
      }).catch(e=>{
        this.$message.error(e);
      })
    },
    openAdd(){
      let data = {"page":1,"capacity":200,"model":{comId:this.checkPark.id,comName: "",passName: "",passType: ""}}
      this.openDevice()
      passInfo(data).then(res=>{
        this.passList = res.records
        this.dialogFormVisible = true
      }).catch(e=>{
        this.$message.error(e);
      })
      
    },
    passDeleteFun(passId){
      this.$confirm('确认删除该通道?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        passDelete({passId:passId}).then(res=>{
          this.$message.success('删除成功');
          this.passQueryFun()
        }).catch(e=>{
          this.$message.error(e);
        })
      })
    },
    toPark(){
      this.$emit('toPark');
    },
    passQueryFun(){
      let data = {
        "capacity": this.pagination.pageSize,
        "model": this.checkPark.id,
        "page": this.pagination.pageIndex,
        "sorts": [
          {
            "direction": "string",
            "property": "string"
          }
        ]
      }
      passQuery(data).then(res=>{
        this.tableData = res.records
      })
    },
    handleSizeChange(){},
    handlePageChange(){}
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
