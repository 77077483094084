import Vue from 'vue'
import VueRouter from 'vue-router'
import AppLayout from '@/layouts/AppLayout'
import { getUserInfo } from '@/api/system/common'
const Login = () => import('@/views/login')
const ErrorNoPermissions = () => import('@/views/no-permissions')
const Error404 = () => import('@/views/404')
const workTable = () => import('@/views/work/work')
const control = () => import('@/views/control/control')
const controlPage = () => import('@/views/control/controlPage')
const chargingPile = () => import('@/views/control/chargingPile')

Vue.use(VueRouter)

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  routes: [
    // 登录
    {
      name: 'login',
      path: '/login',
      component: Login
    },
    // 无权限
    {
      name: 'no-permissions',
      path: '/no-permissions',
      component: ErrorNoPermissions
    },
    // 404
    {
      name: '404',
      path: '/404',
      component: Error404
    },
    // 工作台
    {
      name: 'work',
      path: '/workTable',
      component: workTable
    },
    // 监控
    {
      name: 'control',
      path: '/control',
      component: control
    },
    // 监控分页
    {
      name: 'controlPage',
      path: '/controlPage',
      component: controlPage
    },
    {
      name: 'chargingPile',
      path: '/chargingPile',
      component: chargingPile
    },
    // 首页重定向
    {
      path: '/',
      redirect: '/index'
    },
    // 内容页（动态加载）
    {
      name: 'layout',
      path: '',
      component: AppLayout,
      children: []
    }
  ]
})
router.beforeEach((to, from, next) => {
  
  // 无权访问页面可直接访问
  if (to.name === 'no-permissions') {
    next()
    return
  }
  const userInfo = router.app.$options.store.state.userInfo
  if (userInfo != null) {
    // 如果用户不存在权限
    if (userInfo.permissions.length === 0) {
      next({ name: 'no-permissions' })
      return
    }
    next()
    return
  }
  // 登录页面处理
  if (to.name === 'login') {
    next()
    return
  }
  // 非登录页面，验证用户是否登录
  getUserInfo()
    .then(userInfo => {
      // 如果用户不存在权限
      if (userInfo.permissions.length === 0) {
        next({ name: 'no-permissions' })
        return
      }
      // 已登录，存储userInfo
      router.app.$store.commit('setUserInfo', userInfo)
      localStorage.setItem('roles',userInfo.roles)
      localStorage.setItem('userInfo',userInfo)
      next()
    })
    .catch(e => {
      // 未登录，跳转至登录页
      next({ name: 'login' })
    })
})

export default router
