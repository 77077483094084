<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
  import echarts from 'echarts'
  require('echarts/theme/macarons') // echarts theme
  import resize from './mixins/resize'

  const animationDuration = 6000

  export default {
    mixins: [resize],
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '300px'
      }
    },
    data() {
      return {
        chart: null
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.initChart()
      })
    },
    beforeDestroy() {
      if (!this.chart) {
        return
      }
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
        this.chart = echarts.init(this.$el, 'macarons')
//        var builderJson = {
//          "all": 10887,
//          "charts": {
//            "map": 3237,
//            "lines": 2164,
//            "bar": 7561,
//            "line": 7778,
//            "pie": 7355,
//            "scatter": 2405,
//            "candlestick": 1842,
//            "radar": 2090,
//            "heatmap": 1762,
//            "treemap": 1593,
//            "graph": 2060,
//            "boxplot": 1537,
//            "parallel": 1908,
//            "gauge": 2107,
//            "funnel": 1692,
//            "sankey": 1568
//          },
//          "components": {
//            "geo": 2788,
//            "title": 9575,
//            "legend": 9400,
//            "tooltip": 9466,
//            "grid": 9266,
//            "markPoint": 3419,
//            "markLine": 2984,
//            "timeline": 2739,
//            "dataZoom": 2744,
//            "visualMap": 2466,
//            "toolbox": 3034,
//            "polar": 1945
//          },
//          "ie": 9743
//        };
//
//        var downloadJson = {
//          "echarts.min.js": 17365,
//          "echarts.simple.min.js": 4079,
//          "echarts.common.min.js": 6929,
//          "echarts.js": 14890
//        };
//
//        var themeJson = {
//          "dark.js": 1594,
//          "infographic.js": 925,
//          "shine.js": 1608,
//          "roma.js": 721,
//          "macarons.js": 2179,
//          "vintage.js": 1982
//        };
//
//
//        var option = {
//
//          tooltip: {},
//          title: [{
//            text: '在线构建',
//            subtext: '总计 ' + builderJson.all,
//            x: '25%',
//            textAlign: 'center'
//          }, {
//            text: '各版本下载',
//            subtext: '总计 ' + Object.keys(downloadJson).reduce(function (all, key) {
//              return all + downloadJson[key];
//            }, 0),
//            x: '75%',
//            textAlign: 'center'
//          }, {
//            text: '主题下载',
//            subtext: '总计 ' + Object.keys(themeJson).reduce(function (all, key) {
//              return all + themeJson[key];
//            }, 0),
//            x: '75%',
//            y: '50%',
//            textAlign: 'center'
//          }],
//          grid: [{
//            top: 50,
//            width: '50%',
//            bottom: '45%',
//            left: 10,
//            containLabel: true
//          }, {
//            top: '55%',
//            width: '50%',
//            bottom: 0,
//            left: 10,
//            containLabel: true
//          }],
//          xAxis: [{
//            type: 'value',
//            max: builderJson.all,
//            splitLine: {
//              show: false
//            }
//          }, {
//            type: 'value',
//            max: builderJson.all,
//            gridIndex: 1,
//            splitLine: {
//              show: false
//            }
//          }],
//          yAxis: [{
//            type: 'category',
//            data: Object.keys(builderJson.charts),
//            axisLabel: {
//              interval: 0,
//              rotate: 30
//            },
//            splitLine: {
//              show: false
//            }
//          }, {
//            gridIndex: 1,
//            type: 'category',
//            data: Object.keys(builderJson.components),
//            axisLabel: {
//              interval: 0,
//              rotate: 30
//            },
//            splitLine: {
//              show: false
//            }
//          }],
//          series: [{
//            type: 'bar',
//            stack: 'chart',
//            z: 3,
//            label: {
//              normal: {
//                position: 'right',
//                show: true
//              }
//            },
//            data: Object.keys(builderJson.charts).map(function (key) {
//              return builderJson.charts[key];
//            })
//          }, {
//            type: 'bar',
//            stack: 'chart',
//            silent: true,
//            itemStyle: {
//              normal: {
//                color: '#eee'
//              }
//            },
//            data: Object.keys(builderJson.charts).map(function (key) {
//              return builderJson.all - builderJson.charts[key];
//            })
//          }, {
//            type: 'bar',
//            stack: 'component',
//            xAxisIndex: 1,
//            yAxisIndex: 1,
//            z: 3,
//            label: {
//              normal: {
//                position: 'right',
//                show: true
//              }
//            },
//            data: Object.keys(builderJson.components).map(function (key) {
//              return builderJson.components[key];
//            })
//          }, {
//            type: 'bar',
//            stack: 'component',
//            silent: true,
//            xAxisIndex: 1,
//            yAxisIndex: 1,
//            itemStyle: {
//              normal: {
//                color: '#eee'
//              }
//            },
//            data: Object.keys(builderJson.components).map(function (key) {
//              return builderJson.all - builderJson.components[key];
//            })
//          }, {
//            type: 'pie',
//            radius: [0, '30%'],
//            center: ['75%', '25%'],
//            data: Object.keys(downloadJson).map(function (key) {
//              return {
//                name: key.replace('.js', ''),
//                value: downloadJson[key]
//              }
//            })
//          }, {
//            type: 'pie',
//            radius: [0, '30%'],
//            center: ['75%', '75%'],
//            data: Object.keys(themeJson).map(function (key) {
//              return {
//                name: key.replace('.js', ''),
//                value: themeJson[key]
//              }
//            })
//          }]
//        }


        var builderJson = {
          "all": 10887,
          "charts": {
            "map": 3237,
            "lines": 2164,
            "bar": 7561,
            "line": 7778,
            "pie": 7355,
            "scatter": 2405,
            "candlestick": 1842,
            "radar": 2090,
            "heatmap": 1762,
            "treemap": 1593,
            "graph": 2060,
            "boxplot": 1537,
            "parallel": 1908,
            "gauge": 2107,
            "funnel": 1692,
            "sankey": 1568
          },
          "ie": 9743
        };



        var option = {
          tooltip: {},
          title: [{
            text: '今日车场呼叫排行',
            subtext: '总计 ' + builderJson.all,
            x: '25%',
            textAlign: 'center'
          }],
          grid: [{
            top: 50,
            width: '90%',
            left: 10,
            containLabel: true
          }],
          xAxis: [{
            type: 'value',
            max: builderJson.all,
            splitLine: {
              show: false
            }
          }],
          yAxis: [{
            type: 'category',
            data: Object.keys(builderJson.charts),
//            //y轴名称倾斜
//            axisLabel: {
//              interval: 0,
//              rotate: 30
//            },
            splitLine: {
              show: false
            }
          }],
          series: [{
            type: 'bar',
            stack: 'chart',
            z: 3,
            label: {
              normal: {
                position: 'right',
                show: true
              }
            },
            data: Object.keys(builderJson.charts).map(function (key) {
              return builderJson.charts[key];
            })
          }]
        }

//        var option = {
//          title: {
//            text: '呼叫排行',
//          },
//          dataset: {
//            source: [
//              ['score', 'amount', 'product'],
//              [89.3, 58212, 'Matcha Latte'],
//              [57.1, 78254, 'Milk Tea'],
//              [74.4, 41032, 'Cheese Cocoa'],
//              [50.1, 12755, 'Cheese Brownie'],
//              [89.7, 20145, 'Matcha Cocoa'],
//              [68.1, 79146, 'Tea'],
//              [19.6, 91852, 'Orange Juice'],
//              [10.6, 101852, 'Lemon Juice'],
//              [32.7, 20112, 'Walnut Brownie']
//            ]
//          },
//          grid: {containLabel: true},
//          xAxis: {name: 'amount'},
//          yAxis: {type: 'category'},
//          visualMap: {
//            orient: 'horizontal',
//            left: 'center',
//            min: 10,
//            max: 100,
//            text: ['高', '低'],
//            // Map the score column to color
//            dimension: 0,
//            inRange: {
//              color: ['#D7DA8B', '#E15457']
//            }
//          },
//          series: [
//            {
//              type: 'bar',
//              encode: {
//                // Map the "amount" column to X axis.
//                x: 'amount',
//                // Map the "product" column to Y axis
//                y: 'product'
//              }
//            }
//          ]
//        };
        this.chart.setOption(option)
      }
    }
  }
</script>
