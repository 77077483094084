<template>
  <TableLayout class="menu-layout">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="设备类型">
        <el-select v-model="searchForm.deviceCategory" placeholder="请选择设备类型" @change="changedeciveChidList('searchForm')">
          <el-option :label="item.name" :value="item.code" v-for="(item) in deciveList" :key="item.code + '1'"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备型号">
        <el-select v-model="searchForm.deviceModel" placeholder="请选择设备型号">
          <el-option :label="item.name" :value="item.code" v-for="(item) in deciveChidList" :key="item.code + '2'"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="升级版本" >
        <el-input v-model="searchForm.version" placeholder="请输入升级版本" clearable />
      </el-form-item>
      <el-form-item label="设备编号" >
        <el-input v-model="searchForm.deviceNodeId" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="resetNew">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="nodeId"
        stripe
        height="60vh"
        default-expand-all
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="sn" label="设备编号"></el-table-column>
        <el-table-column prop="deviceCategory" label="设备类型">
          <template slot-scope="{row}">{{deciveData[row.deviceCategory]}}</template>
        </el-table-column>
        <el-table-column prop="deviceModel" label="设备型号"></el-table-column>
        <el-table-column prop="upgradeTime" label="升级时间" min-width="130px">
          <template slot-scope="{row}">{{$getTime(row.upgradeTime / 1000)}}</template>
        </el-table-column>
        <el-table-column prop="beforeUpgrade" label="升级前版本"></el-table-column>
        <el-table-column prop="afterUpgrade" label="升级后版本"></el-table-column>
        <el-table-column prop="fileId" label="升级文件ID"></el-table-column>
        <el-table-column prop="userId" label="操作人员"></el-table-column>
        <el-table-column prop="upgradeStatus" label="升级状态">
          <template slot-scope="{row}">{{upgradeStatus[row.upgradeStatus]}}</template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>


  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import {deciveList} from '@/api/device/upFile'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination},
  data () {
    return {
      deciveData:{},
      uploadUrl:'',
      upFileUrl:'',
      upFileShow:false,
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        "deviceCategory": '',
        "deviceModel": '',
        "deviceNodeId": '',
        "version": ''
      },
      deviceId:'',
      passData:{},
      deciveList:[],
      deciveChidList:[],
      multipleSelection:[],
      upgradeStatus:{
        '1': '升级中',
        '2': '升级成功',
        '3': '升级失败',
        '4': '下载失败'
      }
    }
  },
  mounted(){
    this.getdeciveList()
  },
  methods: {
    changedeciveChidList(val){
      if(this[val].category == ''){
        return false
      }
      this.deciveList.map(item=>{
        if(item.code == this[val].category){
          this.deviceId = item.id
        }
      })
      this[val].model = ''
      let data = {
        capacity: 200,
        model: this.deviceId,
        page: 1
      }
      deciveList(data).then(res=>{
        this.deciveChidList = res.records
      })
    },
    getdeciveList(){
      let data = {
        capacity: 200,
        model: 0,
        page: 1
      }
      deciveList(data).then(res=>{
        res.records.map(item=>{
          this.deciveData[item.code] = item.name
        })
        this.deciveList = res.records
        
      })
    },
    //重置
    resetNew(){
      this.searchForm = {
        "deviceCategory": '',
        "deviceModel": '',
        "deviceNodeId": '',
        "version": ''
      }
      this.search()
    },
  },
  created () {
    this.config({
      module: '菜单',
      api: '/device/upRecord',
      "sorts":[
        {
          "property":"du.upgrade_time",
          "direction":"desc"
        }
      ]
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
