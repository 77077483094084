<template>
  <TableLayout class="menu-layout" :permissions="['system:omr:openRecord']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="车场编号">
        <el-input v-model="searchForm.comId" placeholder="呼叫车场编号" clearable />
      </el-form-item>
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.sn" placeholder="呼叫设备编号" clearable />
      </el-form-item>
      <el-form-item label="操作人员">
        <el-input v-model="searchForm.operateName" placeholder="呼叫操作人员" clearable />
      </el-form-item>
      <el-form-item label="开闸时间" prop="loginTime">
        <el-date-picker
          v-model="searchDateRange"
          type="datetimerange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="handleSearchTimeChange"
        ></el-date-picker>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="resetNew">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        default-expand-all
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="comid" label="车场编号"></el-table-column>
        <el-table-column prop="comName" label="车场名称"></el-table-column>
        <el-table-column prop="sn" label="设备编号" min-width="140px"></el-table-column>
        <el-table-column prop="sn" label="设备名称" min-width="100px"></el-table-column>
        <el-table-column prop="passName" label="通道名称" min-width="80px"></el-table-column>
        <el-table-column prop="operateName" label="操作人员"></el-table-column>
        <el-table-column prop="openTime" label="开闸时间" min-width="130px">
          <template slot-scope="{row}">{{$getTime(row.openTime)}}</template>
        </el-table-column>
        <el-table-column prop="completeTime" label="完成时间" min-width="130px">
          <template slot-scope="{row}">{{$getTime(row.completeTime)}}</template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import {getDeviceNames,getPass} from '@/api/omr/open'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination},
  data () {
    return {
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        comId:'',
        openTimeBegin:'',
        openTimeEnd:'',
        sn:'',
        userId:''
      },
      statusOptions: [{
        value: '0',
        label: '未处理'
      }, {
        value: '1',
        label: '处理中'
      }, {
        value: '2',
        label: '已处理'
      }],
      callTypeOptions: [{
        value: '0',
        label: '相机主叫'
      }, {
        value: '1',
        label: 'APP主叫'
      }, {
        value: '2',
        label: 'APP广播'
      }],
      DeviceNamesData:{},
      DeviceNamesList:[],
      passData:{}
    }
  },
  mounted(){
    // this.getDeviceNamesFun()
  },
  methods: {
    //获取通道数据
    getPassFuc(){
      getPass()
        .then(records => {
          records.map(item=>{
            this.passData[item.id] = item.passname
          })
          
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
        })
    },
    getDeviceNamesFun(){
      getDeviceNames()
        .then(records => {
          records.map(item=>{
            this.DeviceNamesData[item.iotId] = item.nikeName
          })
          this.DeviceNamesList = records;
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          
        })
    },
    // 时间搜索范围变化
    handleSearchTimeChange (value) {
      this.searchForm.openTimeBegin = null
      this.searchForm.openTimeEnd = null
      if (value != null) {
        this.searchForm.openTimeBegin = value[0] / 1000
        this.searchForm.openTimeEnd = value[1] / 1000
      }
      this.search()
    },
    //重置
    resetNew(){
      this.searchForm = {
        comId:'',
        openTimeBegin:this.searchDateRange[0] / 1000,
        openTimeEnd:this.searchDateRange[1] / 1000,
        sn:'',
        userId:''
      }
      this.search()
    },
  },
  created () {
    const timeStart=new Date(new Date().toLocaleDateString()).getTime();
    let timeEnd=new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)
    this.searchDateRange = [timeStart-86400000*3,timeEnd]
    this.searchForm.openTimeBegin = this.searchDateRange[0] / 1000
    this.searchForm.openTimeEnd = +((this.searchDateRange[1] / 1000).toFixed(0)) - 1
    this.config({
      module: '菜单',
      api: '/omr/open',
    //   sorts: [{
    //     property: 'start_time',
    //     direction: 'DESC'
    //   }]
      
    })
    // this.handleSearchTimeChange(this.searchDateRange)
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
