import { render, staticRenderFns } from "./upFile.vue?vue&type=template&id=5b1b62aa&scoped=true&"
import script from "./upFile.vue?vue&type=script&lang=js&"
export * from "./upFile.vue?vue&type=script&lang=js&"
import style0 from "./upFile.vue?vue&type=style&index=0&id=5b1b62aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b1b62aa",
  null
  
)

export default component.exports