
import request from '@/utils/request'

// 查询当前运营商下所有告警规则
export function fetchList (data) {
  return request.post('/system/alarm/queryRule',data)
}

// 所有枚举查询
export function queryAllMenu () {
    return request.get('/system/alarm/queryAllMenu')
  }
  
// 查询当前运营商下所有告警消息
export function recordList (data) {
    return request.post('/system/alarm/record',data)
  }

//添加告警规则
export function create (data) {
  return request.post('/system/alarm/addRule',data)
}
//编辑告警规则
export function updateById (data) {
  return request.post('/system/alarm/editRule',data)
}

// 删除告警规则
export function deleteById (id) {
  return request.post('/system/alarm/deleteRule?id=' + id)
}

// 启用或者停用
export function operateRule (data) {
  return request.post('/system/alarm/operateRule?id=' + data.id + '&state='  + data.state)
}

