<template>
  <div>
    <TableLayout class="menu-layout" :permissions="['system:menu:query']">
      
      <!-- 表格和分页 -->
      <template v-slot:table-wrap v-if="!defayltTableShow">
        <ul class="toolbar" v-permissions="['system:role:create', 'system:role:delete']">
          <li><el-button icon="el-icon-setting" type="success" @click="useDefaultRuleFun">确认默认启用</el-button></li>
          <li v-permissions="['system:role:create']">
            <el-button icon="el-icon-d-arrow-left" type="text" @click="toBack">返回</el-button>
            <el-button icon="el-icon-refresh" type="text" @click="search">刷新</el-button>
          </li>
          
        </ul>
        <el-table
          ref="table"
          v-loading="isWorking.search"
          :data="tableData.list"
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          row-key="id"
          stripe
          :default-expand-all="false"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="product" label="产品"></el-table-column>
          <el-table-column prop="triggerSource" label="触发源"></el-table-column>
          <el-table-column prop="alarmType" label="类型"></el-table-column>
          <el-table-column prop="triggerWay" label="触发方式"></el-table-column>
          <el-table-column prop="state" label="触发条件">
            <template slot-scope="{row}">{{row.level + row.cycle + row.condition + row.value + row.unit}}</template>
          </el-table-column>
          <el-table-column prop="level" label="层级"></el-table-column>
          <el-table-column prop="noticeWay" label="通知方式"></el-table-column>
          <el-table-column prop="state" label="通知频率">
            <template slot-scope="{row}">{{'每' + row.hour + '小时通知' + row.times + '次'}}</template>
          </el-table-column>
          <el-table-column prop="state" label="通知时间">
            <template slot-scope="{row}">{{row.startTime + '至' + row.endTime}}</template>
          </el-table-column>
        </el-table>
        <pagination
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          :pagination="tableData.pagination"
        ></pagination>
      </template>
    </TableLayout>
  </div>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'

import {useDefaultRule} from '@/api/alarm/DefaultRules'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination},
  data () {
    return {
      value:false,
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        product:'',
        state:'',
        tenantId:'',
        triggerSource:'',
      },
      statusList:[],
      statusData:{},
      productList:[],
      productData:{},
      triggerSourceList:[],
      triggerSourceData:{},
      alarmTypeData:{},
      unionUserData:{},
      defayltTableShow:false,
      multipleSelection:[]
    }
  },
  mounted(){
    
  },
  methods: {
    // 使用默认规则
    useDefaultRuleFun(){
      if(this.multipleSelection.length){
        let idList = [];
        this.multipleSelection.map(item=>{
          idList.push(item.id)
        })
        useDefaultRule(idList)
          .then(records => {
            if( records == '添加成功'){
              this.$message({
                message: '添加成功',
                type: "success",
                duration: 2 * 1000
              });
              this.search()
            }
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
          .finally(() => {
          
          })
      }else{
        this.$tip.apiFailed('请选择规则')
      }
    },
    handleSelectionChange(val){
      this.multipleSelection = val;
    },
    //返回上一页
    toBack(){
      this.$parent.closeDefayltTable()
    }
  },
  created () {
    this.config({
      module: '菜单',
      api: '/alarm/DefaultRules',
      // sorts: [{
      //   property: 'start_time',
      //   direction: 'DESC'
      // }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
