<template>
  <TableLayout class="menu-layout" :permissions="['system:menu:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="运营商编码" prop="code">
        <el-input v-model="searchForm.code" v-trim placeholder="请输入角色编码" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="运营商ID" prop="tenantId">
        <el-input v-model="searchForm.tenantId" v-trim placeholder="请输入角色名称" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="运营商名称" prop="tenantName">
        <el-input v-model="searchForm.tenantName" v-trim placeholder="请输入角色名称" @keypress.enter.native="search"/>
      </el-form-item>
      <el-form-item label="登录时间" prop="loginTime">
        <el-date-picker
          v-model="searchDateRange"
          type="datetimerange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="handleSearchTimeChange"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" style="text-align: right;" v-permissions="['system:tenant:add']">
        <li><el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button></li>
        <li><el-button @click="reset">重置</el-button></li>
        <li><el-button type="primary" @click="$refs.operaMenuWindow.open('新增运营商')" icon="el-icon-plus" v-permissions="['system:tenant:add']">新建</el-button></li>
      </ul>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        default-expand-all
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" fixed="left"></el-table-column>
        <el-table-column prop="id" label="运营商ID"></el-table-column>
        <el-table-column prop="managerUser.username" label="账号名称" min-width="80px"></el-table-column>
        <el-table-column prop="name" label="运营商名称" min-width="80px"></el-table-column>
        <el-table-column prop="createUserInfo.username" label="创建人" min-width="100px"></el-table-column>
        <el-table-column prop="managerUser.mobile" label="手机号" min-width="100px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="140px">
          <template slot-scope="{row}">{{$getTime(row.createTime)}}</template>
        </el-table-column>
        <el-table-column prop="updateUserInfo.username" label="更新人" min-width="100px"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="140px">
          <template slot-scope="{row}">{{$getTime(row.updateTime)}}</template>
        </el-table-column>
        <el-table-column prop="code" label="运营商编码" min-width="200px"></el-table-column>
        
        <el-table-column
          v-if="containPermissions(['system:com:bindingTenant', 'system:tenant:edit', 'system:tenant:delete'])"
          label="操作"
          min-width="260"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" icon="el-icon-s-tools" v-permissions="['system:com:bindingTenant']" @click="$refs.bindPark.open(row)" >绑定车场</el-button>
            <el-button type="text" icon="el-icon-edit" @click="$refs.addServe.open('增值服务配置', row)" v-permissions="['system:addValueAdded:update']">增值服务配置</el-button>
            <el-button type="text" icon="el-icon-edit" @click="$refs.operaMenuWindow.open('编辑运营商', row)" v-permissions="['system:tenant:edit']">编辑</el-button>
            <el-button v-if="!row.fixed" type="text" icon="el-icon-delete" @click="deleteById(row)" v-permissions="['system:tenant:delete']">删除</el-button>
            <el-button v-if="!row.fixed" type="text" icon="el-icon-delete" @click="$refs.resetPwdWindow.open(row)" v-permissions="['system:tenant:delete']">重置密码</el-button>
            <el-button v-if="!row.fixed" type="text" icon="el-icon-delete" @click="doadTypeSetShow(row)">广告类型设置</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>
    <!-- 新建/修改 -->
    <Edit ref="operaMenuWindow" @success="handlePageChange(tableData.pagination.pageIndex)"/>
    <addServe ref="addServe"></addServe>
    <bindPark ref="bindPark"></bindPark>

    <!-- 重置密码 -->
    <resetPwdWindow ref="resetPwdWindow" @success="handlePageChange(tableData.pagination.pageIndex)" type="tenant"/>

    <!-- 设置广告类型 -->
    <el-dialog title="设置广告类型" :visible.sync="adTypeSetShow" height="600px" width="400px">
      <div>
        <el-select v-model="adType" placeholder="请选择活动区域">
          <el-option v-for="type in adTypeList" :label="type.name" :value="type.type" :key="type.type"></el-option>
        </el-select>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="adTypeSetShow = false">取 消</el-button>
        <el-button type="primary" @click="defaultAdType">确 定</el-button>
      </div>
    </el-dialog>
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Edit from '@/components/tenant/edit'
import Pagination from '@/components/common/Pagination'
import bindPark from '@/components/tenant/bindPark'
import addServe from '@/components/tenant/addServe'
import ResetPwdWindow from '@/components/system/user/ResetPwdWindow'
import { updateById, updateStatus, sort, resetPwd } from '@/api/tenant/tenant'
import { getAdType, AdTypeList, defaultAdType} from "../../api/ad/oderAdUpLoad";
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { Edit, TableLayout, Pagination,bindPark,addServe, ResetPwdWindow},
  data () {
    return {
      adTypeSetShow: false,
      form:{
        comId:'',
        tenantId:''
      },
      dialogTableVisible:false,
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        code: '',
        tenantId: '',
        tenantName: '',
        createTimeEnd: null,
        createTimeStart: null
      },
      adType: '',
      adTypeList: [],
      adTenant: {}
    }
  },
  methods: {
    doadTypeSetShow(row) {
      this.adTenant = row
      this.AdTypeList()
      this.adType = row.adType === null ? 'IST' : row.adType
      
    },
    // 设置默认广告
    defaultAdType() {
      let data = {
        adType: this.adType,
        code: this.adTenant.code,
        createTime: this.adTenant.createTime,
        managerUserId: this.adTenant.managerUser.id,
        id: this.adTenant.id,
        name: this.adTenant.name,
        updateTime: this.adTenant.updateTime,
      }
      updateById(data).then(res=>{
        this.adTypeSetShow = false;
        this.$message({ message: '操作成功', type: 'success'});
        this.search()
      }).catch(e=>{
        this.adTypeSetShow = false;
        this.$message.error(e);
        
      })
    },
    // 获取所有广告类型
    AdTypeList() {
       AdTypeList().then(res=>{
        this.adTypeList = res
        this.adTypeSetShow = true
      })
    },
    // 获取广告类型
    getAdType() {
      getAdType(this.searchForm.comId,this.searchForm.isSend).then(res=>{
        this.adType = res
        if (this.adType === 'IST') {
          this.getAdList()
        } else {
          // this.getAdListPP()
        }
      })
    },
    resetPwd(row){  
      let data = {

      }
      
    },
    // 时间搜索范围变化
    handleSearchTimeChange (value) {
      this.searchForm.createTimeStart = null
      this.searchForm.createTimeEnd = null
      if (value != null) {
        this.searchForm.createTimeStart = value[0] / 1000
        this.searchForm.createTimeEnd = value[1] / 1000
      }
      this.search()
    },
  },
  created () {
    this.config({
      module: '菜单',
      api: '/tenant/tenant',
      sorts: [{
        property: 'create_time',
        direction: 'DESC'
      }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
</style>
