<template>
  <!-- 新建/修改 -->
  <el-dialog
    title="增值服务配置"
    :visible.sync="visible"
    :width="size"
  >
    <el-form ref="form" label-width="100px">
      <!-- <el-form-item label="选择车场" >
        <el-select v-model="searchForm.comId">
          <el-option
            v-for="item in parkTagList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="订单广告">
        <el-radio-group v-model="searchForm.orderAdService">
        <el-radio :label="0">关闭</el-radio>
        <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="短信服务">
        <el-radio-group v-model="searchForm.smsNotifyService">
        <el-radio :label="0">关闭</el-radio>
        <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="音视频服务">
        <el-radio-group v-model="searchForm.wxCall">
        <el-radio :label="0">关闭</el-radio>
        <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import { tenantManagerComList} from '@/api/parking/parking';
import { updateInfoByTenant,getInfoByTanant} from '@/api/addValueAdded/index';
export default {
  name: 'OperaUserWindow',
  extends: BaseOpera,
  components: { GlobalWindow,TableLayout, Pagination },
  data () {
    return {
      size:'40vw',
      // 表单数据
      searchForm: {
        "comId": null,
        "orderAdService": 0,
        "smsNotifyService": 0,
        "wxCall": 0,
        "tenantId": null
      },
      parkTagList:[],
    }
  },
  methods: {
    /**
     * @title 窗口标题
     * @target 编辑的用户对象
     */
    open (name,item) {
      this.userObj = item;
      this.searchForm.tenantId = item.managerUser.tenantId;
      let that = this;
      this.getInfoByTanantFun(item.managerUser.tenantId)
      
      
      this.visible = true;
    },
    //获取运营商增值服务
    getInfoByTanantFun(tenantId){
      
      getInfoByTanant(tenantId).then(res=>{
        this.searchForm.smsNotifyService = res.smsNotifyService;
        this.searchForm.orderAdService = res.orderAdService;
        this.searchForm.wxCall = res.wxCall;
      })
    },
    confirm(){
      updateInfoByTenant(this.searchForm).then(res=>{
        this.$tip.apiSuccess('配置成功');
        this.visible = false;
      }).catch(e=>{
        this.$tip.apiFailed('配置失败')
        this.visible = false;
      })
    }
    
  },
}
</script>

<style lang="scss" scoped>
.global-window {
  /deep/ .el-date-editor {
    width: 100%;
  }
  // 表单头像处理
  /deep/ .form-item-avatar {
    .el-radio.is-bordered {
      height: auto;
      padding: 6px;
      margin: 0 10px 0 0;
      .el-radio__input {
        display: none;
      }
      .el-radio__label {
        padding: 0;
        width: 48px;
        display: block;
        img {
          width: 100%;
        }
      }
    }
  }
}
.park-tag-box{
  padding:10px 0;
}
.park-tag-box /deep/ .el-tag{
  margin:10px;
}
</style>
