<template>
  <TableLayout class="menu-layout" :permissions="['system:addValueAdded:oneselfInfo']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="运营商:" v-permissions="['system:addValueAdded:recharge']">
        <el-select v-model="searchForm.tenantName" placeholder="请选择运营商" filterable>
          <el-option v-for="(tenant , i) in tenantList" :key="i" :label="tenant.name" :value="tenant.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="searchDateRange"
          type="datetimerange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="handleSearchTimeChange"
        ></el-date-picker>
      </el-form-item>
      <section>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button @click="resetNew">重置</el-button>
      </section>
    </el-form>

    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar">
        <li style="float:right;">
          <el-button type="primary"   @click="$refs.operInvest.open('1')" icon="el-icon-setting" v-permissions="['system:addValueAdded:recharge']">充值</el-button>
        </li>
        <li style="line-height:40px;">剩余条数: <span style="color:#E6A23C;">{{smsCount}}</span></li>
      </ul>
      <el-table
        ref="table"
        v-loading="isWorking.search"
        :data="tableData.list"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        row-key="id"
        stripe
        height="60vh"
        :default-expand-all="false"
        @selection-change="handleSelectionChange"
      >
        <el-table-column prop="id" label="序号"></el-table-column>
        <el-table-column prop="tenantId" label="运营商ID"></el-table-column>
        <el-table-column prop="tenantName" label="运营商名称"></el-table-column>
        <el-table-column prop="ruleName" label="充值时间" min-width="120">
          <template slot-scope="{row}">{{$getTime(row.createTime / 1000)}}</template>
        </el-table-column>
        <el-table-column prop="value" label="充值条数"></el-table-column>
        <el-table-column prop="beforeValue" label="充值前条数"></el-table-column>
        <el-table-column prop="afterValue" label="充值后条数"></el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      ></pagination>
    </template>

    <el-dialog
      title="修正短信剩余条数"
      :visible.sync="dialogVisible"
      width="30%">
      <el-form>
        <el-form-item label="修正:">
          <el-input-number v-model="updateValue" :min="1" :step="1" step-strictly></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateFun">修 正</el-button>
      </span>
    </el-dialog>
    <recharge ref="operInvest" :search="search"></recharge>
    
  </TableLayout>
</template>

<script>
import TableLayout from '@/layouts/TableLayout'
import BaseTable from '@/components/base/BaseTable'
import Pagination from '@/components/common/Pagination'
import recharge from '@/components/addValueAdded/recharge'
import {getInfo,update,queryAllTtenant} from '@/api/addValueAdded/index'
export default {
  name: 'SystemMenu',
  extends: BaseTable,
  components: { TableLayout, Pagination,recharge},
  data () {
    return {
      dialogVisible:false,
      value:false,
      // 搜索时间范围
      searchDateRange: [],
      // 是否正在处理中
      isWorking: {
        sort: false
      },
      // 搜索
      searchForm: {
        createTimeEnd:'',
        createTimeStart:'',
        type:'1',
        tenantName:''
      },
      statusList:[],
      statusData:{},
      productList:[],
      productData:{},
      triggerSourceList:[],
      triggerSourceData:{},
      alarmTypeData:{},
      unionUserData:{},
      typeList:[
        {value_name:'短信充值',value_no:'1'},
        {value_name:'音视频充值',value_no:'2'}
      ],
      smsCount:0,
      updateValue:0,
      tenantList:[]
    }
  },
  mounted(){
    this.getInfoFun()
    this.queryAllTtenantFun()
  },
  methods: {
    //获取运营商列表
    queryAllTtenantFun(){
      let data = {
        model:{
          "code": "",
          "createTimeEnd": null,
          "createTimeStart": null,
          "tenantId": null,
          "tenantName": ""
        }
      }
      queryAllTtenant(data).then(res=>{
        this.tenantList = res;
      })
    },
    //修正
    updateFun(){
      update(this.updateValue).then(res=>{

      })
    },
    getInfoFun(){
      getInfo().then(res=>{
        this.smsCount = res.smsCount;
        // this.updateValue = res.smsCount;
      })
    },
    // 时间搜索范围变化
    handleSearchTimeChange (value) {
      this.searchForm.createTimeEnd = null
      this.searchForm.createTimeStart = null
      if (value != null) {
        this.searchForm.createTimeStart = value[0] / 1000
        this.searchForm.createTimeEnd = value[1] / 1000
      }
      // this.search()
    },
    currentDate() {
      //返回当前日期年月日 2018-03-20
      let start = new Date();
      return start.getFullYear() + '-' + this.formatNumber(start.getMonth() + 1) + '-' + this.formatNumber(start.getDate());
    },
    resetNew(){
      this.searchForm = {
        createTimeEnd:'',
        createTimeStart:'',
        type:'2',
        tenantName:''
      }
      this.searchForm.createTimeStart = new Date(this.currentDate() + ' 00:00:00').getTime() -(24 * 3 * 60 * 60 * 1000);
      this.searchForm.createTimeEnd = new Date(this.currentDate() + ' 23:59:59').getTime();
      this.searchDateRange = [this.searchForm.createTimeStart, this.searchForm.createTimeEnd];
    },
    // 获取最近时间
    formatNumber(num) {
        //数字格式化成两位 xx
        if (num > 9)
            return num;
        else
            return '0' + num;
    },
  },
  created () {
    this.searchForm.createTimeStart = new Date(this.currentDate() + ' 00:00:00').getTime() -(24 * 3 * 60 * 60 * 1000);
    this.searchForm.createTimeEnd = new Date(this.currentDate() + ' 23:59:59').getTime();
    this.searchDateRange = [this.searchForm.createTimeStart, this.searchForm.createTimeEnd];
    this.config({
      module: '菜单',
      api: '/addValueAdded/index',
      // sorts: [{
      //   property: 'start_time',
      //   direction: 'DESC'
      // }]
      
    })
    this.search()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.menu-layout {
  /deep/ .table-content {
    margin-top: 0;
  }
}
// 图标列
.table-column-icon {
  // element-ui图标
  i {
    background-color: $primary-color;
    opacity: 0.72;
    font-size: 20px;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
  }
  // 自定义图标
  [class^="yunying-icon-"] {
    width: 20px;
    height: 20px;
    background-size: 16px;
    vertical-align: middle;
  }
}
.toolbar{
  height:40px;
}
</style>
